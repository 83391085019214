<div>
  <div mat-dialog-title>Configure Network Segments</div>
  <mat-dialog-content>
    <mat-divider></mat-divider>
    <div class="segment-options">
      @for (type of segmentTypes; track type.name; let i = $index) {
        @if (!type.custom) {
          <mat-checkbox [checked]="type.selected" (change)="toggleType(type)">
            <div class="type-name">{{ type.name }}</div>
          </mat-checkbox>
        } @else {
          <mat-checkbox class="narrow-table-fields" [checked]="type.selected">
            <div class="custom-type">
              <mat-form-field>
                <input [(ngModel)]="type.name" placeholder="SERVICE NAME" matInput />
              </mat-form-field>
              <mat-icon class="icon" [matTooltip]="type.type === 'NETWORK' ? 'Network segment' : 'Cloud hosted system'">{{ type.type === 'NETWORK' ? 'storage' : 'cloud' }}</mat-icon>
              <cybexer-confirm-action>
                <button mat-icon-button (click)="removeCustomType(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </cybexer-confirm-action>
            </div>
          </mat-checkbox>
        }
      }

      <div>
        @if (!showAdditionalButtons) {
          <button mat-button (click)="toggleButtons()" class="add-btn">
            <mat-icon>add_circle</mat-icon>add
          </button>
        } @else {
          <div>
            <button mat-button (click)="addType('NETWORK')" class="add-btn">
              <mat-icon>add_circle</mat-icon>Network segment
            </button>
            <button mat-button (click)="addType('CLOUD')" class="add-btn">
              <mat-icon>add_circle</mat-icon>Cloud hosted system
            </button>
          </div>
        }
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button (click)="saveAndClose()" color="primary">
      <mat-icon>save</mat-icon>save and close
    </button>
  </mat-dialog-actions>
</div>
