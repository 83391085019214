import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cybexer-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInput,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private translate: TranslateService
  ) {}

  get confirmBtnText(): String {
    if (this.data?.confirmBtnText) {
      return this.data.confirmBtnText;
    }
    const translation = this.translate.instant('cybexer.confirmDialog.confirm');
    return translation !== 'cybexer.confirmDialog.confirm' ? translation : 'Confirm';
  }

  get cancelBtnText(): String {
    if (this.data?.cancelBtnText) {
      return this.data.cancelBtnText;
    }
    const translation = this.translate.instant('cybexer.confirmDialog.cancel');
    return translation !== 'cybexer.confirmDialog.cancel' ? translation : 'Cancel';
  }

  get message(): String {
    if (this.data?.message) {
      return this.data.message;
    }
    const translation = this.translate.instant('cybexer.confirmDialog.confirmProceed');
    return translation !== 'cybexer.confirmDialog.confirmProceed'
      ? translation
      : 'Are you sure you want to proceed?';
  }

  get additionalMessage(): String {
    return this.data.additionalMessage;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}

export class ConfirmDialogInput {
  confirmBtnText?: string;
  cancelBtnText?: string;
  message?: string;
  additionalMessage?: string;
}
