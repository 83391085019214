import { Component, OnInit } from '@angular/core';
import { ClipboardData } from '../../../model/clipboard-data.model';
import { ClipboardService } from '../../../service/clipboard.service';

@Component({
  selector: 'cybexer-clipboard-overlay',
  templateUrl: './clipboard-overlay.component.html',
  styleUrls: ['./clipboard-overlay.component.scss'],
})
export class ClipboardOverlayComponent implements OnInit {
  text: string;

  constructor(private clipboardService: ClipboardService) {}

  ngOnInit(): void {
    this.clipboardService.clipboardCache$.subscribe((clipboardData) => {
      this.text = clipboardData?.data;
    });
  }

  onTextChange(): void {
    this.clipboardService.clipboardCache$.next(
      new ClipboardData({
        mimetype: 'text/plain',
        data: this.text,
      })
    );
  }
}
