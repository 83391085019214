import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cybexer-tooltip',
  template: `{{ text }}`,
})
export class TooltipComponent {
  @HostBinding('class.cybexer-tooltip')
  @Input()
  text = '';
}
