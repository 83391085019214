<div #editor class="monaco-editor"></div>
<div hidden>
  <div #errorOverlay class="error-overlay">
    <div class="error-overlay-title">
      <div class="codicon codicon-error"></div>
      <div>
        {{
          'cybexer.monaco.viewer.codeCopied' | translate: { default: 'Code copied to clipboard' }
        }}
      </div>
      <div
        title="Close"
        class="button codicon {{ CODICON_WIDGET_CLOSE }}"
        role="button"
        aria-disabled="false"
      ></div>
    </div>
    <div class="error-overlay-body">
      <div class="{{ ERROR_OVERLAY_BODY_CONTENT }}"></div>
    </div>
  </div>
</div>
