import { Router } from '@angular/router';
import { Observable } from 'rxjs';

export abstract class VsphereManagerBaseService {
  protected constructor(protected router: Router) {}

  abstract getConnectionId(vmId: string): Observable<string>;

  abstract getWebConsoleUrl(connectionId: string): Observable<string>;

  abstract createNewWindowUrl(vmId: string, targetName: string): string;

  abstract notifySentry(error: any): void;
}
