import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConsoleConnectionType, ConsoleAuthData } from '../guacamole.model';

@Component({
  selector: 'cybexer-guacamole-console-login',
  templateUrl: './guacamole-console-login.component.html',
  styleUrls: ['./guacamole-console-login.component.scss'],
})
export class GuacamoleConsoleLoginComponent implements OnInit {
  @Input() hostname: string;
  @Input() protocol: string;
  @Input() port: string = '';
  @Input() username: string;
  @Input() password: string;
  @Input() reconnectTarget: string;
  @Output() consoleAuthData = new EventEmitter<any>();

  loginForm: FormGroup;
  loading: boolean;

  protocols = [ConsoleConnectionType.SSH, ConsoleConnectionType.VNC, ConsoleConnectionType.RDP];

  constructor() {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      protocol: new FormControl(
        this.protocol ? this.protocol : ConsoleConnectionType.SSH,
        Validators.required
      ),
      hostname: new FormControl(this.hostname, Validators.required),
      port: new FormControl(this.port),
      username: new FormControl(this.username, Validators.required),
      password: new FormControl(this.password, Validators.required),
    });
  }

  login(): void {
    if (this.loginForm.valid) {
      this.loading = true;

      const data = new ConsoleAuthData({
        hostname: this.loginForm.get('hostname').value,
        connectionType: this.loginForm.get('protocol').value,
        port: this.loginForm.get('port').value,
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value,
      });

      this.consoleAuthData.emit(data);
      this.loading = false;
    }
  }

  reconnect(): void {
    this.consoleAuthData.emit('');
  }
}
