import { Directive, ElementRef, HostBinding, OnDestroy, Renderer2 } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from '../components/tooltip/tooltip.component';

@Directive({
  selector: '[cybexerConfirm]',
})
export class ConfirmDirective implements OnDestroy {
  confirmMessage = 'Press again to confirm';

  @HostBinding('class.cybexer-confirming-btn')
  isInConfirmationState: boolean;

  disabled = false;

  private confirmationTimeout: number;
  private TIMEOUT_MS = 3000;

  private overlayRef: OverlayRef;

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private renderer: Renderer2
  ) {
    this.renderer.listen(this.el.nativeElement, 'click', this.captureClick);
    this.renderer.listen(
      this.el.nativeElement,
      'mouseleave',
      this.startConfirmationCancel.bind(this)
    );
    this.renderer.listen(
      this.el.nativeElement,
      'mouseenter',
      this.stopConfirmationCancel.bind(this)
    );
  }

  private captureClick = (event: PointerEvent) => {
    if (!this.isInConfirmationState) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();
      this.startConfirmation();
      return false;
    } else {
      this.confirmAction();
      return true;
    }
  };

  startConfirmation() {
    if (!this.disabled) {
      this.isInConfirmationState = true;

      window.setTimeout(() => {
        this.showTooltip();
      }, 0);
    }
  }

  startConfirmationCancel() {
    if (this.isInConfirmationState) {
      this.confirmationTimeout = window.setTimeout(() => {
        this.cleanup();
      }, this.TIMEOUT_MS);
    }
  }

  stopConfirmationCancel() {
    if (this.isInConfirmationState) {
      window.clearTimeout(this.confirmationTimeout);
      this.confirmationTimeout = null;
    }
  }

  confirmAction() {
    window.clearTimeout(this.confirmationTimeout);
    this.confirmationTimeout = null;
    this.cleanup();
  }

  cleanup() {
    this.isInConfirmationState = false;
    this.hideTooltip();
  }

  private showTooltip() {
    this.overlayRef = this.createTooltip();

    const tooltipPortal = new ComponentPortal(TooltipComponent);
    const tooltipRef = this.overlayRef.attach(tooltipPortal);

    tooltipRef.instance.text = this.confirmMessage;
  }

  private createTooltip() {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.el)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]);

    return this.overlay.create({ positionStrategy });
  }

  private hideTooltip() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  ngOnDestroy() {
    this.hideTooltip();
  }
}
