import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { editor } from 'monaco-editor';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;
import { MonacoCommonsEditorComponent } from '../editor/monaco-commons-editor.component';
import { NotificationsService } from '../../../service/notifications.service';

@Component({
  selector: 'cybexer-monaco-code-viewer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonacoCodeViewerComponent),
      multi: true,
    },
  ],
  templateUrl: './monaco-code-viewer.component.html',
  styleUrls: ['./monaco-code-viewer.component.scss'],
})
export class MonacoCodeViewerComponent extends MonacoCommonsEditorComponent {
  @Input() isToolbarHidden?: boolean;
  @Input() override heightFitContent: boolean = true;
  @Output() editorFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() editorBlur: EventEmitter<void> = new EventEmitter<void>();
  protected override options: IStandaloneEditorConstructionOptions = {
    lineNumbers: 'off',
    tabSize: 2,
    scrollBeyondLastLine: false,
    automaticLayout: true,
    folding: false,
    minimap: { enabled: false },
    domReadOnly: true,
    renderLineHighlight: 'none',
    scrollbar: {
      alwaysConsumeMouseWheel: false,
    },
  };

  constructor(
    protected override renderer: Renderer2,
    protected override injector: Injector,
    private notificationsService: NotificationsService
  ) {
    super(renderer, injector);
  }

  protected override initMonaco(): void {
    super.initMonaco();
    if (!this.editor) return;
    this.editor.onDidChangeModelContent(() => {
      this.setEditorHeight();
    });

    this.editor.onDidBlurEditorWidget(() => {
      this.editorBlur.emit();
    });

    this.editor.onDidFocusEditorWidget(() => {
      this.editorFocus.emit();
    });
  }

  copyCodeToClipboard() {
    return navigator.clipboard
      .writeText(this.value)
      .then(() => this.notificationsService.info('Code copied to clipboard'));
  }
}
