import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { FormArray, FormGroup } from '@angular/forms';

export async function confirmed(
  dialog: MatDialog,
  message?: string,
  additionalMessage?: string,
  confirmBtnText?: string
): Promise<boolean> {
  return await firstValueFrom(
    dialog
      .open(ConfirmDialogComponent, {
        data: {
          message: message,
          additionalMessage: additionalMessage,
          confirmBtnText: confirmBtnText,
        },
      })
      .afterClosed()
  );
}

export function debounce(func: (...args: any[]) => any, wait: number): (...args: any[]) => void {
  let timeout: any;

  return function (this: any) {
    const context = this;
    const args = arguments;

    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function logFormErrors(form: FormGroup, ...keys: string[]): void {
  Object.keys(form.controls).forEach((key) => {
    const control = form.get(key);
    const newKeys = [...keys, key];
    if (control instanceof FormGroup) {
      logFormErrors(control, ...newKeys);
    } else if (control instanceof FormArray) {
      control.controls.forEach((ctrl, index) =>
        logFormErrors(ctrl as FormGroup, ...newKeys, index.toString())
      );
    } else {
      if (control.errors) {
        console.error(
          `Control: ${newKeys.join('.')}.${key}, Value: ${control.value}, Errors:`,
          control.errors
        );
      }
    }
  });
}
