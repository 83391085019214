import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MonacoCommonsEditorComponent } from '../editor/monaco-commons-editor.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'cybexer-json-monaco-editor',
  templateUrl: '../editor/monaco-commons-editor.component.html',
  styleUrls: ['../editor/monaco-commons-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonacoJsonEditorComponent),
      multi: true,
    },
  ],
})
export class MonacoJsonEditorComponent extends MonacoCommonsEditorComponent implements OnChanges {
  // todo: add tests

  @Input() jsonSchema: any;
  @Input() jinja2: boolean = false;

  constructor(
    protected override renderer: Renderer2,
    protected override injector: Injector
  ) {
    super(renderer, injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['jsonSchema']?.firstChange) {
      this.loadNewJsonSchema(changes['jsonSchema'].currentValue);
    }
  }

  override initMonaco(): void {
    this.language = this.jinja2 ? 'jinja2.json' : 'json';

    super.initMonaco();

    if (this.jsonSchema != null) {
      this.loadNewJsonSchema(this.jsonSchema);
    }
  }

  private loadNewJsonSchema(jsonSchema) {
    if (this.editor) {
      this.jsonSchema = jsonSchema;
      this.monacoCommonsEditorService.setJsonDefaultSchema({
        uri: jsonSchema['$schema'],
        fileMatch: ['**'],
        schema: jsonSchema,
      });
    }
    this.setupMarkerHandling();
  }

  protected override shouldClearJsonSchemaOnInit(): boolean {
    return false;
  }

  setJsonDefaultsSchema(jsonSchema) {
    if (this.editor) {
      this.jsonSchema = jsonSchema;
      this.monacoCommonsEditorService.setJsonDefaultSchema(jsonSchema);
    }
    this.setupMarkerHandling();
  }

  isEditorValueValid() {
    let isValid = true;
    let markers = this.getMarkersSortedByLineNumberFromEditorModel();

    const severeErrors = markers.filter(
      (it) => it.severity > this.getMonacoHandle().MarkerSeverity.Warning
    );
    if (severeErrors.length !== 0) {
      this.toggleFirstWarningMarker(severeErrors[0]);
      isValid = false;
    }

    return isValid;
  }

  downloadJsonSchema(definition = false) {
    const jsonSchemaTitle = this.jsonSchema['title'];
    const filename = definition ? `${jsonSchemaTitle}_definition.json` : `${jsonSchemaTitle}.json`;

    const blob = new Blob([JSON.stringify(this.jsonSchema, null, 4)], {
      type: 'application/json',
    });
    saveAs(blob, filename);
  }

  private setupMarkerHandling(): void {
    const monaco = this.getMonacoHandle();
    const editor = this.editor;
    let updatingMarkers = false;

    monaco.editor.onDidChangeMarkers(() => {
      if (updatingMarkers) return;

      const model = editor.getModel();

      if (model) {
        const markers = monaco.editor.getModelMarkers({ resource: model.uri });
        const newMarkers = markers.map((marker) => {
          if (
            marker.message.includes('is not allowed') &&
            marker.severity !== monaco.MarkerSeverity.Warning
          ) {
            return {
              ...marker,
              severity: monaco.MarkerSeverity.Warning,
            };
          }
          return marker;
        });

        const markersChanged = !markers.every(
          (val, index) => val.severity === newMarkers[index].severity
        );

        if (markersChanged) {
          updatingMarkers = true;
          monaco.editor.setModelMarkers(model, 'json', newMarkers);
          updatingMarkers = false;
        }
      }
    });
  }
}
