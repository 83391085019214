import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vlm-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() mainClass: string = '';
  @Input() inputIcon: string = '';
  icon: string = '';

  ngOnInit(): void {
    this.icon = IconComponent.getIconClass(this.inputIcon);
  }

  static getIconClass(type: string): string {
    switch (type?.toLowerCase()) {
      case 'windows':
        return 'fab fa-windows';
      case 'linux':
        return 'fab fa-linux';
      case 'mail server':
        return 'fas fa-envelope';
      case 'web server':
      case 'dns server':
        return 'fas fa-globe';
      case 'proxy server':
        return 'fas fa-server';
      case 'file server':
        return 'fa fa-folder';
      case 'plc':
        return 'fa fa-microchip';
      case 'freebsd':
        return 'fab fa-freebsd';
      case 'hard drive':
        return 'fa-solid fa-hard-drive';
      case 'computer':
        return 'fa-solid fa-computer';
      case 'ram':
      case 'other':
        return 'fa fa-memory';
      default:
        if (type?.toLowerCase().startsWith('fa')) {
          return type;
        }
        return 'fa fa-memory';
    }
  }
}
