import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Guacamole from 'guacamole-common-js';
import { firstValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClipboardData } from '../../../model/clipboard-data.model';
import { ClipboardService } from '../../../service/clipboard.service';
import { FullscreenService } from '../../../service/fullscreen.service';
import { compressImage, getElementResolution } from '../guacamole-helper';
import {
  ActiveConnection,
  ClientConnectionState,
  ConsoleConnectionParameters,
  ConsoleConnectionSource,
  ConsoleConnectionType,
  ConsoleErrorData,
  ConsoleAuthData,
  GuacamoleStatus,
  GuacamoleStatusMessage,
  TunnelConnectionState,
} from '../guacamole.model';
import { GuacamoleConsoleService } from '../service/guacamole-console.service';
import { GuacamoleManagerBaseService } from '../service/guacamole-manager-base.service';
import { GuacamoleConsoleDisplayComponent } from './guacamole-console-display/guacamole-console-display.component';
import { NotificationsService } from '../../../service/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'cybexer-guacamole-console',
  templateUrl: './guacamole-console.component.html',
  styleUrls: ['./guacamole-console.component.scss'],
})
export class GuacamoleConsoleComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('guacamoleContainer', { static: true }) guacamoleContainer: ElementRef;
  @ViewChild('guacamoleConsoleDisplay') guacamoleConsoleDisplay: GuacamoleConsoleDisplayComponent;
  @ViewChild(MatSidenav) clipboard: MatSidenav;

  @Input() connectionParameters: ConsoleConnectionParameters;
  @Input() open: EventEmitter<boolean>;
  @Input() isNewWindow = false;
  @Input() isHorizontal = false;
  @Input() isConsoleLoginEnabled = false;
  @Output() consoleClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  showPermissionWarning: boolean = false;
  isFullWidth: boolean = false;
  clientConnectionState: ClientConnectionState = ClientConnectionState.IDLE;
  tunnelConnectionState: TunnelConnectionState = TunnelConnectionState.CONNECTING;

  isNewConsoleLogin: boolean = false;
  consoleAuthData: ConsoleAuthData;
  openConnections: Map<string, ActiveConnection> = new Map();
  initialConnection: ConsoleConnectionParameters;
  connectionResetInProgress: boolean = false;

  readonly ClientConnectionState = ClientConnectionState;
  readonly ConsoleConnectionType = ConsoleConnectionType;

  private clipboardCache: ClipboardData;
  private windowReference: Window;

  constructor(
    protected notificationsService: NotificationsService,
    protected clipboardService: ClipboardService,
    protected fullscreenService: FullscreenService,
    protected guacamoleService: GuacamoleConsoleService,
    protected guacamoleManager: GuacamoleManagerBaseService,
    protected translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.clientConnectionState === ClientConnectionState.CONNECTED &&
      changes['connectionParameters'].previousValue
    ) {
      this.saveActiveConnection(
        changes['connectionParameters'].previousValue as ConsoleConnectionParameters
      ).then(() => {
        this.unbindConsoleComponents();
        this.resetData();
      });
    }
  }

  ngOnInit(): void {
    this.clipboardService
      .hasClipboardPermissions()
      .then((hasPermissions) => (this.showPermissionWarning = !hasPermissions));

    this.open?.pipe(untilDestroyed(this)).subscribe((isConsoleOpened) => {
      if (isConsoleOpened) {
        // Wait for changes to be recognized
        setTimeout(() => {
          if (!this.areConnectionParametersProvided()) return;

          if (!this.connectionParameters.source) {
            console.warn(`Console connection source is missing`);
            this.consoleClose.emit(true);
            return;
          }

          this.openConsole();
        }, 200);
      }
    });

    this.clipboardService.clipboardCache$
      .pipe(debounceTime(500))
      .pipe(untilDestroyed(this))
      .subscribe((data: ClipboardData) => {
        if (data) {
          this.clipboardCache = data;

          this.clipboardService.setLocalClipboard(this.clipboardCache);
          this.clipboardService.sendDataToGuacamoleRemoteClipboard(
            this.guacamoleService.client,
            this.clipboardCache
          );
        }
      });
  }

  private areConnectionParametersProvided(): boolean {
    if (!this.connectionParameters) {
      console.warn('No connection parameters provided.');
      this.consoleClose.emit(true);
      return false;
    }
    return true;
  }

  authenticate(event): void {
    this.isNewConsoleLogin = false;

    if (event?.hostname) {
      this.consoleAuthData = event;

      this.connectionParameters = new ConsoleConnectionParameters({
        name: this.consoleAuthData.hostname,
        connectionType: this.consoleAuthData.connectionType,
        source: ConsoleConnectionSource.DIRECT,
        port: this.consoleAuthData.port,
        username: this.consoleAuthData.username,
        password: this.consoleAuthData.password,
      });
    } else {
      // use initial connection to reconnect
      this.connectionParameters = this.initialConnection;
    }

    this.openConsole();
  }

  private openConsole(): void {
    this.clientConnectionState = ClientConnectionState.CONNECTING;
    const activeConnection = this.getExistingConnection();
    if (activeConnection) {
      this.connectionParameters = new ConsoleConnectionParameters({
        id: activeConnection.id,
        vmId: activeConnection.vmId,
        name: activeConnection.name,
        source: activeConnection.source,
        connectionType: activeConnection.protocol,
        port: activeConnection.port,
        username: activeConnection.username,
        password: activeConnection.password,
      });
    }

    if (activeConnection && this.connectionParameters.id) {
      this.reconnect(activeConnection);
    } else if (
      this.connectionParameters.id ||
      this.isVCenterConnection() ||
      this.isDirectConnection()
    ) {
      this.connect();
    } else if (this.isConsoleLoginEnabled) {
      this.isNewConsoleLogin = true;
      this.clientConnectionState = ClientConnectionState.IDLE;
    } else {
      this.notificationsService.error(
        this.translate.instant('cybexer.guacamole.console.consoleOpenError', {
          default: 'Could not open console',
        }),
        this.translate.instant('cybexer.guacamole.console.noParametersProvided', {
          default: 'No connection parameters provided',
        })
      );
      this.close();
    }
  }

  private getExistingConnection(): ActiveConnection {
    return this.openConnections.get(
      `${this.connectionParameters?.name} - ${this.connectionParameters?.connectionType}`
    );
  }

  private isDirectConnection(): boolean {
    return (
      this.connectionParameters.source === ConsoleConnectionSource.DIRECT &&
      !!this.connectionParameters.name &&
      !!this.connectionParameters.username
    );
  }

  private isVCenterConnection(): boolean {
    return (
      this.connectionParameters.source === ConsoleConnectionSource.VCENTER &&
      !!this.connectionParameters.vmId
    );
  }

  private reconnect(activeConnection: ActiveConnection) {
    this.guacamoleService.client = activeConnection.client;
    this.guacamoleService.tunnel = activeConnection.tunnel;

    if (!this.guacamoleService.isConnected()) {
      this.connect();
    } else {
      this.guacamoleService.getCurrentState().then((state: Guacamole.Client.State) => {
        this.clientConnectionState = state;

        switch (state) {
          case ClientConnectionState.CONNECTED:
            this.guacamoleService.clientReady.next();
            this.bindHandlers();

            break;
          case ClientConnectionState.DISCONNECTED:
            this.connect();

            break;
        }
      });
    }
  }

  private async connect() {
    try {
      const params = await this.buildUrlParams();
      this.guacamoleService.tunnel = await firstValueFrom(
        this.guacamoleManager.getTunnel().pipe(untilDestroyed(this))
      );
      await this.guacamoleService.connectToClient(params);
      this.bindHandlers();
    } catch {
      this.notificationsService.error(
        this.translate.instant('cybexer.guacamole.console.connectionFailedError', {
          default: 'Could not establish connection',
        })
      );
      this.close();
    }
  }

  private async buildUrlParams(): Promise<URLSearchParams> {
    const elementResolution = getElementResolution(
      this.guacamoleConsoleDisplay.guacamoleConsole.nativeElement
    );
    const urlParams = new URLSearchParams();

    if (!this.connectionParameters.id) {
      if (!this.consoleAuthData) {
        this.consoleAuthData = ConsoleAuthData.constructFrom(this.connectionParameters);
      }

      this.connectionParameters.id = await this.guacamoleManager.getConnectionId(
        this.consoleAuthData
      );
    }

    urlParams.append('connectionId', this.connectionParameters?.id);
    urlParams.append('screenWidth', elementResolution.width.toString());
    urlParams.append('screenHeight', elementResolution.height.toString());

    if (this.connectionParameters?.connectionType == ConsoleConnectionType.RDP) {
      urlParams.append('resize-method', 'display-update');
      urlParams.append('ignore-cert', 'true');
    }

    this.guacamoleManager.getAdditionalUrlParams().forEach((value, key) => {
      urlParams.append(key, value);
    });

    this.consoleAuthData = undefined;

    return urlParams;
  }

  private bindHandlers() {
    this.guacamoleService.bindErrorHandlers(this.handleTunnelError, this.handleClientError);
    this.guacamoleService.bindStateHandlers(this.handleTunnelState, this.handleClientState);
    this.guacamoleService.bindClipboardHandler(
      this.clipboardService.handleGuacamoleRemoteClipboard
    );

    window.addEventListener('keydown', this.toggleClipboard);
  }

  private handleClientState = (clientState: Guacamole.Client.State) => {
    this.clientConnectionState = clientState as ClientConnectionState;
    switch (this.clientConnectionState) {
      case ClientConnectionState.CONNECTED:
        this.guacamoleService.resize.next(true);

        break;
      case ClientConnectionState.DISCONNECTING:
      case ClientConnectionState.DISCONNECTED:
        this.close();

        break;
    }

    console.log(
      `Client connection state is { ${clientState} : ${
        ClientConnectionState[this.clientConnectionState]
      } }`
    );
  };

  private handleTunnelState = (tunnelState: Guacamole.Tunnel.State) => {
    this.tunnelConnectionState = tunnelState as TunnelConnectionState;
    switch (this.tunnelConnectionState) {
      case TunnelConnectionState.CLOSED:
        this.close();

        break;
    }

    console.log(
      `Tunnel connection state is { ${tunnelState} : ${
        TunnelConnectionState[this.tunnelConnectionState]
      } }`
    );
  };

  private toggleClipboard = (event) => {
    if (event.ctrlKey && event.altKey && event.shiftKey) {
      this.clipboard.toggle();
    }
  };

  async openInNewWindow() {
    this.windowReference = window.open(
      '',
      '_blank',
      `toolbar=0, menubar=0, location=0, width=900, height=600`
    );

    if (!this.consoleAuthData) {
      this.consoleAuthData = ConsoleAuthData.constructFrom(this.connectionParameters);
    }

    this.connectionParameters.id = await this.guacamoleManager
      .getConnectionId(this.consoleAuthData)
      .catch(() => {
        this.windowReference.close();
        this.notificationsService.error(
          this.translate.instant('cybexer.guacamole.console.connectionFailedError', {
            default: 'Could not establish connection',
          })
        );
        return undefined;
      });

    const url = this.guacamoleManager.createNewWindowUrl(this.connectionParameters);
    this.close();
    this.windowReference.location = url;
  }

  // If fullscreen element is within an overlay (Mat-Dialog) then fullscreen overlay works with error
  // "Uncaught DOMException: Failed to execute 'appendChild' on 'Node': The new child element contains the parent."
  // https://github.com/angular/components/issues/10679
  toggleFullscreen(): void {
    const element = this.guacamoleContainer.nativeElement;
    if (this.fullscreenService.isFullscreen(element)) {
      this.fullscreenService.exitFullscreen();
    } else {
      this.fullscreenService.enterFullscreen(element);
    }
  }

  toggleDisplayWidth(): void {
    this.isFullWidth = !this.isFullWidth;
    this.guacamoleService.toWideScreen.next(this.isFullWidth);
  }

  private handleClientError = (status: Guacamole.Status) => {
    this.handleErrorStatusesFor('Client', status);
    this.close();
  };

  private handleTunnelError = (status: Guacamole.Status) => {
    this.handleErrorStatusesFor('Tunnel', status);
    this.close();
  };

  private handleErrorStatusesFor(name: string, status: Guacamole.Status) {
    const guacaStatus = GuacamoleStatus[status.code];
    let errorMsg = this.translate.instant('cybexer.guacamole.console.nameConnectionFailed', {
      name: name,
      default: `${name} connection failed`,
    });

    if (this.isCustomErrorMessage(status)) {
      this.notificationsService.error(
        this.translate.instant('cybexer.guacamole.console.nameConnectionFailedMessage', {
          name: name,
          message: status.message,
          default: `${name} connection failed: ${status.message}.`,
        })
      );
      errorMsg = this.translate.instant(
        'cybexer.guacamole.console.nameConnectionFailedWithMessage',
        {
          name: name,
          message: status.message,
          default: `${name} failed with { message: ${status.message} }`,
        }
      );
    } else if (guacaStatus) {
      this.notificationsService.error(
        this.translate.instant('cybexer.guacamole.console.nameConnectionFailedSeeLogs', {
          name: name,
          status: GuacamoleStatus.toString(guacaStatus),
          default: `${name} connection failed: ${GuacamoleStatus.toString(guacaStatus)}. See logs.`,
        })
      );

      console.error(GuacamoleStatusMessage[guacaStatus]);
      const errorObj = {
        error: GuacamoleStatus.toString(guacaStatus),
        code: status.code,
        message: status.message
          ? status.message
          : this.translate.instant('cybexer.guacamole.status.' + guacaStatus.toLowerCase(), {
              default: GuacamoleStatusMessage[guacaStatus],
            }),
      };
      errorMsg = `${name} failed with ${JSON.stringify(errorObj)}`;
    }

    console.error(errorMsg);
    this.guacamoleManager.notifySentry(
      new ConsoleErrorData({
        connectionId: this.connectionParameters.id,
        connectionName: this.connectionParameters.name,
        connectionType: this.connectionParameters.connectionType,
        errorMsg: errorMsg,
      })
    );

    this.notificationsService.info(
      this.translate.instant('cybexer.guacamole.console.disconnectedError', {
        default: 'Disconnected. Please retry connection',
      })
    );
  }

  private isCustomErrorMessage(status: Guacamole.Status) {
    return isNaN(status?.code);
  }

  logout(): void {
    if (this.connectionResetInProgress) return;
    this.connectionResetInProgress = true;

    const connectionParameters = new ConsoleConnectionParameters({
      ...this.connectionParameters,
      id: undefined,
    });

    this.saveActiveConnection(connectionParameters).then(() => {
      if (
        connectionParameters.source === ConsoleConnectionSource.VCENTER ||
        (connectionParameters.source === ConsoleConnectionSource.DIRECT &&
          connectionParameters?.username)
      ) {
        this.initialConnection = connectionParameters;
      }
      this.guacamoleService.disconnect();
      this.unbindConsoleComponents();

      this.isNewConsoleLogin = true;
      this.connectionResetInProgress = false;
    });
  }

  close(): void {
    if (this.connectionResetInProgress) return;

    this.consoleClose.emit(true);
    if (this.isNewConsoleLogin) {
      this.isNewConsoleLogin = false;
      return;
    }

    this.connectionResetInProgress = true;

    this.saveActiveConnection(this.connectionParameters).then(() => {
      this.unbindConsoleComponents();
      this.resetData();
      this.connectionResetInProgress = false;
    });
  }

  private async saveActiveConnection(connectionParameters: ConsoleConnectionParameters) {
    if (this.clientConnectionState === ClientConnectionState.CONNECTED && connectionParameters) {
      const image: string = await this.guacamoleService.getScreenshot(
        connectionParameters.connectionType
      );

      await compressImage(image).then((compressedImage) => {
        const activeConnection = new ActiveConnection({
          id: connectionParameters.id,
          vmId: connectionParameters.vmId,
          name: connectionParameters.name,
          source: connectionParameters.source,
          protocol: connectionParameters.connectionType,
          port: connectionParameters.port,
          username: connectionParameters.username,
          password: connectionParameters.password,
          client: this.guacamoleService.client,
          tunnel: this.guacamoleService.tunnel,
          thumb: compressedImage,
        });
        this.guacamoleService.activeGuacamoleConnection$.next(activeConnection);
        this.openConnections.set(
          `${connectionParameters?.name} - ${connectionParameters?.connectionType}`,
          activeConnection
        );
      });
    }
  }

  private resetData() {
    this.clientConnectionState = ClientConnectionState.IDLE;
    this.tunnelConnectionState = TunnelConnectionState.CONNECTING;
    this.isNewConsoleLogin = false;

    this.consoleAuthData = undefined;
    this.initialConnection = undefined;

    this.guacamoleService.clear();
  }

  private unbindConsoleComponents(): void {
    if (this.fullscreenService.isFullscreen(this.guacamoleContainer.nativeElement)) {
      this.fullscreenService.exitFullscreen();
      return;
    }

    window.removeEventListener('keydown', this.toggleClipboard);
    this.clipboard.close();

    this.guacamoleService.closeConsole.next();

    this.guacamoleService.unbindHandlers();
  }

  ngOnDestroy(): void {
    this.guacamoleService.disconnect();
    this.guacamoleService.activeGuacamoleConnection$.next(null);
    this.resetData();
    this.openConnections.clear();
  }
}
