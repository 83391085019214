import { ErrorHandler, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from './service/notifications.service';

export const ERROR_CODES = {
  INTERNAL_ERROR: 'cybexer.error.INTERNAL_ERROR',
  BAD_REQUEST: 'cybexer.error.BAD_REQUEST',
  UNAUTHENTICATED_REQUEST: 'cybexer.error.UNAUTHENTICATED_REQUEST',
  FORBIDDEN: 'cybexer.error.FORBIDDEN',
  ENTITY_NOT_FOUND: 'cybexer.error.ENTITY_NOT_FOUND',
  CONFLICT: 'cybexer.error.CONFLICT',
  PAYLOAD_TOO_LARGE: 'cybexer.error.PAYLOAD_TOO_LARGE',
  URI_TOO_LONG: 'cybexer.error.URI_TOO_LONG',
  UNSUPPORTED_MEDIA_TYPE: 'cybexer.error.UNSUPPORTED_MEDIA_TYPE',
};

export abstract class CommonErrorHandler implements ErrorHandler {
  private cachedRouter: Router;

  protected constructor(
    private injector: Injector,
    private notificationsService: NotificationsService,
    private zone: NgZone
  ) {}

  handleError(error: any): void {
    // rxjs firstValueFrom throws error in slightly different format
    if (error.rejection && !error.rejection.code) {
      error = error.rejection;
    }

    if (error.rejection && error.rejection.code) {
      this.processByRejectionCode(error);
    } else if (error.status != null) {
      this.processByStatus(error);
    } else if (this.isSessionHttpError(error)) {
      // if a session resolver get http error, we need to redirect to global error page.
      this.zone.run(() => this.router.navigateByUrl('/error/' + error.rejection.status));
    } else if (
      error instanceof TypeError &&
      error.message === 'this.finishPromiseResolver is not a function'
    ) {
      console.debug(error.name, error.message);
    } else {
      this.showError('cybexer.error.connectionProblem', 'cybexer.error.refreshPage');
      console.error(error);
    }
  }

  private processByRejectionCode(error: any) {
    switch (error.rejection.code) {
      case ERROR_CODES.BAD_REQUEST:
        this.process400Error();
        break;
      case ERROR_CODES.UNAUTHENTICATED_REQUEST:
        this.process401Error();
        break;
      case ERROR_CODES.FORBIDDEN:
        this.process403Error();
        break;
      case ERROR_CODES.ENTITY_NOT_FOUND:
        this.process404Error();
        break;
      case ERROR_CODES.CONFLICT:
        this.process409Error();
        break;
      case ERROR_CODES.PAYLOAD_TOO_LARGE:
        this.process413Error();
        break;
      case ERROR_CODES.URI_TOO_LONG:
        this.process414Error();
        break;
      case ERROR_CODES.UNSUPPORTED_MEDIA_TYPE:
        this.process415Error();
        break;
      case ERROR_CODES.INTERNAL_ERROR:
        this.process500Error();
        break;
      default:
        this.showError('cybexer.error.errorOccurred', error.statusText);
    }
  }

  private processByStatus(error: any) {
    switch (error.status) {
      case 400:
        this.process400Error(error);
        break;
      case 401:
        this.process401Error();
        break;
      case 403:
        this.process403Error(error);
        break;
      case 404:
        this.process404Error(error);
        break;
      case 409:
        this.process409Error(error);
        break;
      case 413:
        this.process413Error(error);
        break;
      case 414:
        this.process414Error(error);
        break;
      case 415:
        this.process415Error(error);
        break;
      case 500:
        this.process500Error();
        break;
      case 504:
      case 0:
        this.showError('cybexer.error.connectionProblem', '');
        break;
      default:
        this.showError('cybexer.error.errorOccurred', error.statusText);
    }
  }

  private isSessionHttpError(error: any): boolean {
    return (
      error.rejection &&
      error.rejection.status !== 0 &&
      error.rejection.url &&
      error.rejection.url.indexOf('auth/whoami') !== -1
    );
  }

  private get router(): Router {
    if (!this.cachedRouter) {
      this.cachedRouter = this.injector.get(Router);
    }
    return this.cachedRouter;
  }

  private process400Error(error: any = null) {
    this.showError(ERROR_CODES.BAD_REQUEST, this.getErrorMessage(error));
  }

  private process401Error() {
    const path = window.location.pathname;
    if (path.indexOf(this.loginPage) === -1) {
      this.zone.run(() => {
        this.router.navigateByUrl(this.loginPage + encodeURIComponent(path));
      });
    }
  }

  private process403Error(error: HttpErrorResponse = null) {
    this.showError(ERROR_CODES.FORBIDDEN, this.getErrorMessage(error));
  }

  private process404Error(error: HttpErrorResponse = null) {
    this.showError(ERROR_CODES.ENTITY_NOT_FOUND, this.getErrorMessage(error));
  }

  private process409Error(error: HttpErrorResponse = null) {
    this.showError(ERROR_CODES.CONFLICT, this.getErrorMessage(error));
  }

  private process413Error(error: HttpErrorResponse = null) {
    this.showError(ERROR_CODES.PAYLOAD_TOO_LARGE, this.getErrorMessage(error));
  }

  private process414Error(error: HttpErrorResponse = null) {
    this.showError(ERROR_CODES.URI_TOO_LONG, this.getErrorMessage(error));
  }

  private process415Error(error: HttpErrorResponse = null) {
    this.showError(ERROR_CODES.UNSUPPORTED_MEDIA_TYPE, this.getErrorMessage(error));
  }

  private process500Error() {
    this.showError(ERROR_CODES.INTERNAL_ERROR, 'cybexer.error.tryAgainLater');
  }

  private showError(title: string, content?: string) {
    this.zone.run(() => this.notificationsService.error(title, content));
  }

  abstract get loginPage(): string;

  abstract getErrorMessage(errorResponse?: HttpErrorResponse): string;
}
