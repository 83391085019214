import { Component, Input } from '@angular/core';
import { NavigationMenuGroup } from '../navigation-menu.model';

@Component({
  selector: 'cybexer-menu-section',
  templateUrl: 'menu-section.component.html',
  styleUrls: ['menu-section.component.scss'],
})
export class MenuSectionComponent {
  @Input() name: String;
  @Input() description: String = '';
  @Input() icon: String;
  @Input() groups: NavigationMenuGroup[] = [];
}
