<cybexer-widget-header
  [title]="'Step 2'"
  [inline]="true"
></cybexer-widget-header>

<cybexer-widget-body>
  <mat-card class="full-card separate">
    <mat-card-title class="title-line">
      <cybexer-flex class="title-text" verticalAlign="center">
        Customize Your Digital Twin Environment
        <mat-icon
          [matTooltip]="'Select and configure servers, services, tools, and network segments to replicate your specific technology environment.'"
        >
          info
        </mat-icon>
      </cybexer-flex>
      <button mat-raised-button (click)="configureSegments()">
        <mat-icon>settings</mat-icon>
        <div>Configure Segments</div>
      </button>
    </mat-card-title>
    <mat-divider></mat-divider>

    <mat-card-content>
      <mat-accordion>
        @for (
          segment of segments;
          track segment.title;
          let k = $index;
          let first = $first
        ) {
          @if (segment.selected || !segment.type) {
            <mat-expansion-panel class="segment-panel" [expanded]="first">
              <mat-expansion-panel-header expandedHeight="48px">
                <div class="header">
                  <div>{{ segment.title }}</div>
                  <div class="type">{{ segment.type }}</div>
                </div>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <div class="body separate">
                @if (segment.type === "WORKSTATIONS" || segment.flat) {
                  <div class="services">
                    @for (
                      server of segment.servers;
                      track server.title;
                      let i = $index
                    ) {
                      <div class="box">
                        <div class="icon-wrapper">
                          <vlm-icon
                            [inputIcon]="server.type ? server.type : 'ram'"
                          ></vlm-icon>
                        </div>
                        <div class="text">
                          <div class="disable-uppercase">
                            {{ server.title }}
                          </div>
                          <button
                            mat-icon-button
                            class="small-icon-button edit-button"
                            (click)="editServerOrService(server, true)"
                          >
                            <mat-icon>edit</mat-icon>
                          </button>
                        </div>
                        <cybexer-confirm-action class="delete">
                          <button
                            mat-icon-button
                            class="small-icon-button"
                            (click)="deleteServer(segment, i)"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </cybexer-confirm-action>
                      </div>
                    }
                    <div class="server">
                      <div
                        class="box new-box"
                        (click)="addServer(segment)"
                        matRipple
                      >
                        <div class="icon-wrapper">
                          <i
                            class="icon fa fa-plus-circle"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div class="text">New</div>
                      </div>
                    </div>
                  </div>
                } @else {
                  <div class="body-title">
                    <div>Servers</div>
                    <div>Services</div>
                  </div>
                  @for (
                    server of segment.servers;
                    track server.title;
                    let i = $index
                  ) {
                    <div class="server-row">
                      <div class="server">
                        <div class="box">
                          <div class="icon-wrapper">
                            <vlm-icon
                              [inputIcon]="server.type ? server.type : 'ram'"
                            ></vlm-icon>
                          </div>
                          <div class="text">
                            <div class="disable-uppercase">
                              {{ server.title }}
                            </div>
                            <button
                              mat-icon-button
                              class="small-icon-button edit-button"
                              (click)="editServerOrService(server, true)"
                            >
                              <mat-icon>edit</mat-icon>
                            </button>
                          </div>
                          <cybexer-confirm-action class="delete">
                            <button
                              mat-icon-button
                              class="small-icon-button"
                              (click)="deleteServer(segment, i)"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                          </cybexer-confirm-action>
                        </div>
                      </div>

                      <div class="dash">
                        <mat-icon>navigate_next</mat-icon>
                      </div>

                      <div class="services">
                        @for (
                          service of server.services;
                          track service;
                          let j = $index
                        ) {
                          <div class="box">
                            <div class="icon-wrapper">
                              <vlm-icon
                                [inputIcon]="
                                  service.type ? service.type : 'ram'
                                "
                              ></vlm-icon>
                            </div>
                            <div class="text">
                              <div class="disable-uppercase">
                                {{ service?.title }}
                              </div>
                              <button
                                mat-icon-button
                                class="small-icon-button edit-button"
                                (click)="editServerOrService(service)"
                              >
                                <mat-icon>edit</mat-icon>
                              </button>
                            </div>
                            <cybexer-confirm-action class="delete">
                              <button
                                mat-icon-button
                                class="small-icon-button"
                                (click)="deleteService(server, j)"
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                            </cybexer-confirm-action>
                          </div>
                        }
                        <div
                          class="box new-box"
                          (click)="addService(server)"
                          matRipple
                        >
                          <div class="icon-wrapper">
                            <i
                              class="icon fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div class="text">New</div>
                        </div>
                      </div>
                    </div>
                  }
                  <div class="server-row">
                    <div class="server">
                      <div
                        class="box new-box"
                        (click)="addServer(segment)"
                        matRipple
                      >
                        <div class="icon-wrapper">
                          <i
                            class="icon fa fa-plus-circle"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div class="text">New</div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </mat-expansion-panel>
          }
        }

        @if (!isAnySegmentVisible) {
          <button mat-button (click)="configureSegments()" class="wide-btn">
            <mat-icon>add_circle</mat-icon>
            Add a segment
          </button>
        }


        @if (scenariosAndVulnerabilities?.length) {
          <mat-divider class="segment-divider"></mat-divider>
          <mat-expansion-panel class="segment-panel">
            <mat-expansion-panel-header expandedHeight="48px">
              Scenarios and Vulnerabilities
            </mat-expansion-panel-header>
            <mat-divider></mat-divider>
            <div class="body separate">
              @for (sv of scenariosAndVulnerabilities; track sv.name) {
                <mat-checkbox [checked]="sv.selected" (click)="toggleSv(sv)">
                  {{ sv.name }}
                </mat-checkbox>
              }
            </div>
          </mat-expansion-panel>
        }
      </mat-accordion>
    </mat-card-content>

    <div class="actions" id="image">
      <button mat-raised-button (click)="viewRange()">View the Range</button>

      <button mat-raised-button color="primary" (click)="continue()">
        Continue to step 3/3
      </button>
    </div>
  </mat-card>
</cybexer-widget-body>
