import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { forkJoin, from, Observable, of, switchMap, timeout } from 'rxjs';
import {
  IAIFabricPromptOverrideService,
  ChatType,
  AIFabricService,
  AvailableLLM,
} from '../../service/ai-fabric.service';
import { NotificationsService } from '../../service/notifications.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { catchError, first } from 'rxjs/operators';
import { PromptOverride, UpdatePromptOverrideInput } from '../../model/prompt-override.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { logFormErrors } from '../../util/utils';
import { NamedEntity } from '../../model/named-entity.model';
import { MonacoCommonsEditorService } from '../../service/monaco-commons-editor.service';
import { TranslateService } from '@ngx-translate/core';

export enum PromptType {
  SITUATION_REPORT_ASSESSMENT = 'SITUATION_REPORT_ASSESSMENT',
  INCIDENT_REPORT_ASSESSMENT = 'INCIDENT_REPORT_ASSESSMENT',
  ATTACK_REPORT_ASSESSMENT = 'ATTACK_REPORT_ASSESSMENT',
  CTF_TASK_REPORT_ASSESSMENT = 'CTF_TASK_REPORT_ASSESSMENT',
  AAR_OBJECTIVE_ANALYSIS = 'AAR_OBJECTIVE_ANALYSIS',
}

export type PromptId = PromptType | ChatType;

export type PromptOverrideData = {
  prompt: string;
  defaultPrompt: string;
  isPromptEnabled: boolean;
  isChatPrompt?: boolean;
  promptId: PromptId;
  modelConfigurationId?: string;
  modelDisplayName?: string;
};

@Component({
  selector: 'cybexer-aifabric-prompt-override-dialog',
  templateUrl: './aifabric-prompt-override-dialog.component.html',
  styleUrls: ['./aifabric-prompt-override-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AIFabricPromptOverrideDialogComponent implements OnInit {
  selectedPromptOverride: PromptOverride = this.createPromptOverride();
  selectedPrompt?: PromptOverrideData;
  visiblePromptOverrides: PromptOverride[] = [];
  promptOverridesData?: PromptOverrideData[];
  isProcessing = true;
  isEditingName = false;
  form: FormGroup;
  platformId?: string;
  readonly monacoEditorMaxHeight: string = 'calc(80vh - 17.5rem)';

  private readonly chatTypes: string[] = Object.values(ChatType);
  private readonly promptTypes: string[] = Object.values(PromptType);
  readonly promptIds: PromptId[] = this.promptTypes.concat(this.chatTypes) as PromptId[];
  private promptOverrides?: PromptOverride[];
  private defaultPromptOverridesData?: PromptOverrideData[];
  private defaultPrompts?: PromptOverride;
  private defaultAvailableLLM: AvailableLLM = {
    displayName: 'Default',
    modelConfigurationId: null,
    llmProviderId: undefined,
  };
  availableLLMs = [this.defaultAvailableLLM];
  private availablePlatforms: { id: string; name: string }[] = [];
  private aiFabricPromptOverrideService: IAIFabricPromptOverrideService;

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private aiFabricService: AIFabricService,
    private monacoCommonsEditorService: MonacoCommonsEditorService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AIFabricPromptOverrideDialogInput
  ) {
    this.aiFabricPromptOverrideService =
      this.data.aiFabricPromptOverrideService || this.aiFabricService;
    this.availablePlatforms = this.data.availablePlatforms || [];
    this.platformId = this.data.platformId;

    if (this.data.editablePromptIds) {
      this.chatTypes = this.chatTypes.filter((it) =>
        this.data.editablePromptIds.includes(it as PromptId)
      );
      this.promptTypes = this.promptTypes.filter((it) =>
        this.data.editablePromptIds.includes(it as PromptId)
      );
      this.promptIds = this.promptTypes.concat(this.chatTypes) as PromptId[];
    }
    if (this.data.platformId) {
      this.monacoEditorMaxHeight = 'calc(80vh - 20rem)';
    }
    if (this.data.isLightTheme$) {
      monacoCommonsEditorService.setIsLightThemeObservable(this.data.isLightTheme$);
    }
  }

  async ngOnInit() {
    forkJoin([
      this.aiFabricPromptOverrideService
        .getDefaultPrompts()
        .then((defaultPrompts) => {
          this.defaultPrompts = defaultPrompts;
          this.defaultPromptOverridesData = this.preparePromptOverrideData(defaultPrompts);
        })
        .catch((e) => {
          console.error('Failed to fetch default prompts', e);
          this.notificationService.error(
            this.translate.instant('cybexer.aiFabric.defaultPromptsFetchError', {
              default: 'Failed to fetch default prompts',
            })
          );
          this.defaultPrompts = null;
        })
        .finally(() => {
          this.isProcessing = this.promptOverrides === undefined;
          this.selectedPrompt = this.data.mainPromptId
            ? this.findPromptData(this.data.mainPromptId)
            : this.promptOverridesData?.length
              ? this.promptOverridesData[0]
              : this.defaultPromptOverridesData[0];

          this.form = this.fb.group({
            selectedPromptOverride: new UntypedFormControl(this.selectedPromptOverride),
            selectedPrompt: new UntypedFormControl(this.selectedPrompt),
            name: new UntypedFormControl('', Validators.required),
            isShared: new UntypedFormControl(false, Validators.required),
            isFilterByThisPlatform: new UntypedFormControl(false, Validators.required),
            preferenceOverride: new UntypedFormControl(false, Validators.required),
            promptOverrides: this.fb.array(
              this.promptIds.map((promptId) => this.promptFromGroup('', promptId))
            ),
          });
          this.subscribeToFormValueChanges();
          this.resetForm();
        }),
      this.aiFabricPromptOverrideService.getAIFabricModels(this.data.platformId).then((models) => {
        this.availableLLMs = [this.defaultAvailableLLM].concat(models);
      }),
    ])
      .pipe(
        first(),
        switchMap(() => this.fetchPromptOverrides())
      )
      .subscribe(() => this.updateForm());
  }

  subscribeToFormValueChanges() {
    this.promptOverridesFormArray.controls.forEach((group: FormGroup, index) => {
      const defaultPrompt = this.defaultPromptOverridesData.find(
        (p) => p.promptId === this.promptIds[index]
      )?.defaultPrompt;
      this.getPromptControl(group).valueChanges.subscribe((value) => {
        if (this.promptOverridesData?.length) {
          const isOverridden = value !== defaultPrompt;
          this.promptOverridesData[index].isPromptEnabled = isOverridden;
          this.getIsPromptEnabledControl(group).setValue(isOverridden);
        }
      });
      this.getModelConfigurationIdControl(group).valueChanges.subscribe((value) => {
        if (this.promptOverridesData?.length) {
          this.promptOverridesData[index].modelConfigurationId = value;
          this.promptOverridesData[index].modelDisplayName = this.displayName(value);
        }
      });
    });
  }

  displayName(id: string) {
    return this.availableLLMs.find((it) => it.modelConfigurationId == id)?.displayName || id;
  }

  preparePromptOverrideData(promptOverride: PromptOverride): PromptOverrideData[] {
    const defaultPrompts = this.defaultPrompts;
    return this.promptTypes
      .map((promptType) => {
        const prompt = promptOverride.prompts?.find((p) => p.promptType === promptType);
        const defaultPrompt =
          defaultPrompts?.prompts.find((p) => p.promptType === promptType)?.prompt || '';
        return {
          prompt: prompt?.prompt || defaultPrompt,
          defaultPrompt: defaultPrompt,
          promptId: promptType,
          modelConfigurationId: prompt?.modelConfigurationId,
          modelDisplayName: this.displayName(prompt?.modelConfigurationId),
        } as PromptOverrideData;
      })
      .concat(
        this.chatTypes.map((chatType) => {
          const chatPrompt = promptOverride.chatPrompts?.find((p) => p.chatType === chatType);
          const defaultPrompt =
            defaultPrompts?.chatPrompts.find((p) => p.chatType === chatType)?.chatPrompt || '';
          return {
            prompt: chatPrompt?.chatPrompt || defaultPrompt,
            defaultPrompt: defaultPrompt,
            promptId: chatType,
            isChatPrompt: true,
          } as PromptOverrideData;
        })
      )
      .map((data) => {
        const enabled = data.prompt !== data.defaultPrompt;
        return {
          ...data,
          isPromptEnabled: enabled,
        };
      });
  }

  buildPromptOverride(promptOverrideData: PromptOverrideData[]): UpdatePromptOverrideInput {
    return {
      prompts: promptOverrideData
        .filter((it) => this.promptTypes.includes(it.promptId as ChatType))
        .map((it) => ({
          prompt: it.isPromptEnabled ? it.prompt : null,
          promptType: it.promptId as PromptType,
          modelConfigurationId: it.modelConfigurationId,
        })),
      chatPrompts: promptOverrideData
        .filter((it) => this.chatTypes.includes(it.promptId as ChatType))
        .map((it) => ({
          chatPrompt: it.isPromptEnabled ? it.prompt : null,
          chatType: it.promptId as ChatType,
        })),
    } as UpdatePromptOverrideInput;
  }

  resetForm() {
    const prompts = this.defaultPrompts
      ? this.defaultPromptOverridesData
          .map((prompt) => ({
            prompt: prompt.prompt,
            promptId: prompt.promptId,
            isPromptEnabled: false,
            modelConfigurationId: null,
          }))
          .filter(
            (it) =>
              !this.data.editablePromptIds || this.data.editablePromptIds.includes(it.promptId)
          )
      : this.promptIds.map((promptId: PromptId) => ({
          promptId,
          prompt: '',
          isPromptEnabled: true,
          modelConfigurationId: null,
        }));

    this.selectedPromptOverride = this.createPromptOverride();
    this.selectedPromptOverrideControl.setValue(this.selectedPromptOverride);
    this.nameControl.setValue('');
    this.isSharedControl.setValue(false);
    this.promptOverridesFormArray.setValue(prompts);
  }

  private promptFromGroup(initialPrompt: string, promptId: string, isOverridden: boolean = true) {
    return this.fb.group({
      isPromptEnabled: new UntypedFormControl(isOverridden),
      prompt: new UntypedFormControl(initialPrompt),
      promptId: new UntypedFormControl(promptId, Validators.required),
      modelConfigurationId: new UntypedFormControl(undefined),
    });
  }

  resetPrompt(promptGroup: any, promptData: PromptOverrideData) {
    const promptControl = this.getPromptControl(promptGroup);
    promptControl.setValue(promptData.defaultPrompt || '');
  }

  findPromptData(promptId: PromptId) {
    return (
      this.promptOverridesData?.find((it) => it.promptId === promptId) ||
      this.defaultPromptOverridesData.find((it) => it.promptId === promptId)
    );
  }

  getPromptControl(chatGroup: any) {
    return chatGroup.controls['prompt'] as FormControl;
  }

  getIsPromptEnabledControl(chatGroup: any) {
    return chatGroup.controls['isPromptEnabled'] as FormControl;
  }

  getModelConfigurationIdControl(chatGroup: any) {
    return chatGroup.controls['modelConfigurationId'] as FormControl;
  }

  get promptOverridesFormArray() {
    return this.form?.controls['promptOverrides'] as FormArray;
  }

  get nameControl() {
    return this.form?.controls['name'] as FormControl;
  }

  get isSharedControl() {
    return this.form?.controls['isShared'] as FormControl;
  }

  get selectedPromptOverrideControl() {
    return this.form?.controls['selectedPromptOverride'] as FormControl;
  }

  get isFilterByThisPlatformControl() {
    return this.form?.controls['isFilterByThisPlatform'];
  }

  get preferenceOverrideControl() {
    return this.form?.controls['preferenceOverride'];
  }

  fetchPromptOverrides() {
    this.isProcessing = true;
    this.promptOverrides = undefined;

    return from(this.aiFabricPromptOverrideService.getPromptOverrides()).pipe(
      first(),
      timeout(10000),
      catchError(() => {
        this.notificationService.error(
          this.translate.instant('cybexer.aiFabric.promptOverridesFetchError', {
            default: 'Failed to fetch prompt overrides',
          })
        );
        this.isProcessing = this.defaultPrompts === undefined;
        return [];
      }),
      switchMap((promptOverrides: PromptOverride[]) => {
        this.promptOverrides = promptOverrides.map((promptOverride) => ({
          ...promptOverride,
          platformName: this.availablePlatforms.find((p) => p.id === promptOverride.platformId)
            ?.name,
        }));
        this.isEditingName = false;
        return of(true);
      })
    );
  }

  private updateForm() {
    this.isProcessing = false;

    this.updateVisiblePromptOverrides();

    const idToSelect = this.data.currentPreferenceOverrideId || this.data.currentSystemOverrideId;
    const existingIndex = idToSelect
      ? this.visiblePromptOverrides.findIndex((it) => it.id === idToSelect)
      : this.visiblePromptOverrides.findIndex((it) => it.id === this.selectedPromptOverride.id);
    if (existingIndex > -1) {
      this.selectPromptOverride(existingIndex);
    } else if (this.visiblePromptOverrides.length) {
      this.selectPromptOverride(0);
    }
  }

  protected updateVisiblePromptOverrides() {
    this.visiblePromptOverrides = this.platformId
      ? this.promptOverrides.filter(
          (it) => !this.isFilterByThisPlatformControl?.value || it.platformId === this.platformId
        )
      : this.promptOverrides;
    if (this.visiblePromptOverrides.length === 0) {
      this.patchSelectPromptOverride(this.createPromptOverride());
    } else if (
      !this.visiblePromptOverrides.find((it) => it.id === this.selectedPromptOverride.id)
    ) {
      this.selectPromptOverride(0);
    }
  }

  private selectPromptOverride(index: number) {
    this.patchSelectPromptOverride(this.visiblePromptOverrides[index]);
    this.selectedPromptOverrideControl.setValue(this.selectedPromptOverride);
  }

  protected patchSelectPromptOverride(selectedPromptOverride: PromptOverride) {
    this.promptOverridesData = this.preparePromptOverrideData(selectedPromptOverride);
    this.selectedPrompt = this.findPromptData(
      this.selectedPrompt?.promptId || this.data.mainPromptId || this.promptIds[0]
    );
    this.selectedPromptOverride = selectedPromptOverride;
    this.form.patchValue({
      name: selectedPromptOverride.name,
      isShared: !selectedPromptOverride.isPrivate,
      promptOverrides: this.promptOverridesData,
      selectedPrompt: this.selectedPrompt,
      preferenceOverride: this.selectedPromptOverride.id === this.data.currentPreferenceOverrideId,
    });
  }

  private createPromptOverride() {
    return {
      id: undefined,
      platformId: this.platformId,
      name: '',
      prompts: this.defaultPrompts?.prompts || [],
      chatPrompts: this.defaultPrompts?.chatPrompts || [],
    };
  }

  addPromptOverride() {
    this.selectedPromptOverride = this.createPromptOverride();
    this.resetForm();
    this.isEditingName = true;
  }

  async savePromptOverride() {
    if (this.form.invalid) {
      logFormErrors(this.form);
      return;
    }

    const platformId = this.selectedPromptOverride.platformId || this.platformId;
    const promptOverride = {
      name: this.form.value.name,
      isPrivate: !this.form.value.isShared,
      ...this.buildPromptOverride(this.promptOverridesFormArray.value),
    };

    let newId: string;
    if (this.selectedPromptOverride?.id) {
      if (
        await this.aiFabricPromptOverrideService.updatePromptOverride({
          id: this.selectedPromptOverride.id,
          ...promptOverride,
        })
      ) {
        newId = this.selectedPromptOverride.id;
      }
    } else {
      newId = await this.aiFabricPromptOverrideService.createPromptOverride(
        promptOverride,
        platformId
      );
    }

    if (newId) {
      this.notificationService.success(
        this.translate.instant('cybexer.aiFabric.promptOverrideSaved', {
          default: 'Prompt override saved',
        })
      );
    } else {
      this.notificationService.success(
        this.translate.instant('cybexer.aiFabric.promptOverrideSaveError', {
          default: 'Failed to save prompt override',
        })
      );
    }
    this.selectedPromptOverride = {
      platformId: this.selectedPromptOverride.platformId,
      id: this.selectedPromptOverride.id || newId,
      ...promptOverride,
    };
    this.fetchPromptOverrides().subscribe(() => this.updateForm());
  }

  async removeSelectedPromptOverride() {
    if (!this.selectedPromptOverride.id) return;
    await this.aiFabricPromptOverrideService.removePromptOverride(this.selectedPromptOverride.id);
    const i = this.promptOverrides.findIndex((it) => it.id === this.selectedPromptOverride.id);
    this.promptOverrides.splice(i, 1);
    this.updateVisiblePromptOverrides();
    this.notificationService.success(
      this.translate.instant('cybexer.aiFabric.promptOverrideDeleted', {
        default: 'Prompt override deleted',
      })
    );
  }

  togglePreferenceOverride() {
    if (this.data.currentPreferenceOverrideId === this.selectedPromptOverride.id) {
      this.data.currentPreferenceOverrideId = undefined;
      this.data.onPreferenceOverride(undefined);
    } else {
      this.data.currentPreferenceOverrideId = this.selectedPromptOverride.id;
      this.data.onPreferenceOverride(this.selectedPromptOverride);
    }
    this.preferenceOverrideControl.setValue(
      this.selectedPromptOverride.id === this.data.currentPreferenceOverrideId
    );
    this.notificationService.success(
      this.translate.instant('cybexer.aiFabric.promptPreferenceUpdated', {
        default: 'Personal prompt override preference updated',
      })
    );
  }

  copyExerciseJsonValue() {
    navigator.clipboard
      .writeText(
        '"promptOverride": ' +
          JSON.stringify({
            id: this.selectedPromptOverride.id,
            name: this.selectedPromptOverride.name,
          }) +
          ','
      )
      .then(() =>
        this.notificationService.info(
          this.translate.instant('cybexer.aiFabric.jsonCopiedToClipboard', {
            promptName: this.selectedPromptOverride.name,
            default: `JSON code for '${this.selectedPromptOverride.name}' copied to clipboard`,
          })
        )
      );
  }

  editName() {
    this.isEditingName = true;
  }

  setAllModels(modelConfigurationId?: string) {
    const name = this.displayName(modelConfigurationId);
    this.promptOverridesData.forEach((prompt) => {
      if (prompt.isChatPrompt) return;
      prompt.modelConfigurationId = modelConfigurationId;
      prompt.modelDisplayName = name;
    });
    this.promptOverridesFormArray.controls.forEach((group: FormGroup) => {
      this.getModelConfigurationIdControl(group).setValue(modelConfigurationId);
    });
  }
}

export type AIFabricPromptOverrideDialogInput = {
  isLightTheme$?: Observable<boolean>;
  aiFabricAdminKey: string;
  aiFabricUrl: string;
  aiFabricPromptOverrideService?: IAIFabricPromptOverrideService;
  platformId?: string;
  availablePlatforms?: { id: string; name: string }[];
  onPreferenceOverride?: (selectedPromptOverride: NamedEntity) => void;
  isExerciseAdmin?: boolean;
  currentPreferenceOverrideId?: string;
  currentSystemOverrideId?: string;
  mainPromptId?: PromptId;
  editablePromptIds?: PromptId[];
  userName?: string;
};
