@if (group) {
  <mat-list class="dashboard-list-container" dense>
    @if (group.name) {
      <mat-list-item class="sidenav-group" [disableRipple]="true">
        <i matListItemIcon class="{{ group.icon }}" aria-hidden="true"></i>
        <span matListItemLine>{{ group.name }}</span>
      </mat-list-item>
    }
    <mat-nav-list>
      <ng-content></ng-content>
    </mat-nav-list>
  </mat-list>
}
