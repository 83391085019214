import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Segment, Selection, Server } from '../step2.component';

import { ConfiguredRangeService } from './configured-range.service';
import { Router } from '@angular/router';

declare var LeaderLine: any;

@Component({
  selector: 'vlm-configured-range',
  templateUrl: './configured-range.component.html',
  styleUrls: ['./configured-range.component.scss'],
})
export class ConfiguredRangeComponent
  implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked
{
  rawSegments: Segment[] = [];
  scenariosAndVulnerabilities: Selection[] = [];
  viewItems: ViewItem[] = [];
  lines = [];

  segments: Segments = {
    dev: [],
    cloud: [],
    wifi: null,
    nseg: [],
  };

  services: Services = {
    fw1: ['FW'],
    side: ['FW', ''],
    mid: ['R1', 'R2', ''],
    computers: ['PC1', 'PC2'],
  };

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private rangeService: ConfiguredRangeService,
  ) {}

  ngOnInit(): void {
    this.rangeService.setSpecificComponentActive(true);
    const storedSVS = localStorage.getItem('svs');
    this.scenariosAndVulnerabilities = storedSVS
      ? JSON.parse(storedSVS).filter((sv: Selection) => sv.selected)
      : [];
    this.viewItems = [
      { idPrefix: 'fw', items: this.services.fw1, icon: 'hard drive' },
      { idPrefix: 'side', items: this.services.side, icon: 'hard drive' },
      { idPrefix: 'mid', items: this.services.mid, icon: 'hard drive' },
      { idPrefix: 'pc', items: this.services.computers, icon: 'computer' },
    ];

    this.initializeSegments();
    this.generateGrid(this.rawSegments);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createLines();
      this.rerenderLines();
    }, 0);
  }

  ngAfterViewChecked(): void {
    this.redrawLines();
  }

  ngOnDestroy(): void {
    this.destroyLines();
    this.rangeService.setSpecificComponentActive(false);
  }

  private initializeSegments(): void {
    const storedSegments = localStorage.getItem('segments');
    if (storedSegments) {
      this.rawSegments = JSON.parse(storedSegments).filter(
        (segment) => segment.selected,
      );
    }
  }

  generateGrid(segments: Segment[]): void {
    segments.forEach((segment) => {
      switch (segment.service) {
        case 'dev':
          this.segments.dev.push(segment);
          break;
        case 'cloud':
          this.segments.cloud.push(segment);
          break;
        case 'wifi':
          this.segments.wifi = segment;
          break;
        case 'other':
          this.processSideSegment(segment);
          break;
      }
    });

    // Sort the cloud array by the number of servers (ascending) for visual preferences in range
    this.segments.cloud.sort((a, b) => {
      const aServers = a.servers?.length || 0;
      const bServers = b.servers?.length || 0;
      return aServers - bServers;
    });
  }

  private processSideSegment(segment: Segment): void {
    const servers = segment?.servers || [];
    this.segments.nseg.push({
      title: segment.title,
      type: segment.type,
      service: segment.service,
      windows: servers.filter((server) => server.type === 'WINDOWS'),
      linux: servers.filter((server) => server.type === 'LINUX'),
      freebsd: servers.filter((server) => server.type === 'FREEBSD'),
      plc: servers.filter((server) => server.type === 'PLC'),
      other: servers.filter(
        (server) =>
          !['WINDOWS', 'LINUX', 'FREEBSD', 'PLC'].includes(server.type),
      ),
    });
  }

  getTooltip(server: any): string {
    let tooltip = `${server.type}\n${server.title}`;
    if (server.services) {
      tooltip += ':';
      server.services.forEach((service) => {
        tooltip += `\n${service.title}`;
      });
    }
    return tooltip;
  }

  destroyLines(): void {
    const panZoomElement = document.body;
    const leaderLineDefs = document.querySelector('#leader-line-defs');
    if (leaderLineDefs) {
      this.renderer.appendChild(panZoomElement, leaderLineDefs);
    }

    document.querySelectorAll('.leader-line').forEach((lineEl) => {
      this.renderer.appendChild(panZoomElement, lineEl);
    });

    this.lines.forEach((line) => line.remove());
    this.lines = [];
  }

  redrawLines(): void {
    this.lines.forEach((line) => line.position());
  }

  private rerenderLines(): void {
    const panZoomElement = document.querySelector('.grid-container');
    const leaderLineDefs = document.querySelector('#leader-line-defs');
    if (leaderLineDefs) {
      this.renderer.appendChild(panZoomElement, leaderLineDefs);
    }

    document.querySelectorAll('.leader-line').forEach((lineEl) => {
      this.renderer.appendChild(panZoomElement, lineEl);
    });
  }

  onScroll(): void {
    this.redrawLines();
  }

  closeView(): void {
    this.router.navigate(['/step2']);
  }

  drawLine(
    el1: string,
    el2: string,
    endSocket?: string,
    startSocket?: string,
  ): void {
    const element1 = document.getElementById(el1);
    const element2 = document.getElementById(el2);

    if (element1 && element2) {
      const line = new LeaderLine(element1, element2, {
        color: '#c43d3d',
        size: 3,
        startPlug: 'arrow2',
        endPlug: 'arrow2',
        path: 'grid',
        startPlugColor: 'rgba(0, 0, 0, 0)',
        endPlugColor: 'rgba(0, 0, 0, 0)',
        endSocket: endSocket,
        startSocket: startSocket,
        startSocketGravity: 5,
        endSocketGravity: 5,
      });

      this.lines.push(line);
    }
  }

  private createLines(): void {
    this.segments.cloud.forEach((_, index) =>
      this.drawLine(`cloud-${index}`, 'internet', 'top', 'bottom'),
    );

    this.segments.nseg.forEach((_, index) => {
      this.drawLine('nseg-fw', `nseg-${index}`, 'left', 'right');
    });

    this.drawLine('nseg-fw', 'wifi', 'top', 'bottom');

    this.segments.dev.forEach((_, index) => {
      this.drawLine('fw-0', `dev-${index}`, 'bottom', 'top');
    });

    this.services.computers.forEach((_, index) => {
      this.drawLine('side-0', `pc-${index}`, 'top', 'bottom');
    });

    this.addStaticLines();
  }

  private addStaticLines(): void {
    // Business logic for generating switches, servers and firewalls isn't necessary yet
    // so currently the lines are all hardcoded to a default value
    this.drawLine('fw-0', 'side-1');
    this.drawLine('fw-0', 'internet');
    this.drawLine('side-0', 'side-1');
    this.drawLine('mid-0', 'internet', 'bottom', 'top');
    this.drawLine('mid-1', 'internet', 'bottom', 'top');
    this.drawLine('mid-0', 'mid-2', 'top', 'bottom');
    this.drawLine('mid-1', 'mid-2', 'top', 'bottom');
    this.drawLine('mid-2', 'nseg-fw');
    this.drawLine('mid-2', 'side-1');
  }
}

type NetworkSegment = Segment & {
  windows: Server[];
  linux: Server[];
  freebsd: Server[];
  plc: Server[];
  other: Server[];
};

type Segments = {
  dev: Segment[];
  cloud: Segment[];
  wifi: Segment;
  nseg: NetworkSegment[];
};

type Services = {
  fw1: string[];
  side: string[];
  mid: string[];
  computers: string[];
};

type ViewItem = {
  idPrefix: string;
  items: string[];
  icon: string;
};
