import { Injectable } from '@angular/core';
import Guacamole from 'guacamole-common-js';
import { Mimetype } from 'guacamole-common-js/lib/GuacCommon';
import { BehaviorSubject, EMPTY, lastValueFrom } from 'rxjs';
import { ClipboardData } from '../model/clipboard-data.model';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  clipboardCache$: BehaviorSubject<ClipboardData> = new BehaviorSubject<ClipboardData>(null);

  constructor() {}

  hasClipboardPermissions(): Promise<boolean> {
    return Promise.all([
      navigator.permissions.query({ name: 'clipboard-write' as PermissionName }),
      navigator.permissions.query({ name: 'clipboard-read' as PermissionName }),
    ])
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  async getLocalClipboard(): Promise<ClipboardData> {
    if (navigator.clipboard && navigator.clipboard.readText) {
      try {
        const text = await navigator.clipboard.readText();

        return new ClipboardData({
          mimetype: 'text/plain',
          data: text,
        });
      } catch (err) {
        console.error('Failed to read clipboard content: ', err);
        return this.clipboardCache$.value;
      }
    }
    // TODO test it!!!
    return lastValueFrom(EMPTY);
  }

  async setLocalClipboard(clipboardData: ClipboardData) {
    if (
      navigator.clipboard &&
      navigator.clipboard.writeText &&
      clipboardData.mimetype === 'text/plain' &&
      typeof clipboardData.data === 'string'
    ) {
      try {
        await navigator.clipboard.writeText(clipboardData.data);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
  }

  setGuacamoleRemoteClipboard(client): void {
    this.getLocalClipboard().then((data) => {
      this.sendDataToGuacamoleRemoteClipboard(client, data);
    });
  }

  sendDataToGuacamoleRemoteClipboard(client, clipboardData): void {
    if (client && clipboardData && clipboardData.data) {
      let writer: Guacamole.StringWriter;

      const stream = client.createClipboardStream(clipboardData.mimetype, 'clipboardStreamPipe');

      if (typeof clipboardData.data === 'string') {
        writer = new Guacamole.StringWriter(stream);
        writer.sendText(clipboardData.data);
        writer.sendEnd();
      }
    }
  }

  /**
   * Clipboard setting and handling from ngx-remote-desktop project
   * https://github.com/ILLGrenoble/ngx-remote-desktop
   */
  handleGuacamoleRemoteClipboard = (stream: Guacamole.InputStream, mimetype: Mimetype) => {
    const reader = new Guacamole.StringReader(stream);

    // Assemble received data into a single string
    let data = '';
    reader.ontext = (text: string) => {
      data += text;
    };

    // Set clipboard contents once stream is finished
    reader.onend = () => {
      const clipboardObj = new ClipboardData({
        mimetype: mimetype,
        data: data,
      });
      this.setLocalClipboard(clipboardObj);
      this.clipboardCache$.next(clipboardObj);
    };
  };
}
