import { Injectable, signal } from '@angular/core';
import { AIFabricChatbotWindowComponent } from '../components/chatbot-window/aifabric-chatbot-window.component';

@Injectable()
export class AIFabricChatbotService {
  private isChatHidden = signal<boolean>(true);
  private activeChatThreadId = signal<string>(null);
  private isThreadDirty = signal<boolean>(false);
  private aiChatWindow = signal<AIFabricChatbotWindowComponent>(null);

  constructor() {}

  get isChatHiddenSignal() {
    return this.isChatHidden.asReadonly();
  }

  get isThreadDirtySignal() {
    return this.isThreadDirty;
  }

  get aiChatbotWindowSignal() {
    return this.aiChatWindow.asReadonly();
  }

  get activeChatThreadIdSignal() {
    return this.activeChatThreadId;
  }

  setAIFabricChatbotWindowComponent(chatWindow: AIFabricChatbotWindowComponent) {
    this.aiChatWindow.set(chatWindow);
  }

  openChat() {
    this.isChatHidden.set(false);
  }

  closeChat() {
    this.isChatHidden.set(true);
  }

  toggleChat() {
    this.isChatHidden.update((value) => !value);
  }
}
