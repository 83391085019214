import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingService } from './loading.service';
import { takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'cybexer-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  animations: [
    trigger('loadingTrigger', [
      transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LoadingOverlayComponent implements OnInit, OnDestroy {
  loading: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService
      .getState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((loading: boolean) => (this.loading = loading));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
