import { Injectable, OnDestroy, Signal, signal } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { createClient, Client as SSEClient, ExecutionResult } from 'graphql-sse';
import { Client, fetchExchange, gql } from '@urql/core';
import { ChatbotResponse } from '../components/chatbot-window/aifabric-chatbot-window.component';
import {
  CreatePromptOverrideInput,
  PromptOverride,
  UpdatePromptOverrideInput,
} from '../model/prompt-override.model';

type ChatPayload = {
  isFinished: boolean;
  content?: string;
  error?: string;
  toolName?: string;
  toolArgs?: any[];
  toolCallId?: string;
};

export type ChatConversationTitle = {
  conversationId: string;
  title: string;
};

export type AvailableLLM = {
  llmProviderId: string;
  modelConfigurationId?: string;
  displayName: string;
  modelId?: string;
};

export enum ChatType {
  GENERIC = 'GENERIC',
  SYSTEM_DEFINITION = 'SYSTEM_DEFINITION',
  SYSTEM_DEFINITION_SIMPLIFY = 'SYSTEM_DEFINITION_SIMPLIFY',
  SYSTEM_DEFINITION_DOCUMENT = 'SYSTEM_DEFINITION_DOCUMENT',
  CTF_TASK = 'CTF_TASK',
}

export enum ChatConversationToolType {
  EDIT_FILE = 'edit-file',
  EDIT_CTF_TASK = 'edit-ctf-task',
}

export const CHAT_ROLE_USER = 'human';
export const CHAT_ROLE_ASSISTANT = 'ai';

export interface IAIFabricPromptOverrideService {
  getDefaultPrompts(): Promise<PromptOverride>;

  getPromptOverrides(context?: string): Promise<PromptOverride[]>;

  createPromptOverride(
    promptOverride: CreatePromptOverrideInput,
    platformId?: string
  ): Promise<string>;

  updatePromptOverride(promptOverride: UpdatePromptOverrideInput): Promise<boolean>;

  removePromptOverride(id: string): Promise<boolean>;

  getAIFabricModels(platformId?: string): Promise<AvailableLLM[]>;
}

@Injectable()
export class AIFabricService implements OnDestroy, IAIFabricPromptOverrideService {
  static AI_FABRIC_PATH = '/ai-fabric';

  private readonly targetUrl = document.location.origin;
  private urqlClient: Client;
  private sseClient: SSEClient;
  private isAIFabricAvailable$ = new BehaviorSubject<boolean>(false);
  private isAIFabricAvailableSignal = signal<boolean>(false);
  private hasAIFabricConnection$ = new BehaviorSubject<boolean>(false);
  private hasAIFabricConnectionSignal = signal<boolean>(false);
  private customHeaders?: any;

  constructor(private http: HttpClient) {}

  protected get sse() {
    return this.sseClient;
  }

  protected get urql() {
    return this.urqlClient;
  }

  updateAvailability(
    customHeaders?: any,
    apiUrl: string = `/rest/ng${AIFabricService.AI_FABRIC_PATH}`
  ) {
    this.customHeaders = customHeaders;
    this.isAIFabricAvailable(apiUrl)
      .pipe(
        catchError(() => of(false)),
        first()
      )
      .subscribe((isAvailable) => {
        this.isAIFabricAvailable$.next(isAvailable);
        this.isAIFabricAvailableSignal.set(isAvailable);
        if (isAvailable) {
          this.checkConnection();
        } else {
          this.hasAIFabricConnection$.next(false);
          this.hasAIFabricConnectionSignal.set(false);
        }
      });

    this.sseClient = createClient({
      url: this.targetUrl + apiUrl + '/stream',
      headers: this.customHeaders,
    });

    this.urqlClient = new Client({
      url: this.targetUrl + apiUrl,
      fetchOptions: () => {
        return {
          headers: this.customHeaders,
        };
      },
      exchanges: [fetchExchange],
    });
  }

  async checkConnection(): Promise<boolean> {
    try {
      const models = await this.getAIFabricModels();
      const hasConnection = models?.length > 0;
      this.hasAIFabricConnection$.next(hasConnection);
      this.hasAIFabricConnectionSignal.set(hasConnection);
      return hasConnection;
    } catch (error) {
      this.hasAIFabricConnection$.next(false);
      this.hasAIFabricConnectionSignal.set(false);
      console.warn(error);
      return false;
    }
  }

  isAvailable(): Observable<boolean> {
    return this.isAIFabricAvailable$.asObservable();
  }

  get isAvailableSignal(): Signal<boolean> {
    return this.isAIFabricAvailableSignal.asReadonly();
  }

  hasConnection(): Observable<boolean> {
    return this.hasAIFabricConnection$.asObservable();
  }

  get hasConnectionSignal(): Signal<boolean> {
    return this.hasAIFabricConnectionSignal.asReadonly();
  }

  ngOnDestroy() {
    this.isAIFabricAvailable$.complete();
    this.hasAIFabricConnection$.complete();
  }

  private isAIFabricAvailable(apiUrl: string): Observable<boolean> {
    return this.http.get<boolean>(`${apiUrl}/status`, {
      headers: this.customHeaders,
    });
  }

  sendMessage(
    content: string,
    conversationId: string,
    handleToken: (token: string) => void,
    handleFunctionCall: (toolName: string, toolArgs: any[]) => string | Promise<string>,
    enabledTools: string[],
    contextKeys: Record<string, string>,
    hiddenSuffix: string = '',
    cancelSignal?: AbortSignal,
    chatType?: ChatType
  ): Observable<ChatbotResponse> {
    const query = `
      subscription SendMessageSubscription(
        $content: String!
        $conversationId: String!
        $enabledTools: [String!]
        $contextKeys: [PromptVariable!]
        $hiddenSuffix: String!
        $chatType: String!
      ) {
        sendMessage(
          chatContext: {
            conversationId: $conversationId
            enabledTools: $enabledTools
            contextKeys: $contextKeys
            chatType: $chatType
          }
          input: $content
          hiddenSuffix: $hiddenSuffix
        ) {
          content
          error
          isFinished
          toolName
          toolArgs
          toolCallId
        }
      }
    `;

    return new Observable((observer) => {
      const unsubscribe = this.sse.subscribe(
        {
          query,
          variables: {
            conversationId,
            content,
            enabledTools,
            contextKeys: this.contextKeysAsArray(contextKeys),
            hiddenSuffix,
            chatType,
          },
        },
        {
          next: async (value: ExecutionResult) => {
            if (value.errors?.length) {
              observer.error(value.errors);
            }
            const data = value.data['sendMessage'] as unknown as ChatPayload;
            if (data) {
              if (data.toolName) {
                const result = await handleFunctionCall(data.toolName, data.toolArgs);
                if (data.toolCallId) {
                  await this.setToolResult(data.toolCallId, result);
                }
              } else if (data.isFinished || data.error) {
                observer.next({ content: data.content, error: data.error });
                observer.complete();
              } else {
                handleToken(data.content);
              }
            }
          },
          error: (error: unknown) => {
            observer.error(error);
          },
          complete: () => {
            observer.complete();
          },
        }
      );
      cancelSignal.onabort = () => {
        unsubscribe();
        observer.error('AbortError');
      };
    });
  }

  async getModelTokenLimit(conversationId: string) {
    const query = gql`
      query GetModelTokenLimitQuery($conversationId: String!) {
        getModelTokenLimit(conversationId: $conversationId)
      }
    `;

    const result = await this.urql.query(query, { conversationId }).toPromise();
    return this.handleError(result).data.getModelTokenLimit;
  }

  async countTokens(
    message: string,
    conversationId: string,
    enabledTools: ChatConversationToolType[],
    contextKeys: Record<string, string>
  ) {
    const query = gql`
      query CountTokensQuery(
        $message: String!
        $conversationId: String!
        $enabledTools: [String!]!
        $contextKeys: [PromptVariable!]!
      ) {
        countTokens(
          message: $message
          chatContext: {
            conversationId: $conversationId
            enabledTools: $enabledTools
            contextKeys: $contextKeys
          }
        ) {
          tokensInContext
          tokensInSystemPrompt
          tokensInMessage
        }
      }
    `;

    const result = await this.urql
      .query(query, {
        conversationId,
        enabledTools,
        message,
        contextKeys: this.contextKeysAsArray(contextKeys),
      })
      .toPromise();
    return this.handleError(result).data.countTokens;
  }

  async getChatConversationTitles() {
    const query = gql`
      query ChatConversationTitlesQuery {
        chatConversationTitles {
          id
          chatSpace
          conversationId
          title
        }
      }
    `;

    const result = await this.urql.query(query, {}).toPromise();
    return this.handleError(result).data.chatConversationTitles;
  }

  async getChatConversation(conversationId: string) {
    const query = gql`
      query ChatConversationQuery($conversationId: String!) {
        chatConversation(id: $conversationId) {
          id
          messages {
            role
            content
          }
          selectedModel {
            llmProviderId
            modelConfigurationId
            modelId
          }
        }
      }
    `;

    const result = await this.urql.query(query, { conversationId }).toPromise();
    return this.handleError(result).data.chatConversation;
  }

  async getAIFabricModels(): Promise<AvailableLLM[]> {
    const query = gql`
      query AvailableLLMsQuery {
        availableLLMs {
          llmProviderId
          modelConfigurationId
          displayName
          modelId
        }
      }
    `;

    const result = await this.urql.query(query, {}).toPromise();
    return this.handleError(result).data.availableLLMs;
  }

  async getDefaultPrompts(): Promise<PromptOverride> {
    const query = gql`
      query DefaultPromptsQuery {
        defaultPrompts {
          name
          prompts {
            prompt
            promptType
          }
          chatPrompts {
            chatPrompt
            chatType
          }
        }
      }
    `;

    const result = await this.urql.query(query, {}).toPromise();
    return this.handleError(result).data.defaultPrompts;
  }

  async getPromptOverrides(_: string): Promise<PromptOverride[]> {
    const query = gql`
      query PromptOverridesQuery {
        promptOverrides {
          id
          platformId
          name
          prompts {
            prompt
            promptType
            modelConfigurationId
          }
          chatPrompts {
            chatPrompt
            chatType
          }
          isPrivate
          createdByUserId
        }
      }
    `;

    const result = await this.urql.query(query, {}).toPromise();
    return this.handleError(result).data.promptOverrides;
  }

  async listPromptOverrides(): Promise<PromptOverride[]> {
    const query = gql`
      query ListPromptOverridesQuery {
        promptOverrides {
          id
          name
        }
      }
    `;

    const result = await this.urqlClient.query(query, {}).toPromise();
    return this.handleError(result).data.promptOverrides;
  }

  async createPromptOverride(promptOverride: CreatePromptOverrideInput): Promise<string> {
    const mutation = gql`
      mutation CreatePromptOverrideMutation($promptOverride: CreatePromptOverrideInput!) {
        createPromptOverride(promptOverride: $promptOverride)
      }
    `;

    const result = await this.urql.mutation(mutation, { promptOverride }).toPromise();
    return this.handleError(result).data.createPromptOverride;
  }

  async updatePromptOverride(promptOverride: UpdatePromptOverrideInput): Promise<boolean> {
    const mutation = gql`
      mutation UpdatePromptOverrideMutation($promptOverride: UpdatePromptOverrideInput!) {
        updatePromptOverride(promptOverride: $promptOverride)
      }
    `;

    const result = await this.urql.mutation(mutation, { promptOverride }).toPromise();
    return this.handleError(result).data.updatePromptOverride;
  }

  async removePromptOverride(id: string): Promise<boolean> {
    const mutation = gql`
      mutation RemovePromptOverrideMutation($id: String!) {
        removePromptOverride(id: $id)
      }
    `;

    const result = await this.urql.mutation(mutation, { id }).toPromise();
    return this.handleError(result).data.removePromptOverride;
  }

  async setModel(conversationId: string, availableLLM: AvailableLLM) {
    const mutation = gql`
      mutation SetModelQuery($conversationId: String!, $model: AvailableLLMInput!) {
        setModel(model: $model, conversationId: $conversationId)
      }
    `;

    const model = {
      ...availableLLM,
      displayName: undefined,
    };

    const result = await this.urql.mutation(mutation, { conversationId, model }).toPromise();
    return this.handleError(result).data.setModel;
  }

  async createChatConversation(chatSpace: string, chatType: ChatType) {
    const mutation = gql`
      mutation CreateChatConversationMutation($chatSpace: String!, $chatType: String!) {
        createChatConversation(chatConversation: { chatSpace: $chatSpace, chatType: $chatType })
      }
    `;

    const result = await this.urql.mutation(mutation, { chatSpace, chatType }).toPromise();
    return this.handleError(result).data.createChatConversation;
  }

  async removeChatConversation(id: string) {
    const mutation = gql`
      mutation RemoveChatConversationMutation($id: String!) {
        removeChatConversation(id: $id)
      }
    `;

    const result = await this.urql.mutation(mutation, { id }).toPromise();
    return this.handleError(result).data.removeChatConversation;
  }

  async editMessage(
    conversationId: string,
    index: number,
    newMessage: string,
    removeNewer: boolean = false
  ) {
    const mutation = gql`
      mutation EditMessageMutation(
        $conversationId: String!
        $index: Float!
        $newMessage: String!
        $removeNewer: Boolean!
      ) {
        editMessage(
          conversationId: $conversationId
          index: $index
          newMessage: $newMessage
          removeNewer: $removeNewer
        )
      }
    `;

    const result = await this.urql
      .mutation(mutation, { conversationId, index, newMessage, removeNewer })
      .toPromise();
    return this.handleError(result).data.editMessage;
  }

  async removeMessage(conversationId: string, index: number) {
    const mutation = gql`
      mutation RemoveMessageMutation($conversationId: String!, $index: Float!) {
        removeMessage(conversationId: $conversationId, index: $index)
      }
    `;

    const result = await this.urql.mutation(mutation, { conversationId, index }).toPromise();
    return this.handleError(result).data.removeMessage;
  }

  async setToolResult(id: string, result: string) {
    const mutation = gql`
      mutation SetToolCallResultMutation($id: String!, $result: String!) {
        setToolCallResult(id: $id, result: $result)
      }
    `;

    const r = await this.urql.mutation(mutation, { id, result }).toPromise();
    return this.handleError(r).data.setToolCallResult;
  }

  private handleError(result: any) {
    if (result.error) {
      throw new Error(result.error);
    }
    return result;
  }

  private contextKeysAsArray(contextKeys: Record<string, string>) {
    return Object.keys(contextKeys)
      .map((key) => ({ key, value: contextKeys[key] }))
      .filter((it) => it.value);
  }
}
