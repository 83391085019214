<div
  (mouseenter)="hoverOpen()"
  (mouseleave)="closeWithDelay()"
  (click)="clickOpen()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  data-testid="popover-trigger"
  class="popover-label"
>
  {{ label }}
  <ng-content select="[label]"></ng-content>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  (overlayOutsideClick)="close(true)"
>
  <div
    class="popover-content"
    (mouseenter)="stayOpen = true"
    (mouseleave)="close()"
    data-testid="popover-content"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
