@if (openConnections?.size) {
  <div class="guacamole-board" data-testid="guacamole-board">
    <div>
      @if (!isCollapsed) {
        <button class="collapse-button" (click)="isCollapsed = true" mat-icon-button>
          <i class="fa fa-chevron-right collapse-icon"></i>
        </button>
      } @else {
        <button type="button" class="collapse-button" (click)="isCollapsed = false" mat-icon-button>
          <i class="fa fa-chevron-left collapse-icon"></i>
        </button>
      }
    </div>
    <div class="container" [@boardExpand]="isCollapsed ? 'collapsed' : 'expanded'">
      @for (connection of openConnections | keyvalue: disableSort; track connection) {
        <mat-card class="guacamole-console-mini" (click)="openConsole(connection.value)">
          <button
            class="close-button"
            (click)="$event.stopPropagation(); close(connection.key)"
            mat-icon-button
          >
            <i class="fa fa-times-circle close-icon"></i>
          </button>
          <img class="thumb" [src]="connection.value.thumb" alt="" />
          <div class="connection-name">
            <i class="fa fa-terminal"></i>
            <span>{{ connection.key }}</span>
          </div>
        </mat-card>
      }
    </div>
  </div>
}
