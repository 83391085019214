import { AsyncSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import CybexerDarkTheme from '../components/monaco-editor/themes/cybexer-dark-theme.json';
import CybexerLightTheme from '../components/monaco-editor/themes/cybexer-light-theme.json';
import {
  baseJinja2Rules,
  copyMonarchLanguage,
  jsonJinja2Rules,
  plainJinja2Rules,
} from '../util/jinja2-utils';

declare const monaco: any;

@Injectable({ providedIn: 'root' })
export class MonacoCommonsEditorService {
  private static isMonacoLoading = false;
  private static isMonacoLoaded = false;
  private static afterScriptLoadListeners = [];

  private afterScriptLoad$: AsyncSubject<boolean> = new AsyncSubject<boolean>();
  private isLightThemeObservableSingleton?: Observable<boolean>;
  private isLightTheme: boolean = false;
  private jsonSchema?: string;
  private base = 'assets/monaco/min/vs';
  private readonly jinja2EmbeddedLanguages = new Set<string>();

  readonly CYBEXER_DARK_THEME = 'cybexerDarkTheme';
  readonly CYBEXER_LIGHT_THEME = 'cybexerLightTheme';

  constructor() {
    this.ensureMonacoScriptLoaded();
  }

  setJsonDefaultSchema(jsonSchema) {
    // Json schema feature currently supports only one writable json language editor at a time.
    // [readOnly]="false", language="json" editors will race to set their preferred schema as global schema.
    // When more are needed, have to implement schema selection according to the monaco editor conventions.

    this.jsonSchema = jsonSchema;
    if (MonacoCommonsEditorService.isMonacoLoaded && jsonSchema !== undefined) {
      MonacoCommonsEditorService.getMonacoHandle().languages.json.jsonDefaults.setDiagnosticsOptions(
        {
          validate: true,
          schemaValidation: 'error',
          schemas: [jsonSchema],
          enableSchemaRequest: true,
        }
      );
    }
  }

  private registerJinja2JsonLanguage() {
    // For now, we have to choose between jinja2+json syntax highlighting (language=jinja2.json) and json validation (language=json).
    // Monaco editor does not allow custom syntax highlighting for existing json language;
    // nor using json validators and suggestion providers for jinja2.json custom language.
    // For both to work, might be possible to reimplement json schema validation and other features from scratch for jinja2.json language.

    const langId = 'jinja2.json';
    const monaco = MonacoCommonsEditorService.getMonacoHandle();
    if (monaco) {
      monaco.languages.register({ id: langId });
      monaco.languages.setMonarchTokensProvider(langId, jsonJinja2Rules);
    }
  }

  private registerJinja2EmbeddedLanguage(language) {
    if (language === 'json') {
      this.registerJinja2JsonLanguage();
      return;
    }

    const embeddedJinja2Rules = copyMonarchLanguage(baseJinja2Rules);
    embeddedJinja2Rules.tokenizer.root.push([
      /^[\s\S]/,
      {
        token: 'jinja2.head',
        goBack: 1,
        next: '@parent',
        nextEmbedded: language,
      },
    ]);

    embeddedJinja2Rules.tokenizer.parent.forEach((rule) => {
      rule[1]['nextEmbedded'] = '@pop';
    });
    embeddedJinja2Rules.tokenizer.jinja2_block[0][1]['nextEmbedded'] = language;
    embeddedJinja2Rules.tokenizer.jinja2_variable[0][1]['nextEmbedded'] = language;
    embeddedJinja2Rules.tokenizer.jinja2_comment[0][1]['nextEmbedded'] = language;

    const langId = 'jinja2.' + language;
    monaco.languages.register({ id: langId });
    monaco.languages.setMonarchTokensProvider(langId, embeddedJinja2Rules);
  }

  private registerBaseJinja2Language() {
    const monaco = MonacoCommonsEditorService.getMonacoHandle();

    monaco.languages.register({ id: 'jinja2' });
    monaco.languages.setMonarchTokensProvider('jinja2', plainJinja2Rules);

    // just create a json model to warm up monaco internal json highlighting for our jinja2.json language
    MonacoCommonsEditorService.getMonacoHandle().editor.createModel('{}', 'json');
  }

  getScriptLoadSubject(): AsyncSubject<boolean> {
    return this.afterScriptLoad$;
  }

  setIsLightTheme(isLightTheme: boolean) {
    this.isLightTheme = isLightTheme;
    if (MonacoCommonsEditorService.isMonacoLoaded) {
      MonacoCommonsEditorService.getMonacoHandle().editor.setTheme(
        isLightTheme ? this.CYBEXER_LIGHT_THEME : this.CYBEXER_DARK_THEME
      );
    }
  }

  setIsLightThemeObservable(isLightThemeObservable: Observable<boolean>) {
    if (this.isLightThemeObservableSingleton === undefined) {
      this.isLightThemeObservableSingleton = isLightThemeObservable;
      this.isLightThemeObservableSingleton.subscribe((isLightTheme) => {
        this.isLightTheme = isLightTheme;
        if (MonacoCommonsEditorService.isMonacoLoaded) this.setIsLightTheme(isLightTheme);
      });
    }
  }

  private static getMonacoHandle() {
    return monaco;
  }

  private ensureMonacoScriptLoaded(): void {
    if (MonacoCommonsEditorService.isMonacoLoaded) {
      this.afterScriptLoad$.next(true);
      this.afterScriptLoad$.complete();
      return;
    }

    MonacoCommonsEditorService.afterScriptLoadListeners.push(this.afterScriptLoad$);
    if (MonacoCommonsEditorService.isMonacoLoading) {
      return;
    }

    MonacoCommonsEditorService.isMonacoLoading = true;

    const onGotAmdLoader: any = () => {
      (<any>window).require.config({
        paths: { vs: `${this.base || 'assets/vs'}` },
      });
      (<any>window).require(['vs/editor/editor.main'], () => {
        MonacoCommonsEditorService.getMonacoHandle().editor.defineTheme(
          this.CYBEXER_DARK_THEME,
          CybexerDarkTheme
        );
        MonacoCommonsEditorService.getMonacoHandle().editor.defineTheme(
          this.CYBEXER_LIGHT_THEME,
          CybexerLightTheme
        );
        MonacoCommonsEditorService.isMonacoLoaded = true;
        MonacoCommonsEditorService.isMonacoLoading = false;
        this.setIsLightTheme(this.isLightTheme);
        this.registerBaseJinja2Language();
        this.setJsonDefaultSchema(this.jsonSchema);
        MonacoCommonsEditorService.afterScriptLoadListeners.forEach((it) => {
          it.next(true);
          it.complete();
        });
      });
    };

    if (!(<any>window).require) {
      const loaderScript = document.createElement('script');
      loaderScript.type = 'text/javascript';
      loaderScript.src = `${this.base || 'assets/vs'}/loader.js`;
      loaderScript.addEventListener('load', onGotAmdLoader);
      document.body.appendChild(loaderScript);
    } else {
      onGotAmdLoader();
    }
  }

  ensureJinja2EmbeddedLanguageIsRegistered(language: string) {
    if (!MonacoCommonsEditorService.isMonacoLoaded) return;
    if (this.jinja2EmbeddedLanguages.has(language)) return;
    this.jinja2EmbeddedLanguages.add(language);
    this.registerJinja2EmbeddedLanguage(language);
  }
}
