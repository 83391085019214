import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cybexerFilter',
})
export class FilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @param attributes object attributes to match the search text with
   * @returns list of elements that have been filtered
   */
  transform(items: any[], searchText: string, attributes: string[] = []): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    if (attributes.length === 0) {
      return items.filter((it) => it.toLocaleLowerCase().includes(searchText));
    }

    return items.filter((item) =>
      attributes.some((attribute) => {
        let subItems = [item];
        const keys = attribute.split('.');
        while (keys.length) {
          const key = keys.shift();
          const newSubItems = [];
          subItems.forEach((subItem) => {
            if (Array.isArray(subItem[key])) {
              newSubItems.push(...subItem[key]);
            } else if (subItem[key]) {
              newSubItems.push(subItem[key]);
            }
          });
          subItems = newSubItems;
        }
        return subItems.some((value) => value?.toLowerCase()?.includes(searchText));
      })
    );
  }
}
