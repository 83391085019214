// Include most used icons here. Rarer icons don't have to be added.
export enum Icon {
  PLUS_CIRCLE = 'fas fa-plus-circle',
  SEARCH = 'fa fa-search',
  LOGOUT = 'fas fa-sign-out-alt',
  TRASH = 'fas fa-trash',
  LAYER_GROUP = 'fa fa-layer-group',
  HDD = 'fa fa-hdd',
  SERVER = 'fa fa-server',
  MEMORY = 'fa fa-memory',
  NETWORK_WIRED = 'fa fa-network-wired',
}
