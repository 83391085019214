import { BaseModel } from './base.model';
import { ChatType } from '../service/ai-fabric.service';
import { PromptType } from '../components/aifabric-prompt-override-dialog/aifabric-prompt-override-dialog.component';

export class PromptOverride extends BaseModel {
  id: string;
  platformId: string;
  name: string;
  prompts?: PromptOverridePrompt[];
  chatPrompts?: ChatPromptOverride[];
  platformName?: string;
  isPrivate?: boolean;
  createdByUserId?: string;
}

export class UpdatePromptOverrideInput extends BaseModel {
  id: string;
  name?: string;
  prompts?: PromptOverridePrompt[];
  chatPrompts?: ChatPromptOverride[];
  isPrivate?: boolean;
}

export class CreatePromptOverrideInput extends BaseModel {
  name: string;
  prompts?: PromptOverridePrompt[];
  chatPrompts?: ChatPromptOverride[];
  isPrivate?: boolean;
}

export class ChatPromptOverride extends BaseModel {
  chatPrompt: string;
  chatType: ChatType;
}

export class PromptOverridePrompt extends BaseModel {
  prompt: string;
  promptType: PromptType;
  modelConfigurationId: string;
}
