import Guacamole from 'guacamole-common-js';

export function getOptimalResolution(element) {
  const pixelDensity = window.devicePixelRatio || 1;
  const width = element.clientWidth * pixelDensity;
  const height = element.clientHeight * pixelDensity;

  return { width, height };
}

export function getElementResolution(element) {
  const width = element?.clientWidth;
  const height = element?.clientHeight;

  return { width, height };
}

export function calculateScale(elementResolution, resolution) {
  if (!elementResolution || !resolution) return 1;

  return Math.min(
    elementResolution.width / Math.max(resolution.width, elementResolution.width),
    elementResolution.height / Math.max(resolution.height, elementResolution.height)
  );
}

export function getDisplayResolution(display: Guacamole.Display) {
  if (!display) return undefined;

  return {
    width: display.getWidth(),
    height: display.getHeight(),
  };
}

export function calculateHeight(width: number, resolution): number {
  const height = (resolution.height / resolution.width) * width;

  return Math.round(height);
}

export function compressImage(src: string, newX: number = 340, newY: number = 240) {
  return new Promise((response, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const elem = document.createElement('canvas');
      elem.width = newX;
      elem.height = newY;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, newX, newY);
      const data = ctx.canvas.toDataURL();
      response(data);
    };
    img.onerror = (error) => reject(error);
  });
}
