<cybexer-widget-header
  [title]="'Step 1'"
  [inline]="true"
></cybexer-widget-header>
<cybexer-widget-body>
  <mat-card class="full-card separate">
    <mat-card-title>
      Welcome to the
      <div>IT/OT DIGITAL TWIN CONFIGURATOR</div>
    </mat-card-title>
    <mat-divider></mat-divider>
    <div class="disable-uppercase info">
      Use this tool to create a digital twin — a realistic replica of your IT/OT environment — for
      cybersecurity exercises, events, user training or technology testing.
      By defining your objectives and project scale, you’ll receive a solution tailored to simulate
      and support your organization’s technology landscape in a safe, controlled environment.
    </div>
    <mat-divider></mat-divider>

    <div class="choice-cards-title">Choose primary objective</div>
    <div class="choice-cards">
      @for (objective of objectives; track objective.title) {
        <div
          class="choice-card"
          (click)="toggleObjective(objective)"
          [class.selected-card]="objective.title === selectedObjective?.title"
          >
          <div class="icon-wrapper">
            <vlm-icon
              [inputIcon]="objective.icon"
              [mainClass]="'icon'"
            ></vlm-icon>
          </div>
          <div class="title">
            {{ objective.title }}
          </div>

          <div class="description disable-uppercase">
            {{ objective.description }}
          </div>
        </div>
      }
    </div>

    @if (sizes?.length) {
      <div class="choice-cards-title">Choose Size</div>
    }
    <div class="choice-cards">
      @for (
        size of sizes;
        track size;
        let first = $first;
        let last = $last
        ) {
        <div
          class="choice-card"
          (click)="toggleSize(size)"
          [class.selected-card]="size.title === selectedSize?.title"
          >
          <div class="icon-wrapper">
            @if (!first) {
              <vlm-icon
                [inputIcon]="selectedObjective?.sizesIcon ? selectedObjective?.sizesIcon : 'fa fa-users'"
              ></vlm-icon>
            }
            <vlm-icon
              [inputIcon]="selectedObjective?.sizesIcon ? selectedObjective?.sizesIcon : 'fa fa-users'"
              [mainClass]="'icon'"
            ></vlm-icon>
            @if (last) {
              <vlm-icon
                [inputIcon]="selectedObjective?.sizesIcon ? selectedObjective?.sizesIcon : 'fa fa-users'"
              ></vlm-icon>
            }
          </div>
          <div class="title">
            {{ size.title }}
          </div>

          <div class="description disable-uppercase">
            {{ size.description }}
          </div>
        </div>
      }
    </div>

    <div class="actions">
      <button mat-raised-button color="primary" (click)="continue()">
        Continue to step 2/3
      </button>
    </div>
  </mat-card>
</cybexer-widget-body>
