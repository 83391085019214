import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cybexerReplaceUnderScores',
})
export class ReplaceUnderscoresPipe implements PipeTransform {
  transform(value: string) {
    return replaceUnderscores(value);
  }
}

export function replaceUnderscores(value: string): string {
  if (!value) return '';
  return value.replace(new RegExp('_', 'g'), ' ');
}
