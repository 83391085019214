import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cybexer-widget-body',
  templateUrl: './widget-body.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class WidgetBodyComponent {
  @HostBinding('class') class = 'cybexer-widget-body';
}
