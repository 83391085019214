<div class="console-login">
  <mat-card class="console-login-card">
    <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
      <mat-card-content>
        <mat-form-field>
          <mat-label>{{
            'cybexer.guacamole.console.hostname' | translate: { default: 'Hostname' }
          }}</mat-label>
          <input matInput formControlName="hostname" />
          @if (loginForm.controls['hostname'].hasError('required')) {
            <mat-error>
              {{
                'cybexer.guacamole.console.hostnameRequired'
                  | translate: { default: 'Hostname is required' }
              }}
            </mat-error>
          }
        </mat-form-field>
        <div class="connection-params">
          <mat-form-field class="protocol-field">
            <mat-label>{{
              'cybexer.guacamole.console.protocol' | translate: { default: 'protocol' }
            }}</mat-label>
            <mat-select formControlName="protocol" data-testid="protocol-select-dropdown">
              @for (protocol of protocols; track protocol) {
                <mat-option class="target-option" [value]="protocol">
                  {{ protocol }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field class="port-field">
            <mat-label>{{
              'cybexer.guacamole.console.port' | translate: { default: 'Port' }
            }}</mat-label>
            <input matInput formControlName="port" />
          </mat-form-field>
        </div>
        <div class="connection-params">
          <mat-form-field>
            <mat-label>{{
              'cybexer.guacamole.console.username' | translate: { default: 'Username' }
            }}</mat-label>
            <input matInput formControlName="username" />
            @if (loginForm.controls['username'].hasError('required')) {
              <mat-error>
                {{
                  'cybexer.guacamole.console.usernameRequired'
                    | translate: { default: 'Username is required' }
                }}
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              'cybexer.guacamole.console.password' | translate: { default: 'Password' }
            }}</mat-label>
            <input matInput formControlName="password" type="password" />
            @if (loginForm.controls['password'].hasError('required')) {
              <mat-error>
                {{
                  'cybexer.guacamole.console.passwordRequired'
                    | translate: { default: 'Password is required' }
                }}
              </mat-error>
            }
          </mat-form-field>
        </div>
        <mat-card-actions align="end">
          <button mat-raised-button type="submit" [disabled]="loading" color="primary">
            {{ 'cybexer.guacamole.console.connect' | translate: { default: 'Connect' } }}
          </button>
          @if (reconnectTarget) {
            <button mat-button type="button" (click)="reconnect()">
              {{
                'cybexer.guacamole.console.reconnectTo'
                  | translate
                    : { target: reconnectTarget, default: 'Reconnect to ' + reconnectTarget }
              }}
            </button>
          }
        </mat-card-actions>
      </mat-card-content>
      <mat-card-footer>
        @if (loading) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
      </mat-card-footer>
    </form>
  </mat-card>
</div>
