import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cybexer-form-grid',
  templateUrl: './form-grid.component.html',
  styleUrls: ['./form-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormGridComponent {
  @Input() title;
}
