import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[cybexerFileDragDrop]',
})
export class FileDragDropDirective {
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('class.dragover') private isDragOver = false;
  @HostBinding('class.dragleave') private isDragLeave = true;

  constructor() {}

  @HostListener('dragover', ['$event'])
  public onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
    this.isDragLeave = false;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    this.isDragLeave = true;
  }

  @HostListener('drop', ['$event'])
  public async onDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    this.isDragLeave = true;

    const items = event.dataTransfer?.items;
    if (items) {
      const files = await this.collectFilesFromItems(items);
      this.filesChangeEmiter.emit(files);
    }
  }

  private async collectFilesFromItems(items: DataTransferItemList): Promise<File[]> {
    const files: File[] = [];
    const filePromises = Array.from(items).map((item) =>
      this.processEntry(item.webkitGetAsEntry(), files)
    );
    await Promise.all(filePromises);
    return files;
  }

  private processEntry(entry: any, files: File[]): Promise<void> {
    return new Promise((resolve) => {
      if (entry.isFile) {
        entry.file((file: File) => {
          files.push(file);
          resolve();
        });
      } else if (entry.isDirectory) {
        entry.createReader().readEntries((entries: any[]) => {
          const subDirPromises = entries.map((subEntry) => this.processEntry(subEntry, files));
          Promise.all(subDirPromises).then(() => resolve());
        });
      }
    });
  }
}
