import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vlm-conf-segments-dialog',
  templateUrl: './conf-segments-dialog.component.html',
  styleUrls: ['./conf-segments-dialog.component.scss'],
})
export class ConfSegmentsDialogComponent implements OnInit {
  segmentTypes: Type[] = [];
  showAdditionalButtons = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfSegmentsDialogComponent>,
  ) {}

  ngOnInit(): void {
    console.log('types', this.data);
    this.segmentTypes = this.data.segments
      .filter((segment) => !!segment.type)
      .map((segment) => {
        return {
          name: segment.type,
          selected: segment.selected,
          custom: false,
        };
      });
  }

  saveAndClose(): void {
    this.dialogRef.close(
      this.segmentTypes
        .filter((t) => t.name !== '' && t.selected)
        .map((t) => ({
          name: t.name,
          title: t.custom
            ? t.type === 'NETWORK'
              ? 'Network Segment'
              : 'Cloud Hosted Systems'
            : null,
          service: t.custom ? (t.type === 'NETWORK' ? 'other' : 'cloud') : null,
        })),
    );
  }

  toggleType(type: Type) {
    this.segmentTypes.forEach((t) => {
      if (t.name === type.name) {
        t.selected = !t.selected;
      }
    });
  }

  addType(type: string) {
    this.segmentTypes.push({
      name: '',
      type: type,
      selected: true,
      custom: true,
    });
    this.showAdditionalButtons = false;
  }

  removeCustomType(i: number) {
    this.segmentTypes.splice(i, 1);
  }

  toggleButtons(): void {
    this.showAdditionalButtons = !this.showAdditionalButtons;
  }
}

export type Type = {
  name: string;
  type: string;
  selected: boolean;
  custom?: boolean;
};
