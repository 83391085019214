<div class="error-page">
  <div class="glitch" [attr.data-text]="errorCode">{{ errorCode }}</div>
  <div class="glitch glitch-text" [attr.data-text]="'cybexer.error.matrixGlitch' | translate">
    {{ 'cybexer.error.matrixGlitch' | translate }}
  </div>
  <div class="click-container">
    {{ 'cybexer.error.maybe' | translate }}
    <a (click)="navigateToLandingPage()">
      {{ 'cybexer.error.clickingHere' | translate }}
    </a>
    {{ 'cybexer.error.helps' | translate }}
  </div>
  <div class="message">
    {{ 'cybexer.error.wereDoingIt' | translate }}
  </div>
</div>
