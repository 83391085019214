import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActiveConnection } from '../guacamole.model';
import { GuacamoleConsoleService } from '../service/guacamole-console.service';

@UntilDestroy()
@Component({
  selector: 'cybexer-guacamole-board',
  templateUrl: './guacamole-board.component.html',
  styleUrls: ['./guacamole-board.component.scss'],
  animations: [
    trigger('boardExpand', [
      state(
        'expanded',
        style({
          width: '100%',
          opacity: 1,
        })
      ),
      state(
        'collapsed',
        style({
          width: '0',
          opacity: 0,
          visibility: 'hidden',
        })
      ),
      transition('* => collapsed', [animate('0.5s')]),
      transition('* => expanded', [animate('0.5s')]),
    ]),
  ],
})
export class GuacamoleBoardComponent implements OnInit, OnDestroy {
  @Output() openActiveConnection: EventEmitter<ActiveConnection> = new EventEmitter();

  openConnections: Map<string, ActiveConnection> = new Map();
  isCollapsed: boolean = false;

  constructor(private guacamoleService: GuacamoleConsoleService) {}

  ngOnInit(): void {
    this.guacamoleService.activeGuacamoleConnection$
      .pipe(untilDestroyed(this))
      .subscribe((connection: ActiveConnection) => {
        if (connection) {
          this.openConnections.set(`${connection.name} - ${connection.protocol}`, connection);
        }
      });
  }

  openConsole(connection: ActiveConnection) {
    this.openActiveConnection.emit(connection);
  }

  close(key: string) {
    this.openConnections.delete(key);
  }

  disableSort(): number {
    return 0;
  }

  ngOnDestroy(): void {
    this.openConnections.clear();
  }
}
