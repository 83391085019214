import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  static TranslateLoaderFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
  }
}
