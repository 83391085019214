@if (backEnabled) {
  <button
    mat-icon-button
    class="cybexer-widget-back"
    (click)="navigateBack()"
    [matTooltip]="'cybexer.widget.back' | translate: { default: 'Back' }"
  >
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
}
@if (title) {
  <div class="cybexer-widget-title">
    {{ title }}
  </div>
}
@if (!inline) {
  <div class="newline"></div>
}

<ng-content></ng-content>
