import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgxCommonsModule } from '@cybexer/ngx-commons';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppErrorHandler } from './app.error-handler';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { Step1Component } from './views/step1/step1.component';
import { Step2Component } from './views/step2/step2.component';
import { Step3Component } from './views/step3/step3.component';
import { MatRipple } from '@angular/material/core';
import { ConfSegmentsDialogComponent } from './views/step2/conf-segments-dialog/conf-segments-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AppService } from './app.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IconComponent } from '@shared/icon/icon.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfiguredRangeComponent } from './views/step2/configured-range/configured-range.component';
import { ServerEditDialogComponent } from './views/step2/server-edit-dialog/server-edit-dialog.component';
import { TitleCasePipe } from '@angular/common';
import {
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
} from '@ngx-translate/core';
import { TranslationService } from '@services/translation.service';

@NgModule({
  declarations: [
    AppComponent,
    BaseLayoutComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    ConfSegmentsDialogComponent,
    ConfiguredRangeComponent,
    IconComponent,
    ServerEditDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatSlideToggleModule,
    FormsModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatListModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatRipple,
    MatDialogModule,
    MatSnackBarModule,
    NgxCommonsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationService.TranslateLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
  ],
  providers: [
    AppService,
    TitleCasePipe,
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    TranslatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
