import { Component, Input } from '@angular/core';

@Component({
  selector: 'cybexer-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() label: string;
  @Input() title: string;
  @Input() description: string;
  @Input() linkUrl: string;
  @Input() linkText: string;
  @Input() disable: boolean = false;

  openLink() {
    if (this.linkUrl) {
      window.open(this.linkUrl, '_blank');
    }
  }
}
