import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { mergeMap } from 'rxjs';
import { NotificationsService } from '../../../service/notifications.service';
import { ConsoleErrorData } from '../../guacamole/guacamole.model';
import { VsphereManagerBaseService } from '../service/vsphere-manager-base.service';
import { TranslateService } from '@ngx-translate/core';

declare var WMKS: any;

@UntilDestroy()
@Component({
  selector: 'cybexer-vsphere-console',
  templateUrl: './vsphere-console.component.html',
  styleUrls: ['./vsphere-console.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'select-overlay-pane' },
    },
  ],
})
export class VsphereConsoleComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('consoleContainer', { static: true }) consoleContainer: ElementRef;
  @ViewChild('canvasOutput', { static: true }) canvasOutput: ElementRef<HTMLCanvasElement>;

  @Input() consoleId: string;
  @Input() consoleContainerId: string;
  @Input() vmId: string;
  @Input() targetName: string;
  @Input() openWebConsole: EventEmitter<boolean>;
  @Input() isNewWindow = false;
  @Output() closeWebConsole: EventEmitter<boolean> = new EventEmitter<boolean>();

  pasteForm: UntypedFormGroup;
  pasteInitialized = false;
  loading = false;
  progress = 0;
  copyResult: string;
  remoteScreenSize: string;
  isResolutionDropdownOpened = false;
  isConsoleOpened = false;
  webConsoleAPI: any;
  targetChanged = false;
  resizeObserver: ResizeObserver;

  resolutions = [
    { width: 800, height: 600 },
    { width: 1024, height: 768 },
    { width: 1280, height: 800 },
    { width: 1280, height: 1024 },
    { width: 1440, height: 900 },
    { width: 1600, height: 900 },
    { width: 1920, height: 1080 },
  ];

  private connectionState = WMKS.CONST.ConnectionState.CONNECTING;
  private windowReference: Window;

  constructor(
    private vsphereManager: VsphereManagerBaseService,
    private notificationsService: NotificationsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.openWebConsole) {
      this.openWebConsole.pipe(untilDestroyed(this)).subscribe((isConsoleOpened) => {
        this.isConsoleOpened = isConsoleOpened;

        setTimeout(() => {
          if (
            !this.webConsoleAPI ||
            this.webConsoleAPI.getConnectionState() !== WMKS.CONST.ConnectionState.CONNECTED
          ) {
            this.initialize();
          } else if (this.targetChanged) {
            this.destroy().then(() => {
              this.initialize();
            });
          }
        });
      });
    }

    this.createPasteForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['vmId']?.previousValue && !!changes['vmId']) {
      this.targetChanged = true;
    }
  }

  private initialize() {
    this.startConsole();
  }

  private startConsole() {
    if (!this.vmId) {
      this.close();
      this.notificationsService.error(
        this.translate.instant('cybexer.vsphere.errorOpeningConsole', {
          default: 'Error while opening console',
        }),
        this.translate.instant('cybexer.vsphere.noConsole', {
          default: 'No console found',
        })
      );
      return;
    }

    this.vsphereManager
      .getConnectionId(this.vmId)
      .pipe(
        mergeMap((connectionId) => this.vsphereManager.getWebConsoleUrl(connectionId)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (url) => {
          this.createWebConsole(url);
        },
        error: (err: unknown) => {
          this.close();
          if (!(err instanceof HttpErrorResponse)) throw err;
          throw new Error(err.statusText);
        },
      });
  }

  private createPasteForm() {
    this.pasteForm = new UntypedFormGroup({
      text: new UntypedFormControl('', Validators.required),
    });
  }

  private createWebConsole(connectionUrl) {
    console.log(
      this.translate.instant('cybexer.vsphere.consoleConnecting', {
        default: 'The console is connecting...',
      })
    );
    this.connectionState = WMKS.CONST.ConnectionState.CONNECTING;
    this.webConsoleAPI = WMKS.createWMKS(this.consoleId, {
      position: WMKS.CONST.Position.LEFT_TOP,
      changeResolution: false,
      fixANSIEquivalentKeys: true,
      sendProperMouseWheelDeltas: true,
      fitToParent: true,
      useVNCHandshake: false,
    });

    this.webConsoleAPI.register(WMKS.CONST.Events.ERROR, this.errorHandler);
    this.webConsoleAPI.register(
      WMKS.CONST.Events.CONNECTION_STATE_CHANGE,
      this.connectionStateChangeHandler
    );
    this.webConsoleAPI.register(
      WMKS.CONST.Events.REMOTE_SCREEN_SIZE_CHANGE,
      this.remoteScreenSizeChangeHandler
    );

    this.webConsoleAPI.connect(connectionUrl);

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(() => {
        this.rePosition();
      });
    });
    this.resizeObserver.observe(this.consoleContainer.nativeElement);

    document.getElementById('mainCanvas').focus({ preventScroll: true });
  }

  private remoteScreenSizeChangeHandler = () => {
    if (this.isWebConsoleCreated()) {
      const resolution = this.webConsoleAPI.getRemoteScreenSize();
      this.notificationsService.info(
        this.translate.instant('cybexer.vsphere.remoteScreenSize', {
          width: resolution.width,
          height: resolution.height,
          default: `Remote screen size is set to: ${resolution.width}x${resolution.height}`,
        })
      );
    }
  };

  private connectionStateChangeHandler = (event, data) => {
    if (data.state == WMKS.CONST.ConnectionState.CONNECTED) {
      this.connectionState = WMKS.CONST.ConnectionState.CONNECTED;
      const notification = this.translate.instant('cybexer.vsphere.console_connected_msg', {
        default: 'The console has been connected...',
      });
      this.notificationsService.success(notification);
      console.log(notification);
      this.getResolution();
      this.awake();
    }
    if (data.state == WMKS.CONST.ConnectionState.DISCONNECTED) {
      if (this.connectionState === WMKS.CONST.ConnectionState.CONNECTING) {
        this.notificationsService.error(
          this.translate.instant('cybexer.vsphere.connectToVMFailed', {
            default: 'Could not connect to VM. Please, check its state.',
          })
        );
        this.vsphereManager.notifySentry(
          new ConsoleErrorData({
            connectionId: this.vmId,
            connectionName: this.targetName,
            errorMsg: this.translate.instant('cybexer.vsphere.consoleConnectionError', {
              default: 'Console connection problems. Could not connect to VM',
            }),
          })
        );
      }
      if (!!data.reason) {
        console.log(
          this.translate.instant('cybexer.vsphere.disconnectionReason', {
            reason: data.reason,
            default: 'Disconnection reason: ' + data.reason,
          })
        );
      }
      const notification = this.translate.instant('cybexer.vsphere.console_disconnected_msg', {
        default: 'The console has been disconnected...',
      });
      this.notificationsService.info(notification);
      console.log(notification);

      this.destroy().then(() => {
        if (this.isConsoleOpened && this.connectionState === WMKS.CONST.ConnectionState.CONNECTED) {
          this.startConsole();
        } else {
          this.close();
        }
      });
    }
  };

  // Awake remote window using SHIFT key, as currently not supported to send mouse moves manually
  private awake() {
    this.webConsoleAPI.sendKeyCodes([16]); // shift
  }

  private errorHandler = (event, data) => {
    let reason;
    switch (data.errorType) {
      case WMKS.CONST.Events.ERROR.AUTHENTICATION_FAILED:
        reason = this.translate.instant('cybexer.vsphere.authenticationFailed', {
          default: 'Authentication failed.',
        });
        break;
      case WMKS.CONST.Events.ERROR.WEBSOCKET_ERROR:
        reason = this.translate.instant('cybexer.vsphere.webSocketProblem', {
          default: 'Problem with web socket.',
        });
        break;
      case WMKS.CONST.Events.ERROR.PROTOCOL_ERROR:
        reason = this.translate.instant('cybexer.vsphere.protocolProblem', {
          default: 'Problem with protocol.',
        });
        break;
      default:
        reason = this.translate.instant('cybexer.vsphere.unknownError', {
          default: 'Unknown error.',
        });
        break;
    }
    this.notificationsService.error(
      this.translate.instant('cybexer.vsphere.ErrorOccurredWithReason', {
        reason: reason,
        default: 'Error occurred. ' + reason + ' Refresh the page.',
      })
    );
    this.close();
  };

  private isWebConsoleCreated() {
    return this.webConsoleAPI !== null && this.webConsoleAPI !== undefined;
  }

  private rePosition() {
    if (this.isWebConsoleCreated()) {
      this.webConsoleAPI.updateScreen();
    }
  }

  private async destroy() {
    if (this.isWebConsoleCreated()) {
      await this.webConsoleAPI.destroy();

      this.webConsoleAPI.unregister(WMKS.CONST.Events.ERROR, this.errorHandler);
      this.webConsoleAPI.unregister(
        WMKS.CONST.Events.CONNECTION_STATE_CHANGE,
        this.connectionStateChangeHandler
      );
      this.webConsoleAPI.unregister(
        WMKS.CONST.Events.REMOTE_SCREEN_SIZE_CHANGE,
        this.remoteScreenSizeChangeHandler
      );

      this.webConsoleAPI = null;
      this.targetChanged = false;
      console.log(
        this.translate.instant('cybexer.vsphere.consoleDestroyed', {
          default: 'The console has been destroyed...',
        })
      );
    }

    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.consoleContainer.nativeElement);
    }
  }

  close(): void {
    this.closeWebConsole.emit(true);

    if (this.isConnecting()) this.destroy();
    this.isConsoleOpened = false;
    this.targetChanged = false;
  }

  enterFullScreen() {
    if (!this.isWebConsoleCreated()) {
      this.notifyOnConnectionLostError();
      return;
    }
    this.copyResult = '';
    this.pasteInitialized = false;

    const elem = this.consoleContainer.nativeElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  sendCAD() {
    if (!this.isWebConsoleCreated()) {
      this.notifyOnConnectionLostError();
      return;
    }
    this.webConsoleAPI.sendCAD();
  }

  paste() {
    this.copyResult = '';
    this.pasteInitialized = !this.pasteInitialized;

    if (this.pasteInitialized) {
      setTimeout(() => {
        document.getElementById('paste-input').focus({ preventScroll: true });
      }, 0);
    }
  }

  openInNewWindow() {
    const url = this.vsphereManager.createNewWindowUrl(this.vmId, this.targetName);

    this.destroy().then(() => {
      this.close();
    });

    this.windowReference = window.open(
      url,
      '_blank',
      `toolbar=0, menubar=0, location=0, width=900, height=600`
    );
  }

  onPasteSubmit(form: UntypedFormGroup) {
    if (form.valid) {
      if (this.isWebConsoleCreated()) {
        this.webConsoleAPI.sendInputString(form.value.text);
      } else {
        this.notifyOnConnectionLostError();
      }
      this.pasteInitialized = false;
      form.reset();

      document.getElementById('mainCanvas').focus({ preventScroll: true });
    }
  }

  getResolution() {
    if (!this.isWebConsoleCreated()) {
      this.notifyOnConnectionLostError();
      return;
    }
    this.remoteScreenSize = this.webConsoleAPI.getRemoteScreenSize();
  }

  changeResolution(resolution) {
    if (this.isWebConsoleCreated()) {
      this.webConsoleAPI.setOption('changeResolution', true);
      this.webConsoleAPI.setOption('rescale', true);
      this.webConsoleAPI.setRemoteScreenSize(resolution.width, resolution.height);
      this.webConsoleAPI.updateScreen();
    } else {
      this.notifyOnConnectionLostError();
    }

    this.isResolutionDropdownOpened = false;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 && o1.width === o2.width && o1.height === o2.height;
  }

  private notifyOnConnectionLostError() {
    this.notificationsService.error(
      this.translate.instant('cybexer.vsphere.lostVMConnection', {
        default: 'Lost VM connection. Please refresh page.',
      })
    );
    this.vsphereManager.notifySentry(
      new ConsoleErrorData({
        connectionId: this.vmId,
        connectionName: this.targetName,
        errorMsg: this.translate.instant('cybexer.vsphere.consoleConnectionProblems', {
          default: 'Console connection problems. Lost connection',
        }),
      })
    );
    this.close();
  }

  isConnecting() {
    return this.connectionState == WMKS.CONST.ConnectionState.CONNECTING;
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}
