import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cybexer-meta-item',
  templateUrl: './meta-item.component.html',
})
export class MetaItemComponent {
  @HostBinding('class') class = 'cybexer-meta-item';

  @Input()
  icon: string;
}
