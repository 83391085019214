import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfSegmentsDialogComponent } from './conf-segments-dialog/conf-segments-dialog.component';
import { ServerEditDialogComponent } from './server-edit-dialog/server-edit-dialog.component';

@Component({
  selector: 'vlm-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Step2Component implements OnInit {
  segments: Segment[] = [];
  scenariosAndVulnerabilities: Selection[] = [];
  private serverTypes: string[] = [];
  private serviceTypes: string[] = [];

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('segments')) {
      this.segments = JSON.parse(localStorage.getItem('segments'));
      this.scenariosAndVulnerabilities = JSON.parse(
        localStorage.getItem('svs'),
      );
      this.serverTypes = JSON.parse(localStorage.getItem('serverTypes'));
      this.serviceTypes = JSON.parse(localStorage.getItem('serviceTypes'));

      console.log('localStorage template:', this.segments?.length, 'segments');
    } else if (localStorage.getItem('template')) {
      console.log('Template from localstorage');
      const template = JSON.parse(localStorage.getItem('template'));
      this.segments = template.segments;
      this.serverTypes = template.serverTypes;
      this.serviceTypes = template.serviceTypes;

      console.log(this.serverTypes, this.serviceTypes);

      this.setScenariosAndVulnerabilities(template);

      console.log('Template', this.segments?.length, 'segments');

      this.save();
    }
  }

  private setScenariosAndVulnerabilities(template: any) {
    if (template.scenarios) {
      this.scenariosAndVulnerabilities = template.scenarios.map((scenario) => {
        return { name: scenario.name, selected: scenario.selected };
      });
    }
  }

  configureSegments() {
    console.log('Configuring Segments');
    this.dialog
      .open(ConfSegmentsDialogComponent, {
        data: {
          segments: this.segments,
        },
      })
      .afterClosed()
      .subscribe(
        (selections: { name: string; title: string; service: string }[]) => {
          if (selections) {
            this.segments.forEach((segment) => {
              if (segment.type) {
                segment.selected = !!selections.find(
                  (sel) => sel.name === segment.type,
                );
              }
            });

            selections.forEach((selection) => {
              if (!this.segmentTypes.includes(selection.name)) {
                this.segments.push({
                  title: selection.title,
                  type: selection.name,
                  service: selection.service,
                  custom: true,
                  selected: true,
                });
              }
            });

            this.save();
          }
        },
      );
  }

  get segmentTypes(): string[] {
    return this.segments.map((segment) => segment.type);
  }

  addServer(segment: Segment) {
    console.log('Adding server');

    if (!segment.servers) {
      segment.servers = [];
    }

    this.dialog
      .open(ServerEditDialogComponent, {
        data: {
          server: null,
          options: this.serverTypes,
        },
      })
      .afterClosed()
      .subscribe((server) => {
        if (server) {
          segment.servers.push(server);
          this.save();
        }
      });
  }

  addService(server: Server) {
    console.log('Adding service');
    if (!server.services) {
      server.services = [];
    }

    this.dialog
      .open(ServerEditDialogComponent, {
        data: {
          server: null,
          options: this.serviceTypes,
        },
      })
      .afterClosed()
      .subscribe((service) => {
        if (service) {
          server.services.push(service);
          this.save();
        }
      });
  }

  deleteService(server: Server, serviceIndex: number) {
    server.services.splice(serviceIndex, 1);
    this.save();
  }

  deleteServer(segment: Segment, serverIndex: number) {
    segment.servers.splice(serverIndex, 1);
    this.save();
  }

  sortSegments() {
    this.segments.sort((a, b) => {
      if (a.type < b.type) return -1;
      if (a.type > b.type) return 1;

      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;

      return 0;
    });
  }

  continue() {
    this.router.navigateByUrl('step3');
  }

  private save() {
    localStorage.setItem('segments', JSON.stringify(this.segments));
    localStorage.setItem('serverTypes', JSON.stringify(this.serverTypes));
    localStorage.setItem('serviceTypes', JSON.stringify(this.serviceTypes));
    localStorage.setItem(
      'svs',
      JSON.stringify(this.scenariosAndVulnerabilities),
    );
  }

  toggleSv(sv: Selection) {
    sv.selected = !sv.selected;
    this.save();
  }

  editServerOrService(serviceOrService: Server, isServer: boolean = false) {
    this.dialog
      .open(ServerEditDialogComponent, {
        data: {
          server: serviceOrService,
          options: isServer ? this.serverTypes : this.serviceTypes,
        },
      })
      .afterClosed()
      .subscribe((server) => {
        if (server) {
          serviceOrService = server;
          this.save();
        }
      });
  }

  viewRange() {
    this.router.navigate(['/range']);
  }

  get isAnySegmentVisible() {
    return (
      this.segments?.length && this.segments.some((segment) => segment.selected)
    );
  }
}

export type Segment = {
  title: string;
  type?: string;
  service?: string;
  selected?: boolean;
  servers?: Server[];
  custom?: boolean;
  flat?: boolean;
};

export type Server = {
  title?: string;
  type?: string;
  services?: Server[];
};

export type Selection = {
  name: string;
  selected: boolean;
};
