<div class="clipboard-overlay">
  <div class="clipboard-overlay-title">
    {{ 'cybexer.guacamole.clipboard' | translate: { default: 'Clipboard' } }}
  </div>
  <div class="disable-uppercase">
    {{
      'cybexer.guacamole.clipboardNotice'
        | translate
          : {
              default:
                'Text copied/cut will appear here. Changes to the text below will affect the remote clipboard.'
            }
    }}
  </div>
  <div>
    <mat-form-field class="full-width textarea">
      <textarea
        rows="6"
        class="textarea"
        matInput
        matTextareaAutosize
        [(ngModel)]="text"
        (ngModelChange)="onTextChange()"
      >
      </textarea>
    </mat-form-field>
  </div>
</div>
