import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cybexer-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridComponent {
  @Input() itemSize: 'small' | 'medium' | 'form' = 'medium';
}
