import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cybexer-vsphere-console-window',
  templateUrl: './vsphere-console-window.component.html',
  styleUrls: ['./vsphere-console-window.component.scss'],
})
export class VsphereConsoleWindowComponent implements OnInit, AfterViewInit {
  id: string;
  name: string;
  openWebConsole: EventEmitter<boolean> = new EventEmitter();

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['id']) {
        this.id = params['id'];
      }
      if (params['name']) {
        this.name = params['name'];
      }
    });
  }

  ngAfterViewInit(): void {
    this.openWebConsole.emit(true);
  }
}
