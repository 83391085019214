<cybexer-popover>
  <div label class="tag-label">{{ label }}</div>
  <div class="tag-container">
    <div class="title-row">
      @if (title) {
        <div class="title">
          {{ title }}
          @if (title && (linkUrl || linkText)) {
            <span> |</span>
          }
        </div>
      }
      @if (linkUrl || linkText) {
        <a class="disable-uppercase link" (click)="openLink()">
          @if (linkUrl) {
            <i class="fa-solid fa-arrow-up-right-from-square"></i>
          }
          {{ linkText }}
        </a>
      }
    </div>
    @if (description) {
      <div class="disable-uppercase description">
        {{ description.length > 1000 ? (description | slice: 0 : 1000) + '...' : description }}
      </div>
    }
  </div>
</cybexer-popover>
