@if (link) {
  @if (link.uri) {
    <a
      mat-list-item
      routerLink="{{ link.uri }}"
      routerLinkActive="router-link-active"
      class="menu-link-item"
    >
      <i matListItemIcon></i>
      {{ linkName }}
    </a>
  } @else {
    <a mat-list-item href="{{ link.href }}" class="menu-link-item">
      <i matListItemIcon></i>
      {{ linkName }}
    </a>
  }
}
