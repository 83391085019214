<div class="range-container">
  <div class="configured-range-container">
    <div class="range-dialog-content" (scroll)="onScroll()">
      <div class="grid-container">
        <div class="dev">
          @for (segment of segments.dev; track segment; let i = $index) {
            <div class="grid-box dev" id="dev-{{i}}">
              <ng-container
                *ngTemplateOutlet="
                  nodeContent;
                  context: {
                    segment: segment
                  }
                "
              ></ng-container>
            </div>
          }
        </div>

        <div class="cloud">
          @for (segment of segments.cloud; track segment; let i = $index) {
            <div class="grid-box" id="cloud-{{i}}">
              <ng-container
                *ngTemplateOutlet="
                  nodeContent;
                  context: {
                    segment: segment
                  }
                "
              ></ng-container>
            </div>
          }
        </div>

        <cybexer-flex
          direction="vertical"
          verticalAlign="bottom"
          class="scenarios"
        >
          @if (scenariosAndVulnerabilities.length) {
            <div>Scenarios & Vulnerabilities</div>
            @for (sv of scenariosAndVulnerabilities; track sv) {
              <div class="scenario disable-uppercase">
                {{ sv.name }}
              </div>
            }
          }
        </cybexer-flex>

        <div class="nseg">
          @for (segment of segments.nseg; track segment; let i = $index) {
            <div class="grid-box nseg-object" id="nseg-{{i}}">
              <ng-container
                *ngTemplateOutlet="
                  nodeContent;
                  context: {
                    segment: segment
                  }
                "
              ></ng-container>
            </div>
          }
        </div>

        <div class="internet">
          <div class="grid-box container" id="internet">Gamenet Simulated Internet</div>
        </div>

        @for (viewItem of viewItems; track viewItem) {
          <div [ngClass]="viewItem.idPrefix">
            <div class="mid-grid-container">
              @for (item of viewItem.items; track item; let i = $index) {
                <div
                  class="mid-grid-item"
                  [matTooltip]="item"
                >
                  <cybexer-flex
                    direction="vertical"
                    [id]="viewItem.idPrefix + '-' + i">
                    <vlm-icon [inputIcon]="viewItem.icon"></vlm-icon>
                    {{ item }}
                  </cybexer-flex>
                </div>
              }
            </div>
            @if (viewItem.idPrefix === 'mid') {
              <div class="mid-fw-container">
                <div class="mid-grid-item" [matTooltip]="'FW'">
                  <cybexer-flex direction="vertical" id="nseg-fw">
                    <vlm-icon [inputIcon]="'hard drive'"></vlm-icon>
                    FW
                  </cybexer-flex>
                </div>
              </div>
            }
          </div>
        }
        @if (segments.wifi) {
          <div class="wifi">
            <div class="grid-box wifi" id="wifi">
              <ng-container
                *ngTemplateOutlet="
                  nodeContent;
                  context: {
                    segment: segments.wifi
                  }
                "
              ></ng-container>
            </div>
          </div>
        }
      </div>
    </div>

    <div class="button-container">
      <button mat-raised-button color="primary" (click)="closeView()">Close</button>
    </div>
  </div>
</div>

<ng-template #nodeContent let-segment="segment">
  <cybexer-row>
    <div class="title-container">
      <div class="type">
        {{ segment.type }}
      </div>
      <div class="title">
        {{ segment.title }}
      </div>
    </div>
    <cybexer-row class="content-container">
      @if (segment.service === 'other') {
        <div class="server-group">
          @for (service of ['windows', 'linux', 'freebsd', 'plc', 'other']; track service; let i = $index) {
            @if (segment[service].length) {
              @for (server of segment[service]; track server) {
                <cybexer-flex
                  direction="vertical"
                  verticalAlign="center"
                  class="icon"
                  [matTooltip]="getTooltip(server)"
                  matTooltipClass="group-tooltip"
                >
                  <vlm-icon [inputIcon]="service"></vlm-icon>
                  <div class="server-title">{{ server.title }}</div>
                </cybexer-flex>
              }
            }
          }
        </div>
      } @else {
        <div class="server-group">
          @for (server of segment.servers; track server.title) {
            <cybexer-flex
              direction="vertical"
              verticalAlign="center"
              class="icon"
              [matTooltip]="getTooltip(server)"
              matTooltipClass="group-tooltip"
            >
              <vlm-icon [inputIcon]="server.type"></vlm-icon>
              <div class="server-title">
                {{ server.title }}
              </div>
            </cybexer-flex>
          }
        </div>
      }
    </cybexer-row>
  </cybexer-row>
</ng-template>
