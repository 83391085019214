import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  NgZone,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { editor } from 'monaco-editor';
import { BaseEditorComponent } from '../base-editor.component';
import IMarker = editor.IMarker;
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;

@Component({
  selector: 'cybexer-monaco-editor',
  templateUrl: './monaco-commons-editor.component.html',
  styleUrls: ['./monaco-commons-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonacoCommonsEditorComponent),
      multi: true,
    },
  ],
})
export class MonacoCommonsEditorComponent
  extends BaseEditorComponent
  implements ControlValueAccessor
{
  @ViewChild('errorOverlay')
  errorOverlayContentRef: ElementRef;

  readonly ERROR_OVERLAY_BODY_CONTENT = 'error-overlay-body-content';
  readonly CODICON_WIDGET_CLOSE = 'codicon-widget-close';
  readonly editorActions = {
    nextMarker: 'editor.action.marker.next',
    closeMarkerNavigation: 'closeMarkersNavigation',
  };

  value: string = '';

  protected override options: IStandaloneEditorConstructionOptions = {
    lineNumbers: 'on',
    tabSize: 2,
    scrollBeyondLastLine: false,
    automaticLayout: true,
    showFoldingControls: 'always',
    folding: this.folding,
    minimap: { enabled: this.minimap },
    // this enables the possibility to style the context menu
    useShadowDOM: false,
  };

  override editor: IStandaloneCodeEditor;
  private zone: NgZone;

  constructor(
    protected override renderer: Renderer2,
    protected override injector: Injector
  ) {
    super(renderer, injector);
    this.zone = injector.get(NgZone);
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value || '';
    this.setValue();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.editor) {
      this.editor.updateOptions({ readOnly: isDisabled });
    }
  }

  protected initMonaco(): void {
    this.registerLanguages();
    const editorDiv: HTMLDivElement = this.editorContentRef.nativeElement;
    this.options.language = this.language;
    this.options.readOnly = this.readOnly;

    if (!this.editor) {
      this.editor = this.getMonacoHandle().editor.create(editorDiv, this.options);
      this.editor.setModel(this.getMonacoHandle().editor.createModel(this.value, this.language));
      this.setEditorHeight();
      this.editorInitialized.emit(this);
      this.setValueEmitter();
      this.editor.layout();
    }
  }

  private registerLanguages() {
    this.getMonacoHandle().languages.register({ id: 'yaml' });
    this.getMonacoHandle().languages.setLanguageConfiguration('yaml', {
      comments: {
        lineComment: '#',
      },
    });
  }

  private setValueEmitter() {
    if (this.editor) {
      this.disposables.push(
        this.editor.onDidChangeModelContent(() => {
          const value = this.editor.getValue();

          this.zone.run(() => {
            this.onChange(value);
            this.value = value;
          });
        })
      );
    }
  }

  private setValue(): void {
    if (!this.editor || !this.editor.getModel()) {
      return;
    }
    this.editor.getModel().setValue(this.value);
  }

  getMarkersSortedByLineNumberFromEditorModel(): IMarker[] {
    let warnings: IMarker[] = this.getMonacoHandle().editor.getModelMarkers({
      resource: this.editor.getModel().uri,
    });

    warnings.sort((marker1, marker2) => {
      if (marker1.startLineNumber > marker2.startLineNumber) {
        return 1;
      }
      if (marker1.startLineNumber < marker2.startLineNumber) {
        return -1;
      }
      return 0;
    });

    return warnings;
  }

  toggleFirstWarningMarker(warning: IMarker) {
    this.editor.setPosition(
      {
        lineNumber: warning.startLineNumber,
        column: warning.startColumn,
      },
      warning.source
    );

    this.closeActiveMarker();
    this.editor.trigger(this.editor.getModel().id, this.editorActions.nextMarker, null);
  }

  closeActiveMarker() {
    this.editor.trigger(this.editor.getModel().id, this.editorActions.closeMarkerNavigation, null);
  }

  displayValidationErrorMessage(errorMessage: string) {
    let overlayWidget = this.createErrorMessageOverlayWidget(errorMessage);
    this.editor.addOverlayWidget(overlayWidget);
  }

  private createErrorMessageOverlayWidget(errorMessage) {
    return {
      getId: () => {
        return 'error-overlay-widget';
      },
      getDomNode: () => {
        let overlayNode = this.errorOverlayContentRef.nativeElement.cloneNode(true);
        let domNode = this.renderer.createElement('div');
        domNode.style.width = '50%';

        this.setOverLayWidgetErrorMessage(overlayNode, errorMessage);
        this.addOnCloseListenerForOverlayWidget(overlayNode);

        domNode.appendChild(overlayNode);

        return domNode;
      },
      getPosition: () => {
        return { preference: 0 };
      },
    };
  }

  setOverLayWidgetErrorMessage(overlayNode, errorMessage) {
    let overlayContent = overlayNode.getElementsByClassName(this.ERROR_OVERLAY_BODY_CONTENT)[0];
    overlayContent.innerText = errorMessage;
  }

  addOnCloseListenerForOverlayWidget(overlayNode) {
    let overlayCloseButton = overlayNode.getElementsByClassName(this.CODICON_WIDGET_CLOSE)[0];
    overlayCloseButton.addEventListener('click', this.removeErrorOverlayWidget);
  }

  removeErrorOverlayWidget() {
    const overlayWidget = document.querySelector('[widgetid="error-overlay-widget"]');
    if (overlayWidget) {
      overlayWidget.remove();
    }
  }
}
