import { Router } from '@angular/router';
import Guacamole from 'guacamole-common-js';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  ConsoleConnectionParameters,
  ConsoleAuthData,
  GuacamoleTunnelType,
  TunnelParams,
} from '../guacamole.model';

export abstract class GuacamoleManagerBaseService {
  protected constructor(protected router: Router) {}

  abstract getTunnelParams(): Observable<TunnelParams>;

  abstract getConnectionId(consoleAuthData: ConsoleAuthData): Promise<string>;

  abstract createNewWindowUrl(connectionParams: ConsoleConnectionParameters): string;

  abstract notifySentry(error: any): void;

  abstract getAdditionalUrlParams(): URLSearchParams;

  getTunnel(): Observable<Guacamole.Tunnel> {
    return this.getTunnelParams().pipe(
      switchMap((params) =>
        of(
          params.type == GuacamoleTunnelType.HTTP
            ? new Guacamole.HTTPTunnel(params.url, params.crossDomain)
            : new Guacamole.WebSocketTunnel(params.url)
        )
      )
    );
  }
}
