import { BaseModel } from '../../model/base.model';

export class NavigationMenuLink extends BaseModel {
  name: string;
  uri: string;
  href: string;
}

export class NavigationMenuGroup extends BaseModel {
  name: string;
  icon: string;
  links: NavigationMenuLink[];

  constructor(data?) {
    super(data);
  }
}
