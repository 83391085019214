@if (hasConnection) {
  <div class="chat-window">
    <mat-menu #histories class="chat-histories" xPosition="before">
      <mat-list
        class="chatbot-window-chat-histories-list"
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
      >
        @for (chatHistoryTitle of availableChatHistories; track chatHistoryTitle) {
          <mat-list-item (click)="openOldChat(chatHistoryTitle.conversationId)">
            <button
              class="delete-chat small-icon-button"
              mat-icon-button
              (click)="deleteChat(chatHistoryTitle.conversationId); $event.stopPropagation()"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <span>{{ chatHistoryTitle.title }}</span>
          </mat-list-item>
        }
      </mat-list>
    </mat-menu>
    <div class="chat-area">
      <div class="chat-window-toolbar">
        <div class="chat-action-wrapper" [ngClass]="{ 'with-close-btn': !isChatHidden }">
          <button
            class="chatbot-option"
            mat-icon-button
            [disabled]="!availableChatHistories.length"
            [matMenuTriggerFor]="histories"
            #menuTrigger="matMenuTrigger"
            (menuOpened)="menuOpened()"
            (menuClosed)="menuClosed()"
            [matTooltip]="
              'cybexer.aiFabric.chatbot.previousConversations'
                | translate: { default: 'Previous conversations' }
            "
          >
            <mat-icon [ngClass]="{ enabled: isHistoriesMenuOpen }"> history </mat-icon>
          </button>
          @for (toolGroup of chatbotToolGroups; track toolGroup) {
            <button
              class="chatbot-option"
              mat-icon-button
              (click)="toggleToolGroup(toolGroup)"
              [matTooltip]="toolGroup[0].tooltip ? (toolGroup[0].tooltip | translate) : undefined"
            >
              <mat-icon
                [ngClass]="{
                  enabled: toolGroup[0].isEnabled,
                  failed: !isToolGroupUsable(toolGroup)
                }"
                >{{ toolGroup[0].icon }}
              </mat-icon>
            </button>
          }
          @if (availableModels.length) {
            <button
              class="chatbot-option"
              mat-icon-button
              (click)="newChat()"
              [matTooltip]="'cybexer.aiFabric.chatbot.newChat' | translate: { default: 'New chat' }"
            >
              <mat-icon>add</mat-icon>
            </button>
            <mat-select
              class="select-model"
              [(ngModel)]="selectedModel"
              [disabled]="!!streamingMessage"
              (valueChange)="changeModel($event)"
            >
              @for (model of availableModels; track model.modelId) {
                <mat-option [value]="model">
                  {{ model.displayName ? model.displayName : model.modelId }}
                </mat-option>
              }
            </mat-select>
          }
        </div>
        @if (!isChatHidden) {
          <button class="close-btn" mat-icon-button (click)="toggleChat.emit()">
            <mat-icon>close</mat-icon>
          </button>
        }
      </div>
      <div class="history" #history>
        @for (message of messages; track message; let i = $index) {
          <div [ngClass]="message.role" class="message-row">
            <i
              [ngClass]="message.role === CHAT_ROLE_USER ? 'fas fa-user' : 'fas fa-robot'"
              class="role-icon"
            ></i>
            <div class="message-content">
              @if (!streamingMessage) {
                <div class="button-wrapper">
                  <button
                    class="message-action small-icon-button"
                    mat-icon-button
                    (click)="deleteMessage(i)"
                    [matTooltip]="
                      'cybexer.aiFabric.chatbot.deleteMessage'
                        | translate: { default: 'Delete message' }
                    "
                    matTooltipPosition="above"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                  @if (!message.editing) {
                    <button
                      class="message-action small-icon-button"
                      mat-icon-button
                      (click)="startEditMessage(i)"
                      [matTooltip]="
                        'cybexer.aiFabric.chatbot.editMessage'
                          | translate: { default: 'Edit message' }
                      "
                      matTooltipPosition="above"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                  } @else {
                    <button
                      class="message-action small-icon-button"
                      mat-icon-button
                      (click)="finishEditMessage(i)"
                      [matTooltip]="
                        'cybexer.aiFabric.chatbot.save' | translate: { default: 'Save' }
                      "
                      matTooltipPosition="above"
                    >
                      <mat-icon>check</mat-icon>
                    </button>
                    <button
                      class="message-action small-icon-button"
                      mat-icon-button
                      (click)="cancelEditMessage(i)"
                      [matTooltip]="
                        'cybexer.aiFabric.chatbot.cancel' | translate: { default: 'Cancel' }
                      "
                      matTooltipPosition="above"
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                  }
                  <button
                    class="message-action small-icon-button"
                    mat-icon-button
                    (click)="copyMessageToClipboard(message)"
                    [matTooltip]="
                      'cybexer.aiFabric.chatbot.copyToClipboard'
                        | translate: { default: 'Copy to clipboard' }
                    "
                    matTooltipPosition="above"
                  >
                    <mat-icon>file_copy</mat-icon>
                  </button>
                </div>
              }
              @if (message.editing) {
                <div class="message-body edit-message">
                  <textarea
                    [(ngModel)]="editMessage"
                    (keydown.enter)="editMessageEnterPressed(i, $event)"
                    (keydown.escape)="cancelEditMessage(i)"
                    [placeholder]="
                      'cybexer.aiFabric.chatbot.messagePlaceholder'
                        | translate: { default: 'Type your message' }
                    "
                  ></textarea>
                </div>
              } @else {
                <div class="message-body">
                  @for (segment of message.content | cybexerParseCodeBlocks; track segment) {
                    @if (segment.type === 'text') {
                      <p>
                        @for (
                          wordSegment of segment.content | cybexerParseCodeWords;
                          track wordSegment
                        ) {
                          <span [class.code]="wordSegment.type === 'code'">{{
                            wordSegment.content
                          }}</span>
                        }
                      </p>
                    } @else if (segment.type === 'code') {
                      <cybexer-monaco-code-viewer
                        [language]="segment.language || ''"
                        [(ngModel)]="segment.content"
                        [readOnly]="true"
                      ></cybexer-monaco-code-viewer>
                    }
                  }
                </div>
              }
            </div>
            @if (message.errorMessage && message === messages[messages.length - 1]) {
              <div>
                <button mat-icon-button (click)="retryLastMessage()">
                  <mat-icon>refresh</mat-icon>
                </button>
              </div>
            }
          </div>
        }
        @if (
          !streamingMessage &&
          messages.length &&
          messages[messages.length - 1].role === CHAT_ROLE_USER
        ) {
          <div class="regenerate">
            <button mat-icon-button (click)="regenerate()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        }
        @if (streamingMessage) {
          <div class="bot-typing-spinner-area">
            <cybexer-progress-spinner
              [spinnerText]="
                'cybexer.aiFabric.chatbot.typing' | translate: { default: 'Typing...' }
              "
            ></cybexer-progress-spinner>
            <button mat-raised-button (click)="cancelSendMessage()">
              {{ 'cybexer.aiFabric.chatbot.cancel' | translate: { default: 'Cancel' } }}
            </button>
          </div>
        }
      </div>
      <div class="message-row input-row user">
        <i class="fas fa-user role-icon"></i>
        <div class="textarea-container">
          <textarea
            #messageInput
            [(ngModel)]="inputMessage"
            (ngModelChange)="updateTokenUsage()"
            (keydown.enter)="enterPressed($event)"
            [placeholder]="
              'cybexer.aiFabric.chatbot.messagePlaceholder'
                | translate: { default: 'Type your message' }
            "
          ></textarea>
        </div>
      </div>
      <div class="message-row user token-counter-row">
        @if (modelMaxTokens) {
          <span class="token-counter" [ngClass]="isLlmOutOfMemory ? 'red' : null">
            {{
              'cybexer.aiFabric.chatbot.tokensUsed'
                | translate
                  : {
                      tokensUsed: currentInputMessageTokens + contextTokens,
                      maxTokens: modelMaxTokens - reservedTokens,
                      default:
                        currentInputMessageTokens +
                        contextTokens +
                        ' tokens used of ' +
                        (modelMaxTokens - reservedTokens) +
                        ' available tokens'
                    }
            }}
            @if (isLlmOutOfMemory) {
              - {{ llmOutOfMemoryMessage }}
            }
            <cybexer-popover>
              <mat-icon label>info</mat-icon>
              <div class="token-breakdown">
                <div class="title">
                  {{
                    'cybexer.aiFabric.chatbot.tokenBreakdown'
                      | translate: { default: 'Token breakdown' }
                  }}:
                </div>
                {{
                  'cybexer.aiFabric.chatbot.tokensFromCurrentInputMessage'
                    | translate
                      : {
                          tokens: currentInputMessageTokens,
                          default: currentInputMessageTokens + ' from current input message'
                        }
                }}<br />
                {{
                  'cybexer.aiFabric.chatbot.tokensFromHistoryAndFile'
                    | translate
                      : { tokens: contextTokens, default: contextTokens + ' from history & file' }
                }}
              </div>
            </cybexer-popover>
          </span>
        }
      </div>
    </div>
  </div>
}
