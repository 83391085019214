<div (click)="closeSnackbar()" class="notification-content">
  <div>
    <mat-icon>{{ icon }}</mat-icon>
  </div>
  <div>
    <span>{{ data.title | translate }}</span>
    @if (data.content) {
      <span>. {{ data.content | translate }}</span>
    }
  </div>
</div>
