import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getState(): Observable<boolean> {
    return this.loadingState;
  }

  // don't forget to call setLoading(false) onDestroy
  setLoading(state: boolean) {
    this.loadingState.next(state);
  }
}
