import { Component, Input } from '@angular/core';

@Component({
  selector: 'cybexer-progress-spinner',
  templateUrl: 'progress-spinner.component.html',
  styleUrls: ['progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent {
  @Input() spinnerText: string = null;

  constructor() {}
}
