import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsoleConnectionParameters } from '../guacamole.model';

@Component({
  selector: 'cybexer-guacamole-console-window',
  templateUrl: './guacamole-console-window.component.html',
})
export class GuacamoleConsoleWindowComponent implements OnInit, AfterViewInit {
  connectionParameters: ConsoleConnectionParameters;
  isGuacamoleConsoleLoginEnabled: boolean = false;
  openGuacamoleConsole: EventEmitter<boolean> = new EventEmitter();

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['source']) {
        this.connectionParameters = new ConsoleConnectionParameters({
          id: params['id'],
          name: params['name'],
          connectionType: params['connectionType'],
          source: params['source'],
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.openGuacamoleConsole.emit(true);
  }
}
