import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { editor, IDisposable } from 'monaco-editor';
import { takeUntil } from 'rxjs/operators';
import IActionDescriptor = editor.IActionDescriptor;
import IContextKey = editor.IContextKey;
import ContextKeyValue = editor.ContextKeyValue;
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;
import ICodeEditor = editor.ICodeEditor;
import { MonacoCommonsEditorService } from '../../service/monaco-commons-editor.service';

declare const monaco: any;

export type CommonsEditorContextKey = {
  key: string;
  value: Observable<ContextKeyValue>;
  initial: ContextKeyValue;
};

export type CommonsEditorContextAction = IActionDescriptor & { isInitiallyDisabled?: boolean };

@Component({
  template: '',
})
export abstract class BaseEditorComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('editor', { static: true }) editorContentRef: ElementRef;

  @Input() language: string = '';
  @Input() readOnly: boolean = false;
  @Input() minimap: boolean = true;
  @Input() folding: boolean = true;
  @Input() initialWordWrap?: boolean;
  @Input() heightFitContent: boolean = false;
  @Input() height?: string;
  @Input() maxHeight?: string;
  @Input() contextMenuItems: CommonsEditorContextAction[] = [];
  @Input() editorContextKeys: CommonsEditorContextKey[] = [];

  @Output()
  readonly editorInitialized: EventEmitter<BaseEditorComponent> = new EventEmitter();

  destroy$: Subject<void> = new Subject<void>();

  protected internalContextMenuItems: CommonsEditorContextAction[] = [];
  protected contextActions: {
    [id: string]: { isEnabledContextKey: IContextKey };
  } = {};
  protected disposables: IDisposable[] = [];
  protected options: IStandaloneEditorConstructionOptions;
  protected editor: any;
  protected monacoCommonsEditorService: MonacoCommonsEditorService;

  protected constructor(
    protected renderer: Renderer2,
    protected injector: Injector
  ) {
    this.monacoCommonsEditorService = this.injector.get(MonacoCommonsEditorService);
  }

  private setupWordWrapToggleActions() {
    const isWordWrapContextKeyName = 'editorIsWordWrap';
    const isWordWrapContextKey = this.editor.createContextKey(
      isWordWrapContextKeyName,
      this.options.wordWrap != null && this.options.wordWrap !== 'off'
    );

    this.internalContextMenuItems.push({
      id: 'enableWordWrap',
      label: 'Enable word wrap',
      contextMenuGroupId: '9_cutcopypaste',
      precondition: `!${isWordWrapContextKeyName}`,
      run: (editor) => {
        this.options.wordWrap = 'on';
        isWordWrapContextKey.set(true);
        editor.updateOptions({ wordWrap: this.options.wordWrap });
      },
    });

    this.internalContextMenuItems.push({
      id: 'disableWordWrap',
      label: 'Disable word wrap',
      contextMenuGroupId: '9_cutcopypaste',
      precondition: isWordWrapContextKeyName,
      run: (editor) => {
        this.options.wordWrap = 'off';
        isWordWrapContextKey.set(false);
        editor.updateOptions({ wordWrap: this.options.wordWrap });
      },
    });

    this.internalContextMenuItems.push({
      id: 'toggleComments',
      label: 'Toggle comments',
      precondition: 'true',
      contextMenuGroupId: '4_editor_actions',
      run: (editor: ICodeEditor) => {
        editor.getAction('editor.action.commentLine').run();
      },
    });
  }

  protected shouldClearJsonSchemaOnInit() {
    return !this.readOnly && this.language === 'json';
  }

  ngOnInit(): void {
    if (this.language == 'markdown') this.options.wordWrap = 'on';
    if (this.initialWordWrap !== undefined)
      this.options.wordWrap = this.initialWordWrap ? 'on' : 'off';
    if (this.heightFitContent && !this.height)
      this.options.scrollbar = { alwaysConsumeMouseWheel: false };
  }

  ngAfterViewInit(): void {
    this.loadMonaco();
  }

  private loadMonaco(): void {
    this.monacoCommonsEditorService.getScriptLoadSubject().subscribe((isLoaded) => {
      if (isLoaded) {
        this.initMonaco();
        if (this.shouldClearJsonSchemaOnInit()) {
          this.monacoCommonsEditorService.setJsonDefaultSchema({});
        }
        if (this.language?.startsWith('jinja2.'))
          this.monacoCommonsEditorService.ensureJinja2EmbeddedLanguageIsRegistered(
            this.language.substring('jinja2.'.length)
          );
        this.initContextKeys();
        this.initContextActions();
        if (this.heightFitContent && !this.height && !this.readOnly) {
          this.disposables.push(
            this.editor.onDidChangeModelContent(() => {
              this.setEditorHeight();
            })
          );
        }
      }
    });
  }

  private addAndExpr(andExprToAdd: string, targetExpr: string) {
    // Because monaco ContextKeyExpr does not support recursive expressions, using following hack:
    // X && (A || B || C) <===> X && A || X && B || X && C
    const andExpr = `${andExprToAdd}&&`;
    const pieces = targetExpr.split('||');
    return andExpr + pieces.join(andExpr);
  }

  private initContextKeys() {
    this.editorContextKeys.forEach((it) => {
      const contextKey = this.editor.createContextKey(it.key, it.initial);
      it.value.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        contextKey.set(value);
      });
    });
  }

  private initContextActions() {
    this.internalContextMenuItems = this.contextMenuItems.map((it) => it);
    this.setupWordWrapToggleActions();
    this.internalContextMenuItems.forEach((item) => {
      const contextKeyName = `contextAction_${item.id}_isEnabled`;
      const contextKey = this.editor.createContextKey(contextKeyName, !item.isInitiallyDisabled);
      item.precondition = this.addAndExpr(
        contextKeyName,
        item.precondition ? item.precondition : ''
      );
      this.editor.addAction(item);
      this.contextActions[item.id] = {
        isEnabledContextKey: contextKey,
      };
    });
  }

  protected abstract initMonaco(): void;

  protected setEditorHeight() {
    if (this.heightFitContent) {
      if (this.maxHeight) {
        this.renderer.setStyle(this.editorContentRef.nativeElement, 'max-height', this.maxHeight);
      }
      this.renderer.setStyle(
        this.editorContentRef.nativeElement,
        'height',
        this.height ? this.height : this.editor.getContentHeight() + 15 + 'px'
      );
    } else {
      this.renderer.setStyle(this.editorContentRef.nativeElement, 'height', '100%');
    }
  }

  protected getMonacoHandle() {
    return monaco;
  }

  tryParseJSONObject(jsonString: string) {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {}

    return false;
  }

  enableAction(action: string) {
    this.contextActions[action].isEnabledContextKey.set(true);
  }

  disableAction(action: string) {
    this.contextActions[action].isEnabledContextKey.set(false);
  }

  isActionEnabled(action: string) {
    return this.contextActions[action].isEnabledContextKey.get() === true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.editor) {
      this.editor.dispose();
      this.editor = undefined;
    }
    if (this.disposables.length) {
      this.disposables.forEach((disposable) => disposable.dispose());
      this.disposables = [];
    }
  }
}
