import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Column, ColumnType } from '../../model/column.model';

@Component({
  selector: 'cybexer-simple-table',
  templateUrl: './simple-table.component.html',
})
export class SimpleTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  @Input() isPageable = false;
  @Input() isEditable = false;
  @Input() isRowClickEnabled = false;
  @Input() columns: Column[] = [];
  @Input() actionIcon: string;
  @Input() paginationSizes: number[];
  @Input() defaultPageSize;
  @Input() tooltip: string;
  @Input() customSort: boolean = false;

  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowClick: EventEmitter<any> = new EventEmitter();

  // this property needs to have a setter to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    if (this.isEditable) {
      data = [...data, { edit: true, newRow: true }];
    }
    this.setTableDataSource(data);
  }

  tableDataSource = new MatTableDataSource([]);
  displayedColumns: string[];
  sortParams;

  readonly ColumnType = ColumnType;

  constructor() {}

  ngOnInit(): void {
    if (this.isEditable) {
      this.tableDataSource.data = [...this.tableDataSource.data, { edit: true, newRow: true }];
    }

    this.displayedColumns = this.columns.map((tableColumn: Column) => tableColumn.name);

    // If the user changes the sort order, reset back to the first page.
    if (this.isPageable) {
      this.matSort.sortChange.subscribe(() => (this.matPaginator.pageIndex = 0));
    }
  }

  // we need this in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.matPaginator;
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;

    if (this.sortParams?.direction) {
      this.sortTable(this.sortParams);
    }
  }

  sortTable(sortParameters: Sort) {
    // defining name of data property, to sort by, instead of column name
    let sortProp = this.columns.find((column) => column.name === sortParameters.active)?.dataKey;

    sortParameters.active = sortProp ? sortProp : sortParameters.active;

    this.sortParams = sortParameters;

    if (this.customSort) {
      this.sort.emit(sortParameters);
      return;
    }
    this.sortData(sortParameters);
  }

  emitRowClick(row: any) {
    this.rowClick.emit(row);
  }

  sortData(sortParameters: Sort) {
    // default sorting available for dates and strings; new row always at the bottom
    const keyName = sortParameters.active;
    if (sortParameters.direction === 'asc') {
      this.tableDataSource.data = this.tableDataSource.data.sort((a: any, b: any) => {
        if (a.newRow) {
          return 1;
        }
        return this.compare(a, b, keyName);
      });
    } else if (sortParameters.direction === 'desc') {
      this.tableDataSource.data = this.tableDataSource.data.sort((a: any, b: any) => {
        if (a.newRow) {
          return 1;
        }
        return this.compare(b, a, keyName);
      });
    }
  }

  compare(a: any, b: any, keyName: string): number {
    if (a[keyName] instanceof Date || b[keyName] instanceof Date) {
      return a[keyName]?.getTime() - b[keyName]?.getTime();
    }
    return a[keyName]?.toString().localeCompare(b[keyName]?.toString());
  }
}
