import { Injectable } from '@angular/core';

/**
 * Angular doesn't provide a neat way to set {useCapture: true} for event listeners -
 * this EventManagerPlugin allows it by using the (click.capture) event binding.
 *
 * Useful when it is wanted to stop events from propagating at parent element level
 * instead of bubbling from child.
 */
@Injectable({
  providedIn: 'root',
})
export class UseCapturePlugin {
  supports(eventName: string): boolean {
    return eventName === 'click.capture';
  }

  addEventListener(element: HTMLElement, eventName: string, handler: Function): Function {
    const _handler = handler as EventListenerOrEventListenerObject;

    const [name] = eventName.split('.');
    element.addEventListener(name, _handler, true);
    return () => {
      element.removeEventListener(name, _handler);
    };
  }
}
