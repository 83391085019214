import { Pipe, PipeTransform } from '@angular/core';

export interface MessageSegment {
  type: 'text' | 'code';
  content: string;
  language?: string;
}

/**
 * @ngModule VlmPipesModule
 * @description Parses a string into an array of `MessageSegment`s, where code blocks found between triple
 * backticks (\`\`\`) are placed into separate `code` segments. The code segments can optionally
 * have a language property, which was specified after the opening backticks, e.g. \`\`\`javascript
 *
 * ### Example
 * #### Input:
 *
 * Here is a javascript hello world example:
 *
 * \`\`\`javascript
 *
 * let x = 10;
 *
 * console.log('Hello World!');
 *
 * \`\`\`
 *
 * Have a good day!
 *
 * #### Output:
 *
 * ```javascript
 * [
 *  { type: 'text', content: 'Here is a javascript hello world example:' },
 *  { type: 'code', content: 'let x = 10;\nconsole.log(\'Hello World!\');', language: 'javascript' },
 *  { type: 'text', content: 'Have a good day!' },
 * ]
 * ```
 *
 * ### Example
 * Also accepts code blocks without language specified. If the last code block is not closed with backticks,
 * it will still be considered a valid code block:
 * #### Input:
 * \`\`\`
 *
 * console.log('Hello World!');
 *
 * #### Output:
 *
 * ```javascript
 * [
 *  { type: 'code', content: 'console.log(\'Hello World!\');' },
 * ]
 * ```
 */
@Pipe({ name: 'cybexerParseCodeBlocks' })
export class ParseCodeBlocksPipe implements PipeTransform {
  transform(content: string): MessageSegment[] {
    // If there is an unfinished code block, append '```' to the end of the content.
    if (content.split('```').length % 2 === 0) {
      content += '```';
    }

    const segments: MessageSegment[] = [];
    const regex = /```(.+)?\n(((?!```).|\n)+)```|(((?!```).|\n)+)/g;
    let match;
    while ((match = regex.exec(content)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      if (match[2]) {
        segments.push({ type: 'code', content: match[2].trim(), language: match[1] });
      } else {
        segments.push({ type: 'text', content: match[4].trim() });
      }
    }
    return segments;
  }
}
