import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Guacamole from 'guacamole-common-js';
import { ClipboardService } from '../../../../service/clipboard.service';
import {
  calculateHeight,
  calculateScale,
  getDisplayResolution,
  getElementResolution,
  getOptimalResolution,
} from '../../guacamole-helper';
import { ConsoleConnectionType } from '../../guacamole.model';
import { GuacamoleConsoleService } from '../../service/guacamole-console.service';

@UntilDestroy()
@Component({
  selector: 'cybexer-guacamole-console-display',
  templateUrl: './guacamole-console-display.component.html',
  styleUrls: ['./guacamole-console-display.component.scss'],
})
export class GuacamoleConsoleDisplayComponent implements OnInit {
  @ViewChild('guacamoleConsole') guacamoleConsole: ElementRef;

  @Input() connectionType: ConsoleConnectionType;

  private keyboard: Guacamole.Keyboard;
  private mouse: Guacamole.Mouse;

  private remoteClipboardEventHandler: EventListenerOrEventListenerObject;
  private resizeObserver: ResizeObserver;

  constructor(
    protected guacamoleService: GuacamoleConsoleService,
    private clipboardService: ClipboardService
  ) {}

  ngOnInit(): void {
    this.guacamoleService.clientReady.pipe(untilDestroyed(this)).subscribe(() => {
      this.createDisplay();
      this.installMouse();
      this.installKeyboard();
      this.bindHandlers();
    });

    this.guacamoleService.resize.pipe(untilDestroyed(this)).subscribe((forceResize) => {
      this.resize(forceResize);
    });

    this.guacamoleService.toWideScreen.pipe(untilDestroyed(this)).subscribe((value) => {
      this.toggleDisplayWidth(value);
    });

    this.guacamoleService.closeConsole.pipe(untilDestroyed(this)).subscribe(() => {
      this.uninstallMouse();
      this.uninstallKeyboard();
      this.removeDisplay();
      this.unbindHandlers();
    });
  }

  private getConsoleElement(): HTMLElement {
    return this.guacamoleConsole.nativeElement;
  }

  private focusConsoleElementHandler = () => this.getConsoleElement().focus();

  private createDisplay() {
    const element = this.getConsoleElement();

    if (element.hasChildNodes()) element.removeChild(element.firstChild);
    element.appendChild(this.guacamoleService.getDisplay().getElement());

    this.guacamoleService.getDisplay().onresize = this.displayResizeHandler;
  }

  private removeDisplay(): void {
    if (this.guacamoleService.getDisplay()) this.guacamoleService.getDisplay().onresize = undefined;

    const element = this.getConsoleElement();

    if (element.hasChildNodes() && this.guacamoleService.getDisplay()) {
      element.removeChild(this.guacamoleService.getDisplay().getElement());
    }
    this.restoreDefaultElementStyle(element);
  }

  private restoreDefaultElementStyle(element: HTMLElement): void {
    element.removeAttribute('style');
  }

  private displayResizeHandler = () => this.resize();

  private bindHandlers(): void {
    const element = this.getConsoleElement();
    element.focus();

    element.addEventListener('blur', this.resetKeyboard);
    element.addEventListener('click', this.focusConsoleElementHandler);

    this.bindClipboardEventListeners();
    this.subscribeToConsoleElementResize();
  }

  private unbindHandlers(): void {
    const element = this.getConsoleElement();

    element.removeEventListener('blur', this.resetKeyboard);
    element.removeEventListener('click', this.focusConsoleElementHandler);

    this.unbindClipboardEventListeners();
    this.unsubscribeFromConsoleElementResize();
  }

  private installMouse(): void {
    const element = this.getConsoleElement();

    this.mouse = new Guacamole.Mouse(element);
    this.mouse.onmousedown = this.mouse.onmouseup = this.mouse.onmousemove = this.handleMouseState;
    this.mouse.onmouseout = this.handleMouseCursorVisibility;
  }

  private handleMouseState = (mouseState: Guacamole.Mouse.State) => {
    this.guacamoleService.getDisplay()?.showCursor(true);

    if (this.connectionType == ConsoleConnectionType.VNC) {
      const scale = this.guacamoleService.getDisplay().getScale();
      const scaledMouseState = Object.assign({}, mouseState, {
        x: mouseState.x / scale,
        y: mouseState.y / scale,
      });
      this.guacamoleService.sendMouseState(scaledMouseState);
    } else {
      // default behaviour
      // TODO rethink logic
      this.guacamoleService.sendMouseState(mouseState);
    }

    let canvasElements: HTMLCanvasElement[] = Array.from(
      this.guacamoleConsole.nativeElement.getElementsByTagName('canvas')
    );
    if (canvasElements.length > 1 && this.isCursorElement(canvasElements.pop())) {
      this.guacamoleConsole.nativeElement.style.cursor = 'none';
    }
  };

  private isCursorElement(element: HTMLCanvasElement): boolean {
    return element.nodeName === 'CANVAS' && element.width === 64 && element.height === 64;
  }

  private handleMouseCursorVisibility = () => {
    this.guacamoleService.getDisplay()?.showCursor(false);
  };

  private uninstallMouse(): void {
    if (!this.mouse) return;

    this.mouse.onmousedown =
      this.mouse.onmouseup =
      this.mouse.onmousemove =
      this.mouse.onmouseout =
        undefined;
    this.mouse = undefined;
  }

  private installKeyboard(): void {
    const element = this.getConsoleElement() as HTMLElement;

    this.keyboard = new Guacamole.Keyboard();
    this.keyboard.listenTo(element);

    this.keyboard.onkeydown = (keysym) => this.guacamoleService.sendKeyEvent(1, keysym);
    this.keyboard.onkeyup = (keysym) => this.guacamoleService.sendKeyEvent(0, keysym);
  }

  private resetKeyboard(): void {
    if (this.keyboard) {
      this.keyboard.reset();
    }
  }

  private uninstallKeyboard(): void {
    if (!this.keyboard) return;

    this.keyboard.onkeydown = this.keyboard.onkeyup = undefined;
    this.keyboard = undefined;
  }

  private bindClipboardEventListeners(): void {
    this.clipboardService.hasClipboardPermissions().then((hasPermissions) => {
      if (hasPermissions) {
        this.remoteClipboardEventHandler = () =>
          this.clipboardService.setGuacamoleRemoteClipboard(this.guacamoleService.client);

        window.addEventListener('load', this.remoteClipboardEventHandler, true);
        window.addEventListener('copy', this.remoteClipboardEventHandler);
        window.addEventListener('cut', this.remoteClipboardEventHandler);
        this.getConsoleElement().addEventListener('focus', this.remoteClipboardEventHandler, true);
      }
    });
  }

  private unbindClipboardEventListeners(): void {
    if (this.remoteClipboardEventHandler) {
      window.removeEventListener('load', this.remoteClipboardEventHandler, true);
      window.removeEventListener('copy', this.remoteClipboardEventHandler);
      window.removeEventListener('cut', this.remoteClipboardEventHandler);
      this.getConsoleElement().removeEventListener('focus', this.remoteClipboardEventHandler, true);
    }
  }

  private subscribeToConsoleElementResize() {
    if (this.resizeObserver == undefined) {
      this.resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
    }
    this.resizeObserver.observe(this.getConsoleElement());
  }

  private unsubscribeFromConsoleElementResize() {
    this.resizeObserver?.unobserve(this.getConsoleElement());
  }

  private resize(forceResize: boolean = false): void {
    const element = this.getConsoleElement();
    if (!this.guacamoleService.client || !this.guacamoleService.getDisplay()) return;
    if (!element || element.clientWidth < 1 || element.clientHeight < 1) {
      // skip resize while hiding window
      return;
    }

    let elementRes: { width: number; height: number };
    if (this.connectionType == ConsoleConnectionType.VNC) {
      elementRes = getOptimalResolution(element);
    } else {
      elementRes = getElementResolution(element);
    }

    const displayRes = getDisplayResolution(this.guacamoleService.getDisplay());
    if (
      displayRes?.width !== elementRes.width ||
      displayRes?.height !== elementRes.height ||
      forceResize
    ) {
      this.guacamoleService.client.sendSize(elementRes.width ?? 1024, elementRes.height ?? 744);
    }

    if (this.connectionType == ConsoleConnectionType.VNC) {
      // timeout for display to get the correct size
      setTimeout(() => {
        const scale = calculateScale(
          getElementResolution(element),
          getDisplayResolution(this.guacamoleService.getDisplay())
        );
        this.guacamoleService.getDisplay()?.scale(scale);
      }, 100);
    }
  }

  private toggleDisplayWidth(fullWidth: boolean): void {
    const element = this.getConsoleElement();

    if (fullWidth) {
      const height = calculateHeight(
        element.clientWidth,
        getDisplayResolution(this.guacamoleService.getDisplay())
      );
      element.setAttribute('style', `height:${height}px; overflow:auto;`);
      this.resize();
    } else {
      this.restoreDefaultElementStyle(element);
      this.resize();
    }
  }
}
