import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AppService {
  constructor(private http: HttpClient) {}

  refreshData(): Observable<any> {
    return this.http.get('assets/data.json');
  }

  sendEmail(
    to: string,
    subject: string,
    text: string,
    attachment: string,
    formData,
  ): Observable<any> {
    const apiUrl =
      'https://6uwlbzsnm0.execute-api.eu-central-1.amazonaws.com/sendgrid';
    const body = {
      to,
      subject,
      text,
      attachment: {
        content: attachment as string,
        filename: 'Digital_Twin_Configuration.pdf',
        type: 'application/pdf',
      },
      formData,
    };
    return this.http.post(apiUrl, body);
  }
}
