import {
  AfterViewInit,
  Component,
  effect,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { LoadingService } from '@cybexer/ngx-commons';
import { DOCUMENT } from '@angular/common';
import { Theme } from '@models/shared/theme.model';
import { finalize, first } from 'rxjs/operators';
import packageJson from '../../../../package.json';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { ConfiguredRangeService } from '../../views/step2/configured-range/configured-range.service';

@UntilDestroy()
@Component({
  selector: 'vlm-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit, AfterViewInit {
  private static readonly SMALL_BREAKPOINT = '(max-width: 1020px)';
  private static readonly LARGE_BREAKPOINT = '(max-width: 1500px)';

  @ViewChild('nav')
  sidenav: MatSidenav;
  isSideNavHidden = false;
  isRangeActive = false;

  version: string = '';
  isLightTheme = false;
  title = 'Digital Twin Configurator';
  showRefresh: boolean = false;

  private currentTheme = Theme.DARK;

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private loadingService: LoadingService,
    private observer: BreakpointObserver,
    private router: Router,
    private rangeService: ConfiguredRangeService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    effect(() => {
      this.isRangeActive = this.rangeService.isSpecificComponentActive;
      this.updateResponsiveLayout();
    });
  }

  ngOnInit(): void {
    this.currentTheme =
      (localStorage.getItem('activeTheme') as Theme) || Theme.DARK;
    this.isLightTheme = this.currentTheme === Theme.LIGHT;
    this.renderer.setAttribute(this.document.body, 'class', this.currentTheme);

    const versionStr = packageJson.dependencies['@cybexer/ngx-commons'];
    this.version = versionStr.split('#')[1];

    if (!localStorage.getItem('data')) {
      this.refreshAllData();
    }
  }

  ngAfterViewInit(): void {
    this.updateResponsiveLayout();
  }

  private updateResponsiveLayout(): void {
    let breakpoint = this.isRangeActive
      ? BaseLayoutComponent.LARGE_BREAKPOINT
      : BaseLayoutComponent.SMALL_BREAKPOINT;
    setTimeout(() => {
      this.observer
        .observe([breakpoint])
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          if (this.sidenav) {
            if (res.matches) {
              this.sidenav.mode = 'over';
              this.sidenav.close();
            } else {
              this.sidenav.mode = 'side';
              if (!this.isSideNavHidden) {
                this.sidenav.open();
              }
            }
          }
          breakpoint = this.isRangeActive
            ? BaseLayoutComponent.LARGE_BREAKPOINT
            : BaseLayoutComponent.SMALL_BREAKPOINT;
        });
    });
  }

  toggleNav(): void {
    if (this.sidenav.opened) {
      this.sidenav.close();
    } else {
      this.sidenav.open();
    }
    this.isSideNavHidden = !this.sidenav.opened;
  }

  refreshAllData() {
    this.loadingService.setLoading(true);
    localStorage.clear();
    this.appService
      .refreshData()
      .pipe(
        first(),
        finalize(() => this.loadingService.setLoading(false)),
      )
      .subscribe((data) => {
        localStorage.setItem('data', JSON.stringify(data));
        console.log('Data refreshed');

        this.router.navigateByUrl('/step1').then(() => {
          localStorage.removeItem('formValues');
          location.reload();
        });
      });
  }
}
