import Guacamole from 'guacamole-common-js';
import { BaseModel } from '../../model/base.model';

export class ConsoleAuthData extends BaseModel {
  connectionType: ConsoleConnectionType;
  vmId?: string;
  hostname?: string;
  port?: string;
  username?: string;
  password?: string;
  vcVmId?: string;

  static constructFrom(connectionParameters: ConsoleConnectionParameters): ConsoleAuthData {
    return new ConsoleAuthData({
      vmId: connectionParameters.vmId,
      hostname:
        connectionParameters.source === ConsoleConnectionSource.DIRECT
          ? connectionParameters.name
          : undefined,
      connectionType: connectionParameters.connectionType,
      port: connectionParameters.port,
      username: connectionParameters.username,
      password: connectionParameters.password,
    });
  }
}

export class ConsoleConnectionParameters extends BaseModel {
  id?: string;
  vmId?: string;
  name: string;
  source: ConsoleConnectionSource;
  connectionType: ConsoleConnectionType;
  port?: string;
  username?: string;
  password?: string;
}

export class ActiveConnection extends BaseModel {
  id: string;
  vmId?: string;
  name: string;
  source: ConsoleConnectionSource;
  protocol: ConsoleConnectionType;
  port?: string;
  username?: string;
  password?: string;
  client?: Guacamole.Client;
  tunnel?: Guacamole.Tunnel;
  thumb: string;
}

export class TunnelParams extends BaseModel {
  url: string;
  type: GuacamoleTunnelType;
  crossDomain?: boolean;
}

export class ConsoleErrorData extends BaseModel {
  errorMsg: any;
  connectionId: string;
  connectionName: string;
  connectionType: ConsoleConnectionType;
}

export enum ConsoleConnectionSource {
  VCENTER = 'VCENTER',
  DIRECT = 'DIRECT',
}

export enum ConsoleConnectionType {
  NATIVE = 'NATIVE',
  SSH = 'SSH',
  VNC = 'VNC',
  RDP = 'RDP',
}

export enum GuacamoleTunnelType {
  HTTP = 'HTTP',
  WEBSOCKET = 'WEBSOCKET',
}

export enum TunnelConnectionState {
  CONNECTING,
  OPEN,
  CLOSED,
  UNSTABLE,
}

export enum ClientConnectionState {
  IDLE,
  CONNECTING,
  WAITING,
  CONNECTED,
  DISCONNECTING,
  DISCONNECTED,
}

export enum GuacamoleStatus {
  SUCCESS = 0,
  UNSUPPORTED = 256,
  SERVER_ERROR = 512,
  SERVER_BUSY = 513,
  UPSTREAM_TIMEOUT = 514,
  UPSTREAM_ERROR = 515,
  RESOURCE_NOT_FOUND = 516,
  RESOURCE_CONFLICT = 517,
  RESOURCE_CLOSED = 518,
  UPSTREAM_NOT_FOUND = 519,
  UPSTREAM_UNAVAILABLE = 520,
  SESSION_CONFLICT = 521,
  SESSION_TIMEOUT = 522,
  SESSION_CLOSED = 523,
  CLIENT_BAD_REQUEST = 768,
  CLIENT_UNAUTHORIZED = 769,
  CLIENT_FORBIDDEN = 771,
  CLIENT_TIMEOUT = 776,
  CLIENT_OVERRUN = 781,
  CLIENT_BAD_TYPE = 783,
  CLIENT_TOO_MANY = 797,
}

export namespace GuacamoleStatus {
  export function toString(status): string {
    return status.replace(/_/g, ' ');
  }
}

export enum GuacamoleStatusMessage {
  SUCCESS = 'Success',
  UNSUPPORTED = 'The requested operation is unsupported.',
  SERVER_ERROR = 'The operation could not be performed due to an internal failure.',
  SERVER_BUSY = 'The operation could not be performed as the server is busy.',
  UPSTREAM_TIMEOUT = 'The operation could not be performed because the upstream server is not responding.',
  UPSTREAM_ERROR = 'The operation was unsuccessful due to an error or otherwise unexpected condition of the upstream server.',
  RESOURCE_NOT_FOUND = 'The operation could not be performed as the requested resource does not exist.',
  RESOURCE_CONFLICT = 'The operation could not be performed as the requested resource is already in use.',
  RESOURCE_CLOSED = 'The operation could not be performed as the requested resource is now closed.',
  UPSTREAM_NOT_FOUND = 'The operation could not be performed because the upstream server does not appear to exist.',
  UPSTREAM_UNAVAILABLE = 'The operation could not be performed because the upstream server is not available to service the request.',
  SESSION_CONFLICT = 'The session within the upstream server has ended because it conflicted with another session.',
  SESSION_TIMEOUT = 'The session within the upstream server has ended because it appeared to be inactive.',
  SESSION_CLOSED = 'The session within the upstream server has been forcibly terminated.',
  CLIENT_BAD_REQUEST = 'The operation could not be performed because bad parameters were given.',
  CLIENT_UNAUTHORIZED = 'Permission was denied to perform the operation, as the user is not yet authorized (not yet logged in, for example).',
  CLIENT_FORBIDDEN = 'Permission was denied to perform the operation, and this permission will not be granted even if the user is authorized.',
  CLIENT_TIMEOUT = 'The client took too long to respond.',
  CLIENT_OVERRUN = 'The client sent too much data.',
  CLIENT_BAD_TYPE = 'The client sent data of an unsupported or unexpected type.',
  CLIENT_TOO_MANY = 'The operation failed because the current client is already using too many resources.',
}
