import { BaseModel } from './base.model';

export class Column extends BaseModel {
  name: string;
  translateKey: string;
  isNameHidden = false;
  dataKey?: string;
  position?: 'right' | 'left';
  isSortable?: boolean;
  type?: ColumnType;
  label?: string;
  narrow?: boolean;
  icon?: string;
  isConfirmed: false;
  isRequired = true;
  tooltip?: string;
  tooltipFn?: (data: any) => string;
  isEditableFn?: (data: any) => boolean;
  isVisibleFn?: (data: any) => boolean;
  pipeFn?: (data: any) => string;
  actionFn?: (data: any) => string;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('isNameHidden')) {
      this.isNameHidden = data.isNameHidden;
    }

    if (data.hasOwnProperty('isConfirmed')) {
      this.isConfirmed = data.isConfirmed;
    }

    if (data.hasOwnProperty('isRequired')) {
      this.isRequired = data.isRequired;
    }
  }

  pipeTransform(cellValue: any): string {
    if (this.pipeFn) {
      return this.pipeFn(cellValue);
    }
    return cellValue;
  }
}

export enum ColumnType {
  SCORE = 'SCORE',
  ACTION = 'ACTION',
  ICON = 'ICON',
  TOGGLE = 'TOGGLE',
  CHECKBOX = 'CHECKBOX',
}
