import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IntervalService } from '../../service/interval.service';

@Component({
  selector: 'cybexer-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page-component.scss'],
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  errorCode = '';
  private REFRESH_INTERVAL = 10000;
  private timer: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private intervalService: IntervalService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params && params['code']) {
        this.errorCode = params['code'];
      }
    });
    this.timer = this.intervalService.getIntervalWithDelay(this.REFRESH_INTERVAL).subscribe(() => {
      this.navigateToLandingPage();
    });
  }

  navigateToLandingPage() {
    const redirectUrl = this.activatedRoute.snapshot.data['redirectUrl'];
    if (redirectUrl) {
      this.router.navigate([redirectUrl]);
    }
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
  }
}
