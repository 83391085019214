<div class="guac-console-container splitter" id="guac-console-container" #guacamoleContainer>
  <as-split direction="vertical" unit="pixel" [gutterSize]="1" [disabled]="true">
    <as-split-area [size]="40" [lockSize]="true" [order]="0">
      <div class="disable-uppercase console-header" id="console-header">
        <span>{{ 'cybexer.guacamole.console.console' | translate: { default: 'Console' } }}</span>
        @if (!isNewWindow) {
          <button (click)="openInNewWindow()" mat-button>
            <i class="fas fa-external-link-alt mat-button-fa" aria-hidden="true"></i>
          </button>
        }
        <span class="spacer"></span>
        @if (isHorizontal && connectionParameters?.connectionType === ConsoleConnectionType.VNC) {
          @if (!isFullWidth) {
            <button (click)="toggleDisplayWidth()" mat-button>
              <i class="fas fa-arrows-left-right-to-line mat-button-fa" aria-hidden="true"></i>
              {{ 'cybexer.guacamole.console.wideScreen' | translate: { default: 'Wide screen' } }}
            </button>
          } @else {
            <button (click)="toggleDisplayWidth()" mat-button>
              <i class="fas fa-compress mat-button-fa" aria-hidden="true"></i>
              {{ 'cybexer.guacamole.console.compress' | translate: { default: 'Compress' } }}
            </button>
          }
        }
        <button (click)="clipboard.toggle()" mat-button>
          <i class="far fa-clipboard mat-button-fa" aria-hidden="true"></i>
          {{ 'cybexer.guacamole.console.clipboard' | translate: { default: 'Clipboard' } }}
        </button>
        <button (click)="toggleFullscreen()" mat-button>
          <i class="fas fa-expand mat-button-fa" aria-hidden="true"></i>
          {{ 'cybexer.guacamole.console.fullscreen' | translate: { default: 'Fullscreen' } }}
        </button>
        <cybexer-confirm-action>
          @if (!isNewConsoleLogin && isConsoleLoginEnabled) {
            <button (click)="logout()" mat-button>
              <i class="fas fa-right-from-bracket mat-button-fa" aria-hidden="true"></i>
              {{ 'cybexer.guacamole.console.logout' | translate: { default: 'Logout' } }}
            </button>
          }
        </cybexer-confirm-action>
        @if (!isNewWindow) {
          <button (click)="close()" mat-icon-button>
            <i class="fa fa-times"></i>
          </button>
        }
      </div>
    </as-split-area>
    <as-split-area [order]="1" class="guacamole-console-area">
      <mat-sidenav-container class="sidenav-container" autosize>
        <mat-sidenav mode="over" position="end" opened="false" class="sidenav-overlay">
          <cybexer-clipboard-overlay></cybexer-clipboard-overlay>
        </mat-sidenav>
        <mat-sidenav-content>
          @if (showPermissionWarning) {
            <div class="clipboard-warning">
              <i class="fas fa-exclamation-triangle warning-icon" aria-hidden="true"></i>
              <span class="disable-uppercase">
                {{
                  'cybexer.guacamole.console.notSupportedBrowserAlert'
                    | translate
                      : {
                          default:
                            'The browser does not support Clipboard feature. Use Chrome for a better experience.'
                        }
                }}
              </span>
              <div class="spacer"></div>
              <button (click)="showPermissionWarning = false" mat-icon-button>
                <i class="fa fa-times"></i>
              </button>
            </div>
          }
          @if (isNewConsoleLogin) {
            <cybexer-guacamole-console-login
              class="console-login"
              [hostname]="consoleAuthData?.hostname"
              [protocol]="consoleAuthData?.connectionType"
              [port]="consoleAuthData?.port"
              [username]="consoleAuthData?.username"
              [password]="consoleAuthData?.password"
              [reconnectTarget]="initialConnection?.name"
              (consoleAuthData)="authenticate($event)"
            ></cybexer-guacamole-console-login>
          }
          <cybexer-guacamole-console-display
            #guacamoleConsoleDisplay
            [connectionType]="connectionParameters?.connectionType"
          ></cybexer-guacamole-console-display>
          @if (
            clientConnectionState === ClientConnectionState.CONNECTING ||
            clientConnectionState === ClientConnectionState.WAITING ||
            clientConnectionState === ClientConnectionState.DISCONNECTING
          ) {
            <cybexer-progress-spinner
              class="center spinner-overlay"
              [spinnerText]="ClientConnectionState[clientConnectionState] + '...' | titlecase"
            ></cybexer-progress-spinner>
          }
        </mat-sidenav-content>
      </mat-sidenav-container>
    </as-split-area>
  </as-split>
</div>
