import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfiguredRangeService {
  private specificComponentActive = signal(false);

  get isSpecificComponentActive() {
    return this.specificComponentActive();
  }

  setSpecificComponentActive(isActive: boolean): void {
    this.specificComponentActive.set(isActive);
  }
}
