export const baseJinja2Rules = {
  // Set defaultToken to invalid to see what you do not tokenize yet
  // defaultToken: 'invalid',
  keywords: [
    'and',
    'as',
    'block',
    'endblock',
    'debug',
    'extends',
    'filter',
    'endfilter',
    'for',
    'endfor',
    'if',
    'elif',
    'else',
    'endif',
    'in',
    'not',
    'or',
    'recursive',
    'set',
    'trans',
    'endtrans',
    'with',
    'endwith',
    'autoescape',
    'endautoescape',
    'pluralize',
    'scoped',
    'raw',
    'endraw',
    'include',
    'macro',
    'endmacro',
    'call',
    'endcall',
    'do',
    'is',
    'true',
    'false',
  ],
  builtins: [
    'abs',
    'forceescape',
    'map',
    'select',
    'unique',
    'attr',
    'format',
    'max',
    'selectattr',
    'upper',
    'batch',
    'groupby',
    'min',
    'slice',
    'urlencode',
    'capitalize',
    'indent',
    'pprint',
    'sort',
    'urlize',
    'center',
    'int',
    'random',
    'string',
    'wordcount',
    'default',
    'items',
    'reject',
    'striptags',
    'wordwrap',
    'dictsort',
    'join',
    'rejectattr',
    'sum',
    'xmlattr',
    'escape',
    'last',
    'replace',
    'title',
    'filesizeformat',
    'length',
    'reverse',
    'tojson',
    'first',
    'list',
    'round',
    'trim',
    'float',
    'lower',
    'safe',
    'truncate',
    'namespace',
    'joiner',
    'cycler',
    'dict',
    'lipsum',
    'range',
  ],
  operators: ['+', '-', '*', '/', '//', '**', '%', '==', '!=', '<', '>', '<=', '>=', '=', '|'],
  symbols: /[=><!~?:&|+\-*\/^%]+/,

  // C# style strings
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

  // The main tokenizer for our language
  tokenizer: {
    root: [],
    parent: [
      [
        /\{#/,
        {
          token: 'comment',
          bracket: '@open',
          next: '@jinja2_comment',
        },
      ],
      [
        /\{\{/,
        {
          token: 'delimiter',
          bracket: '@open',
          next: '@jinja2_variable',
        },
      ],
      [
        /\{%/,
        {
          token: 'delimiter',
          next: '@jinja2_block',
          bracket: '@open',
        },
      ],
    ],

    jinja2_comment: [
      [/#}/, { token: 'comment.jinja2', bracket: '@close', next: '@pop' }],
      [/[^#]+/, 'comment.jinja2'],
    ],

    jinja2_variable: [
      [/}}/, { token: 'delimiter.jinja2', bracket: '@close', next: '@pop' }],
      { include: '@jinja2_syntax' },
    ],

    jinja2_block: [
      [/%}/, { token: 'delimiter.jinja2', bracket: '@close', next: '@pop' }],
      { include: '@jinja2_syntax' },
    ],

    jinja2_syntax: [
      // identifiers and keywords
      [
        /[a-z_$][\w$]*/,
        {
          cases: {
            '@keywords': 'keyword.jinja2',
            '@builtins': 'keyword.builtin.jinja2',
            '@default': 'identifier.jinja2',
          },
        },
      ],
      [/[A-Z][\w$]*/, 'type.identifier.jinja2'], // to show class names nicely

      // whitespace
      { include: '@whitespace' },

      // delimiters and operators
      [/[{}()\[\]]/, '@brackets'],
      [/[<>](?!@symbols)/, '@brackets'],
      [/@symbols/, { cases: { '@operators': 'operator', '@default': '' } }],

      // numbers
      [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
      [/0[xX][0-9a-fA-F]+/, 'number.hex'],
      [/\d+/, 'number'],

      // delimiter: after number because of .\d floats
      [/[,.]/, 'delimiter'],

      // strings
      [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-teminated string
      [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],
      [/'([^'\\]|\\.)*$/, 'string.invalid'], // non-teminated string
      [/'/, { token: 'string.quote', bracket: '@open', next: '@string_singlequote' }],
    ],

    whitespace: [[/[ \t\r\n]+/, 'white']],

    string: [
      [/[^\\"]+/, 'string'],
      [/@escapes/, 'string.escape'],
      [/\\./, 'string.escape.invalid'],
      [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }],
    ],

    string_singlequote: [
      [/[^\\']+/, 'string'],
      [/@escapes/, 'string.escape'],
      [/\\./, 'string.escape.invalid'],
      [/'/, { token: 'string.quote', bracket: '@close', next: '@pop' }],
    ],
  },
};
export const jsonJinja2Rules = {
  ...baseJinja2Rules,
  defaultToken: 'invalid',
  tokenizer: {
    ...baseJinja2Rules.tokenizer,
    root: [{ include: '@json_item' }],

    json_item: [
      { include: '@jinja2_start' },
      [/\{/, { token: 'bracket.delimiter.json', next: '@json_object' }],
      [/\[/, { token: 'square.delimiter.json', next: '@json_array' }],
      [/"/, { token: 'string.value.json', next: '@json_value_string' }],
      [/[0-9]+\.[0-9]+/, 'float.number.json'],
      [/[0-9]+/, 'number.json'],
      [/true|false/, 'keyword.json'],
      [/null/, 'keyword.json'],
      { include: '@whitespace' },
    ],

    jinja2_start: [
      [/(\{\{)/, { token: 'delimiter.jinja2', bracket: '@open', next: '@jinja2_variable' }],
      [/(\{%)/, { token: 'delimiter.jinja2', bracket: '@open', next: '@jinja2_block' }],
      [/(\{#)/, { token: 'comment.jinja2', bracket: '@open', next: '@jinja2_comment' }],
    ],

    json_object: [
      { include: '@jinja2_start' },
      [/"/, { token: 'string.key.json', next: '@json_key_string' }],
      [/:/, { token: 'colon.delimiter.json', next: '@json_value' }],
      [/}/, { token: 'bracket.delimiter.json', next: '@pop' }],
      { include: '@whitespace' },
    ],

    json_key_string: [
      { include: '@jinja2_start' },
      [/[^\\"]+/, 'string.key.json'],
      [/\\./, 'string.key.escape.json'],
      [/"/, { token: 'string.key.json', next: '@pop' }],
    ],

    json_value: [
      { include: '@json_item' },
      [/,/, { token: 'comma.delimiter.json', next: '@pop' }],
      [/}/, { token: 'bracket.delimiter.json', next: '@pop', goBack: 1 }],
    ],

    json_value_string: [
      { include: '@jinja2_start' },
      [/[^\\"]+/, 'string.value.json'],
      [/\\./, 'string.value.escape.json'],
      [/"/, { token: 'string.value.json', next: '@pop' }],
    ],

    json_array: [
      { include: '@json_item' },
      [/,/, 'comma.delimiter.json'],
      [/]/, { token: 'square.delimiter.json', next: '@pop' }],
    ],
  },
};
export const plainJinja2Rules = copyMonarchLanguage(baseJinja2Rules);
plainJinja2Rules.tokenizer.root.push({ include: '@parent' });

export function copyMonarchLanguage(language) {
  const copyLanguage = { ...language };
  copyLanguage.tokenizer = { ...language.tokenizer };
  copyLanguage.tokenizer.root = [...language.tokenizer.root];
  return copyLanguage;
}
