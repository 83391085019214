import { Component, Input } from '@angular/core';

@Component({
  selector: 'cybexer-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {
  @Input() label: string;
  @Input() disable: boolean = false;
  @Input() click: boolean = false;
  @Input() delay: number = 300;
  isOpen = false;
  stayOpen = false;

  hoverOpen() {
    if (this.disable || this.click) {
      return;
    }
    this.isOpen = true;
  }

  clickOpen() {
    if (this.disable || !this.click) {
      return;
    }
    this.isOpen = true;
  }

  closeWithDelay() {
    if (this.click) {
      return;
    }

    setTimeout(() => {
      if (!this.stayOpen) {
        this.close();
      }
    }, this.delay);
  }

  close(force?: boolean) {
    if (this.click && !force) {
      return;
    }

    this.stayOpen = false;
    this.isOpen = false;
  }
}
