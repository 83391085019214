import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IntervalService {
  constructor() {}

  getInterval(period: number): Observable<number> {
    return interval(period).pipe(startWith(0));
  }

  getIntervalWithDelay(period: number): Observable<number> {
    return interval(period);
  }
}
