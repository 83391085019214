<div class="sidenav-expansion-panel">
  <mat-expansion-panel>
    <mat-expansion-panel-header expandedHeight="84px" collapsedHeight="84px">
      <div class="icon-container">
        <i matListItemIcon class="fas fa-{{ icon }}" aria-hidden="true"></i>
      </div>
      <ul>
        <li>
          <span class="panel-header-title">{{ name }}</span>
        </li>
        @if (description) {
          <li>
            <div class="panel-header-description">{{ description }}</div>
          </li>
        }
      </ul>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</div>
