@if (!isToolbarHidden) {
  <div class="code-viewer-header">
    <div class="code-viewer-title">
      {{ language }}
    </div>
    <button class="code-viewer-copy-button" (click)="copyCodeToClipboard()">
      <i class="fas fa-copy"></i>
      {{ 'cybexer.monaco.viewer.copyCode' | translate: { default: 'Copy code' } }}
    </button>
  </div>
}
<div #editor class="monaco-editor"></div>
