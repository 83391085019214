<div class="aifabric-prompt-override-dialog">
  <cybexer-flex class="title-row" horizontalAlign="justify" verticalAlign="baseline">
    <span mat-dialog-title>
      {{ 'cybexer.aiFabric.configureAIPrompts' | translate: { default: 'Configure AI prompts' } }}
      @if (!selectedPromptOverride.id) {
        <i
          class="fa fa-asterisk"
          aria-hidden="true"
          [matTooltip]="'cybexer.aiFabric.unsaved' | translate: { default: 'Unsaved' }"
        ></i>
      }
    </span>
    <cybexer-flex [gap]="2">
      <button mat-raised-button [disabled]="isProcessing" (click)="addPromptOverride()">
        <mat-icon>add</mat-icon>
        {{
          'cybexer.aiFabric.addNewPromptOverride'
            | translate: { default: 'Add new prompt override' }
        }}
      </button>
    </cybexer-flex>
  </cybexer-flex>

  <mat-dialog-content>
    @if (form) {
      <form [formGroup]="form">
        @if (platformId) {
          <mat-slide-toggle
            formControlName="isFilterByThisPlatform"
            class="platform-filter"
            (change)="updateVisiblePromptOverrides()"
          >
            {{
              'cybexer.aiFabric.showOnlyPromptOverrides'
                | translate: { default: 'Show only prompt overrides for selected platform' }
            }}
          </mat-slide-toggle>
        }

        <cybexer-flex [gap]="2" horizontalAlign="justify">
          @if (isEditingName) {
            <mat-form-field class="flex-left">
              <mat-label>{{ 'cybexer.aiFabric.name' | translate: { default: 'Name' } }}</mat-label>
              <input
                matInput
                formControlName="name"
                [placeholder]="
                  'cybexer.aiFabric.enterOverrideName'
                    | translate: { default: 'Enter prompt override name' }
                "
              />
            </mat-form-field>
          } @else {
            <mat-form-field class="flex-left">
              <mat-label
                >{{
                  'cybexer.aiFabric.selectPromptOverride'
                    | translate: { default: 'Select prompt override' }
                }}
              </mat-label>
              <mat-select
                class="prompt-override-select"
                formControlName="selectedPromptOverride"
                (valueChange)="patchSelectPromptOverride($event)"
              >
                @for (option of visiblePromptOverrides; track option) {
                  <mat-option [value]="option">
                    @if (option.id === data.currentSystemOverrideId) {
                      <mat-icon
                        [matTooltip]="
                          'cybexer.aiFabric.exerciseOverride'
                            | translate: { default: 'Exercise override' }
                        "
                        >code
                      </mat-icon>
                    }
                    {{ option.name }}
                    @if (option.platformName) {
                      - {{ option.platformName }}
                    }
                    @if (option.id === data.currentPreferenceOverrideId) {
                      ({{ 'cybexer.aiFabric.active' | translate: { default: 'ACTIVE' } }})
                    } @else if (
                      !data.currentPreferenceOverrideId &&
                      option.id === data.currentSystemOverrideId
                    ) {
                      ({{ 'cybexer.aiFabric.active' | translate: { default: 'ACTIVE' } }})
                    }
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          <cybexer-flex [gap]="1" verticalAlign="center">
            @if (!isEditingName) {
              @if (data.onPreferenceOverride && !isProcessing && selectedPromptOverride?.id) {
                <mat-slide-toggle
                  class="preference-override-toggle"
                  formControlName="preferenceOverride"
                  (click)="togglePreferenceOverride()"
                >
                  {{
                    'cybexer.aiFabric.personalOverride'
                      | translate: { default: 'Personal override' }
                  }}
                </mat-slide-toggle>
              }

              <button mat-icon-button (click)="editName()">
                <mat-icon>edit</mat-icon>
              </button>
            }
            <button
              mat-icon-button
              [disabled]="isProcessing || !selectedPromptOverride?.id"
              (click)="removeSelectedPromptOverride()"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </cybexer-flex>
        </cybexer-flex>

        <cybexer-flex [gap]="1" horizontalAlign="justify" class="owner-row">
          <mat-label class="disable-uppercase">{{
            'cybexer.aiFabric.promptOverrideOwner'
              | translate: { ownerName: selectedPromptOverride.createdByUserId || '?' }
          }}</mat-label>

          @if (
            !data.userName ||
            !selectedPromptOverride.createdByUserId ||
            selectedPromptOverride.createdByUserId === data.userName
          ) {
            <mat-slide-toggle class="shared-toggle" formControlName="isShared">
              {{ 'cybexer.shared.shared' | translate }}
            </mat-slide-toggle>
          }
        </cybexer-flex>

        <mat-form-field class="full-width">
          <mat-label
            >{{ 'cybexer.aiFabric.selectPrompt' | translate: { default: 'Select prompt' } }}
          </mat-label>
          <mat-select
            class="prompt-select"
            formControlName="selectedPrompt"
            (valueChange)="this.selectedPrompt = $event"
          >
            @for (option of promptOverridesData; track option) {
              <mat-option [value]="option">
                @if (option.isPromptEnabled) {
                  <mat-icon
                    [matTooltip]="
                      'cybexer.aiFabric.isOverridden' | translate: { default: 'Is overridden' }
                    "
                    >arrow_upward
                  </mat-icon>
                }
                @if (option.modelConfigurationId) {
                  <mat-icon
                    [matTooltip]="
                      'cybexer.aiFabric.usingModel'
                        | translate
                          : {
                              name: option.modelDisplayName,
                              default: 'Using model - ' + option.modelDisplayName
                            }
                    "
                    >android
                  </mat-icon>
                }
                {{
                  option.isChatPrompt
                    ? ('cybexer.aiFabric.chat' | translate: { default: 'Chat' })
                    : ''
                }}{{
                  'cybexer.aiFabric.promptId.' + option.promptId
                    | lowercase
                    | translate: { default: option.promptId | cybexerReplaceUnderScores }
                }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        @if (isProcessing || !promptOverridesData?.length) {
          <cybexer-progress-spinner
            [spinnerText]="'cybexer.aiFabric.loading' | translate: { default: 'Loading...' }"
          ></cybexer-progress-spinner>
        } @else {
          <div formArrayName="promptOverrides">
            @for (
              promptGroup of promptOverridesFormArray.controls;
              track promptGroup;
              let i = $index
            ) {
              @if (promptIds[i] === selectedPrompt?.promptId) {
                <div class="prompt-field" [formGroupName]="i">
                  @if (!promptOverridesData[i].isChatPrompt) {
                    <cybexer-flex horizontalAlign="justify" [gap]="2">
                      <mat-form-field class="flex-left">
                        <mat-label
                          >{{
                            'cybexer.aiFabric.selectModel'
                              | translate: { default: 'Select model for this prompt' }
                          }}
                        </mat-label>
                        <mat-select class="model-select" formControlName="modelConfigurationId">
                          @for (option of availableLLMs; track option) {
                            <mat-option [value]="option.modelConfigurationId">
                              {{ option.displayName || option.modelId }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>

                      <cybexer-flex [gap]="1" verticalAlign="center">
                        @if (getModelConfigurationIdControl(promptGroup).value) {
                          <button
                            mat-icon-button
                            (click)="getModelConfigurationIdControl(promptGroup).setValue(null)"
                          >
                            <mat-icon>refresh</mat-icon>
                          </button>
                        }

                        <button
                          mat-icon-button
                          [matTooltip]="
                            'cybexer.aiFabric.setModelForAll'
                              | translate: { default: 'Set model for all prompts' }
                          "
                          (click)="setAllModels(getModelConfigurationIdControl(promptGroup).value)"
                        >
                          <mat-icon>all_out</mat-icon>
                        </button>
                      </cybexer-flex>
                    </cybexer-flex>
                  }

                  @if (getIsPromptEnabledControl(promptGroup).value) {
                    <div class="row">
                      <button
                        mat-raised-button
                        (click)="resetPrompt(promptGroup, findPromptData(promptIds[i]))"
                      >
                        <mat-icon>refresh</mat-icon>
                        {{
                          'cybexer.aiFabric.resetToDefault'
                            | translate: { default: 'Reset to default' }
                        }}
                      </button>
                    </div>
                  }
                  <cybexer-monaco-editor
                    [language]="'plaintext'"
                    formControlName="prompt"
                    [initialWordWrap]="true"
                    [heightFitContent]="true"
                    [maxHeight]="monacoEditorMaxHeight"
                  ></cybexer-monaco-editor>
                </div>
              }
            }
          </div>
        }
      </form>
    }
  </mat-dialog-content>
  <div class="footer-container">
    <mat-dialog-actions>
      @if (data.isExerciseAdmin) {
        <button
          mat-raised-button
          [disabled]="isProcessing || !selectedPromptOverride?.id"
          (click)="copyExerciseJsonValue()"
          [matTooltip]="
            'cybexer.aiFabric.copyValueForOverride'
              | translate: { default: 'Copy value for override in exercise JSON' }
          "
          matTooltipPosition="above"
        >
          <mat-icon>file_copy</mat-icon>
          {{
            'cybexer.aiFabric.copyJsonEntry' | translate: { default: 'Copy exercise JSON entry' }
          }}
        </button>
      }
      <button
        mat-raised-button
        color="primary"
        [disabled]="isProcessing || !selectedPromptOverride"
        (click)="savePromptOverride()"
      >
        <mat-icon>save</mat-icon>
        {{ 'cybexer.aiFabric.save' | translate: { default: 'Save' } }}
      </button>
      <button mat-button mat-dialog-close [disabled]="isProcessing">
        {{ 'cybexer.aiFabric.close' | translate: { default: 'Close' } }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
