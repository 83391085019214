import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'cybexer-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data,
    private snackBarRef: MatSnackBarRef<NotificationComponent>
  ) {}

  closeSnackbar() {
    this.snackBarRef.dismiss();
  }

  get icon() {
    switch (this.data.type) {
      case 'success':
        return 'check_circle_outline';
      case 'info':
        return 'info';
      case 'warning':
        return 'warning_amber';
      case 'error':
        return 'error_outline';
      default:
        return '';
    }
  }
}
