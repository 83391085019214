<div
  class="console-container splitter"
  id="console-container"
  [ngClass]="{ 'new-window-container': isNewWindow }"
>
  <as-split direction="vertical" unit="pixel" [gutterSize]="1" [disabled]="true">
    <as-split-area [size]="40" [order]="0">
      <div class="disable-uppercase console-header" id="console-header">
        <span>{{ 'cybexer.vsphere.console' | translate: { default: 'Console' } }}</span>
        @if (!isNewWindow) {
          <button
            (click)="openInNewWindow()"
            mat-icon-button
            [matTooltip]="
              'cybexer.vsphere.openInNewWindow' | translate: { default: 'Open in new window' }
            "
          >
            <i class="fas fa-external-link-alt mat-button-fa" aria-hidden="true"></i>
          </button>
        }
        <span class="spacer"></span>
        <button
          class="select-button header-button"
          mat-button
          [matTooltip]="
            'cybexer.vsphere.selectResolution' | translate: { default: 'Select resolution' }
          "
        >
          <i class="fas fa-desktop mat-button-fa" aria-hidden="true"></i>
          <span class="header-button-text">{{
            'cybexer.vsphere.resolution' | translate: { default: 'Resolution' }
          }}</span>
          <mat-select
            [(ngModel)]="remoteScreenSize"
            [compareWith]="compareObjects"
            (selectionChange)="changeResolution($event.value)"
            (opened)="getResolution()"
          >
            @for (resolution of resolutions; track resolution) {
              <mat-option [value]="resolution">
                {{ resolution.width }}x{{ resolution.height }}
              </mat-option>
            }
          </mat-select>
        </button>
        <button
          (click)="paste()"
          class="header-button"
          mat-button
          [matTooltip]="'cybexer.vsphere.pasteText' | translate: { default: 'Paste text' }"
        >
          <i class="fas fa-paste mat-button-fa" aria-hidden="true"></i>
          <span class="header-button-text">{{
            'cybexer.vsphere.paste' | translate: { default: 'Paste' }
          }}</span>
        </button>
        <button
          (click)="enterFullScreen()"
          class="header-button"
          mat-button
          [matTooltip]="
            'cybexer.vsphere.enterFullscreen' | translate: { default: 'Enter fullscreen' }
          "
        >
          <i class="fas fa-expand mat-button-fa" aria-hidden="true"></i>
          <span class="header-button-text">{{
            'cybexer.vsphere.fullscreen' | translate: { default: 'Fullscreen' }
          }}</span>
        </button>
        <cybexer-confirm-action>
          <button
            (click)="sendCAD()"
            class="header-button"
            mat-button
            [matTooltip]="
              'cybexer.vsphere.runCtrlAltDelete'
                | translate: { default: 'Run command Ctrl+Alt+Delete' }
            "
          >
            <i class="fas fa-sync-alt mat-button-fa" aria-hidden="true"></i>
            <span class="header-button-text">{{
              'cybexer.vsphere.ctrlAltDelete' | translate: { default: 'Ctrl+Alt+Delete' }
            }}</span>
          </button>
        </cybexer-confirm-action>
        @if (!isNewWindow) {
          <button (click)="close()" mat-icon-button>
            <i class="fa fa-times"></i>
          </button>
        }
      </div>
    </as-split-area>
    <as-split-area [order]="1" class="console-area">
      <div [id]="consoleContainerId" style="height: 100%; position: relative" #consoleContainer>
        @if (pasteInitialized) {
          <div class="after-header-container">
            <form [formGroup]="pasteForm" (ngSubmit)="onPasteSubmit(pasteForm)" class="paste-form">
              <mat-form-field class="full-width text-input">
                <mat-label>{{
                  'cybexer.vsphere.input' | translate: { default: 'Input' }
                }}</mat-label>
                <input
                  id="paste-input"
                  required
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="text"
                />
              </mat-form-field>
              <button mat-raised-button type="submit" class="submit-btn">
                {{ 'cybexer.vsphere.paste' | translate: { default: 'Paste' } }}
              </button>
            </form>
          </div>
        }
        @if (!!copyResult || loading) {
          <div class="after-header-container">
            @if (loading) {
              <div>
                {{
                  'cybexer.vsphere.processing'
                    | translate: { progress: progress, default: 'Processing ' + progress + '%' }
                }}
                <p class="three-dots"></p>
              </div>
            }
            @if (!!copyResult) {
              <mat-form-field class="full-width text-input">
                <mat-label>{{
                  'cybexer.vsphere.recognizedText' | translate: { default: 'Recognized text' }
                }}</mat-label>
                <input autocomplete="off" type="text" matInput [(ngModel)]="copyResult" />
              </mat-form-field>
              <button (click)="copyResult = ''" class="submit-btn" mat-icon-button>
                <i class="fa fa-times"></i>
              </button>
            }
          </div>
        }
        <div [id]="consoleId" class="console">
          @if (isConnecting()) {
            <cybexer-progress-spinner
              class="center spinner-overlay"
              [spinnerText]="'cybexer.vsphere.connecting' | translate: { default: 'Connecting...' }"
            ></cybexer-progress-spinner>
          }
        </div>
        <canvas id="canvasOutput" #canvasOutput hidden></canvas>
      </div>
    </as-split-area>
  </as-split>
</div>
