<cybexer-widget-header
  [title]="'Step 3'"
  [inline]="true"
></cybexer-widget-header>

<cybexer-widget-body>
  <mat-card class="full-card separate">
    <mat-card-title class="title-line">
      Get a Copy of Your IT/OT Digital Twin Configuration
    </mat-card-title>
    <mat-divider></mat-divider>
    <form [formGroup]="form" class="form">
      <mat-card-content>
        <div class="disable-uppercase description">
          Almost there! Enter your contact details so we can get in touch with a
          Digital Twin recommendation and proposal tailored for you. You can
          also download or email a copy of your configuration for your records.
          We look forward to crafting your Digital Twin solution!
        </div>
        <mat-divider></mat-divider>

        <div class="form-content">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Organization</mat-label>
            <input matInput formControlName="organization" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>E-mail</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Phone</mat-label>
            <input matInput formControlName="phone" />
          </mat-form-field>

          <mat-form-field class="textarea">
            <mat-label>Additional notes or requirements...</mat-label>
            <textarea
              type="text"
              matInput
              formControlName="message"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-card-content>

      <div class="actions">
        <mat-checkbox formControlName="optInCybexerContact">
          <div class="contact-checkbox">
            I would like to be contacted by cybexer team
          </div>
        </mat-checkbox>
        <button
          mat-raised-button
          color="primary"
          (click)="sendConfiguration()"
          [disabled]="processing"
          >
          @if (processing) {
            <i class="fas fa-spinner fa-spin loading-icon"></i>
            } Get My Configuration
          </button>
        </div>
      </form>
    </mat-card>
    <img
      id="cybexerLogo"
      alt="Cybexer"
      src="./assets/img/cybexer-logo.png"
      style="display: none"
      />
  </cybexer-widget-body>

  <div class="hidden-image">
    <div id="segments" class="hidden-image-content">
      @for (segment of segmentsForImage; track segment.title; let k = $index) {
        @if (segment.selected || !segment.type) {
          <div style="padding: 0 1rem 1rem 1rem" class="segment-panel">
            <div style="height:48px; display: flex; align-items: center">
              <div class="header">
                <div>{{ segment.title }}</div>
                <div class="type">{{ segment.type }}</div>
              </div>
            </div>
            <mat-divider></mat-divider>
            @if (segment.servers?.length) {
              <div class="body separate">
                @if (segment.type === "WORKSTATIONS" || segment.flat) {
                  <div class="services">
                    @for (server of segment.servers; track server; let i = $index) {
                      <div class="box">
                        <div class="icon-wrapper">
                          <vlm-icon
                            [inputIcon]="server.type ? server.type : 'ram'"
                          ></vlm-icon>
                        </div>
                        <div class="text">
                          <div class="disable-uppercase">
                            {{ server.title }}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                } @else {
                  <div class="body-title">
                    <div>Servers</div>
                    <div>Services</div>
                  </div>
                  @for (
                    server of segment.servers;
                    track server.title;
                    let i = $index
                    ) {
                    <div class="server-row">
                      <div class="server">
                        <div class="box">
                          <div class="icon-wrapper">
                            <vlm-icon
                              [inputIcon]="server.type ? server.type : 'ram'"
                            ></vlm-icon>
                          </div>
                          <div class="text">
                            <div class="disable-uppercase">
                              {{ server.title }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="dash">
                        <mat-icon>navigate_next</mat-icon>
                      </div>
                      <div class="services">
                        @for (
                          service of server.services;
                          track service;
                          let j = $index
                          ) {
                          <div class="box">
                            <div class="icon-wrapper">
                              <vlm-icon
                                [inputIcon]="service.type ? service.type : 'ram'"
                              ></vlm-icon>
                            </div>
                            <div class="text">
                              <div class="disable-uppercase">
                                {{ service?.title }}
                              </div>
                            </div>
                          </div>
                          } @empty {
                          <cybexer-no-data
                            class="no-services"
                            [message]="'No services defined'"
                          ></cybexer-no-data>
                        }
                      </div>
                    </div>
                  }
                }
              </div>
            }
          </div>
        }
      }
    </div>
  </div>
