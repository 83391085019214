<div class="cybexer-table-wrapper">
  <table
    class="cybexer-table"
    mat-table
    [dataSource]="tableDataSource"
    matSort
    (matSortChange)="sortTable($event)"
  >
    @for (column of columns; track column) {
      <ng-container [matColumnDef]="column.name">
        <!-- if sortable column header -->
        @if (column.isSortable) {
          <th
            data-testid="sortable"
            mat-header-cell
            *matHeaderCellDef
            [mat-sort-header]="column.name"
            [arrowPosition]="column.position === 'right' ? 'before' : 'after'"
            [class.narrow]="column.narrow"
          >
            {{
              !column.isNameHidden
                ? column.translateKey
                  ? (column.translateKey | translate)
                  : column.name
                : ''
            }}
          </th>
        } @else {
          <!-- else not sortable -->
          <th
            data-testid="notSortable"
            mat-header-cell
            *matHeaderCellDef
            [class.text-right]="column.position === 'right'"
            [class.narrow]="column.narrow"
          >
            {{
              !column.isNameHidden
                ? column.translateKey
                  ? (column.translateKey | translate)
                  : column.name
                : ''
            }}
          </th>
        }

        <!-- column data -->
        <td
          mat-cell
          *matCellDef="let element"
          [class.text-right]="column.position === 'right'"
          [class.narrow]="column.narrow"
        >
          @if (!column.type) {
            @if (!element['edit'] || (column.isEditableFn && !column.isEditableFn(element))) {
              <span [matTooltip]="column.tooltipFn ? column.tooltipFn(element) : column.tooltip">
                {{ column.pipeTransform(column.dataKey ? element[column.dataKey] : element) }}
              </span>
            } @else if (element['edit'] && (!column.isEditableFn || column.isEditableFn(element))) {
              <mat-form-field>
                <mat-label>{{
                  column.translateKey ? (column.translateKey | translate) : column.name
                }}</mat-label>
                <input
                  [required]="column.isRequired"
                  matInput
                  [(ngModel)]="element[column.dataKey]"
                />
              </mat-form-field>
            }
          } @else {
            @switch (column.type) {
              @case (ColumnType.SCORE) {
                <div
                  class="cybexer-label"
                  [ngClass]="{
                    'cybexer-label--success': element[column.dataKey] > 0,
                    'cybexer-label--neutral': element[column.dataKey] === 0,
                    'cybexer-label--failed': element[column.dataKey] < 0
                  }"
                >
                  {{ column.pipeTransform(element[column.dataKey]) }}
                </div>
              }
              @case (ColumnType.ICON) {
                @if (!column.isVisibleFn || column.isVisibleFn(element)) {
                  <i
                    [matTooltip]="column.tooltipFn ? column.tooltipFn(element) : column.tooltip"
                    data-testid="iconColumn"
                    class="{{ column.pipeFn ? column.pipeTransform(element) : column.icon }}"
                    aria-hidden="true"
                  ></i>
                }
                {{ column.label | translate }}
              }
              @case (ColumnType.TOGGLE) {
                @if (!column.isVisibleFn || column.isVisibleFn(element)) {
                  <mat-slide-toggle
                    [(ngModel)]="element[column.dataKey]"
                    [disabled]="
                      !element['edit'] || (column.isEditableFn && !column.isEditableFn(element))
                    "
                    data-testid="toggleColumn"
                  >
                    {{ column.label | translate }}
                  </mat-slide-toggle>
                }
              }
              @case (ColumnType.CHECKBOX) {
                @if (!column.isVisibleFn || column.isVisibleFn(element)) {
                  <mat-checkbox
                    [(ngModel)]="element[column.dataKey]"
                    (click)="$event.stopPropagation()"
                    [disabled]="
                      !element['edit'] || (column.isEditableFn && !column.isEditableFn(element))
                    "
                    data-testid="checkboxColumn"
                  >
                    {{ column.label | translate }}
                  </mat-checkbox>
                }
              }
              @case (ColumnType.ACTION) {
                @if (column.isConfirmed) {
                  <cybexer-confirm-action>
                    <ng-container *ngTemplateOutlet="actionButton"></ng-container>
                  </cybexer-confirm-action>
                } @else {
                  <ng-container *ngTemplateOutlet="actionButton"></ng-container>
                }
                <ng-template #actionButton>
                  @if (!column.isVisibleFn || column.isVisibleFn(element)) {
                    <button
                      data-testid="buttonColumn"
                      mat-raised-button
                      [matTooltip]="column.tooltipFn ? column.tooltipFn(element) : column.tooltip"
                      (click)="column.actionFn(element)"
                    >
                      @if (column.icon) {
                        <i class="{{ column.icon }}" aria-hidden="true"></i>
                      }
                      {{
                        column.pipeFn ? column.pipeTransform(element) : (column.label | translate)
                      }}
                    </button>
                  }
                </ng-template>
              }
            }
          }
        </td>
      </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      [class.clickable]="isRowClickEnabled"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="isRowClickEnabled && emitRowClick(row)"
    ></tr>
  </table>

  <ng-content></ng-content>

  <!-- pagination -->
  @if (isPageable) {
    <mat-paginator
      [pageSizeOptions]="paginationSizes"
      [pageSize]="defaultPageSize"
      showFirstLastButtons
    ></mat-paginator>
  }
</div>
