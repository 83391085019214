import { Component, Input } from '@angular/core';
import { NavigationMenuGroup } from '../navigation-menu.model';

@Component({
  selector: 'cybexer-menu-group',
  templateUrl: 'menu-group.component.html',
  styleUrls: ['menu-group.component.scss'],
})
export class MenuGroupComponent {
  @Input() group: NavigationMenuGroup;
}
