import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AngularSplitModule } from 'angular-split';
import {
  AIFabricPromptOverrideDialogComponent,
  AIFabricService,
  BreadcrumbComponent,
  CardComponent,
  ConfirmActionComponent,
  ConfirmDialogComponent,
  ErrorPageComponent,
  FileSizePipe,
  FilterPipe,
  FormGridComponent,
  GridComponent,
  GuacamoleBoardComponent,
  GuacamoleConsoleComponent,
  GuacamoleConsoleWindowComponent,
  LoadingOverlayComponent,
  MenuGroupComponent,
  MenuLinkComponent,
  MenuSectionComponent,
  MetaItemComponent,
  NoDataComponent,
  ProgressSpinnerComponent,
  ReplaceUnderscoresPipe,
  RowComponent,
  SimpleTableComponent,
  WidgetComponent,
  WidgetBodyComponent,
  WidgetFilterComponent,
  WidgetHeaderComponent,
} from './';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardOverlayComponent } from './components/guacamole/clipboard-overlay/clipboard-overlay.component';
import { GuacamoleConsoleLoginComponent } from './components/guacamole/guacamole-console-login/guacamole-console-login.component';
import { GuacamoleConsoleDisplayComponent } from './components/guacamole/guacamole-console/guacamole-console-display/guacamole-console-display.component';
import { MonacoCommonsEditorComponent } from './components/monaco-editor/editor/monaco-commons-editor.component';
import { MonacoCommonsDiffEditorComponent } from './components/monaco-editor/diff-editor/monaco-commons-diff-editor.component';
import { MonacoJsonEditorComponent } from './components/monaco-editor/json-editor/monaco-json-editor.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { UseCapturePlugin } from './components/confirm-action/use-capture-plugin.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { PopoverComponent } from './components/popover/popover.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { TagComponent } from './components/tag/tag.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NotificationComponent } from './components/notification/notification.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VsphereConsoleComponent } from './components/vsphere/vsphere-console/vsphere-console.component';
import { VsphereConsoleWindowComponent } from './components/vsphere/vsphere-console-window/vsphere-console-window.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AIFabricChatbotWindowComponent } from './components/chatbot-window/aifabric-chatbot-window.component';
import { ParseCodeBlocksPipe } from './pipes/parse-code-blocks.pipe';
import { ParseCodeWordsPipe } from './pipes/parse-code-words.pipe';
import { MonacoCodeViewerComponent } from './components/monaco-editor/code-viewer/monaco-code-viewer.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { FlexComponent } from './components/flex/flex.component';
import { MonacoCommonsEditorService } from './service/monaco-commons-editor.service';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { ConfirmDirective } from './directives/confirm.directive';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { CustomMissingTranslationHandler } from './missing-translation-handler';
import { FileDragDropDirective } from './directives/file-drag-drop.directive';
import { AIFabricChatbotService } from './service/ai-fabric-chatbot.service';

@NgModule({
  declarations: [
    NoDataComponent,
    WidgetComponent,
    WidgetHeaderComponent,
    WidgetFilterComponent,
    WidgetBodyComponent,
    SimpleTableComponent,
    CardComponent,
    RowComponent,
    FlexComponent,
    GridComponent,
    MetaItemComponent,
    MonacoCommonsEditorComponent,
    MonacoCommonsDiffEditorComponent,
    MonacoJsonEditorComponent,
    ConfirmDialogComponent,
    ConfirmActionComponent,
    MenuSectionComponent,
    MenuGroupComponent,
    MenuLinkComponent,
    ProgressSpinnerComponent,
    LoadingOverlayComponent,
    BreadcrumbComponent,
    ErrorPageComponent,
    PopoverComponent,
    TagComponent,
    GuacamoleConsoleComponent,
    GuacamoleConsoleLoginComponent,
    ClipboardOverlayComponent,
    GuacamoleBoardComponent,
    GuacamoleConsoleDisplayComponent,
    NotificationComponent,
    GuacamoleConsoleWindowComponent,
    FormGridComponent,
    VsphereConsoleComponent,
    VsphereConsoleWindowComponent,
    AIFabricChatbotWindowComponent,
    AIFabricPromptOverrideDialogComponent,
    ParseCodeBlocksPipe,
    ParseCodeWordsPipe,
    FilterPipe,
    FileSizePipe,
    ReplaceUnderscoresPipe,
    MonacoCodeViewerComponent,
    ConfirmDirective,
    FileDragDropDirective,
    TooltipComponent,
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    BreadcrumbModule,
    OverlayModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSidenavModule,
    AngularSplitModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatMenuTrigger,
    MatMenu,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
    }),
  ],
  exports: [
    NoDataComponent,
    WidgetComponent,
    WidgetHeaderComponent,
    WidgetFilterComponent,
    WidgetBodyComponent,
    SimpleTableComponent,
    CardComponent,
    RowComponent,
    FlexComponent,
    GridComponent,
    MetaItemComponent,
    MonacoCommonsEditorComponent,
    MonacoCommonsDiffEditorComponent,
    MonacoJsonEditorComponent,
    ConfirmDialogComponent,
    ConfirmActionComponent,
    MenuSectionComponent,
    MenuGroupComponent,
    MenuLinkComponent,
    ProgressSpinnerComponent,
    LoadingOverlayComponent,
    BreadcrumbComponent,
    ErrorPageComponent,
    PopoverComponent,
    TagComponent,
    GuacamoleConsoleComponent,
    GuacamoleBoardComponent,
    NotificationComponent,
    FormGridComponent,
    VsphereConsoleComponent,
    AIFabricChatbotWindowComponent,
    AIFabricPromptOverrideDialogComponent,
    ParseCodeBlocksPipe,
    ParseCodeWordsPipe,
    ReplaceUnderscoresPipe,
    FilterPipe,
    FileSizePipe,
    MonacoCodeViewerComponent,
    ConfirmDirective,
    FileDragDropDirective,
    TooltipComponent,
  ],
  providers: [
    {
      provide: EVENT_MANAGER_PLUGINS,
      useExisting: UseCapturePlugin,
      multi: true,
    },
    FilterPipe,
    FileSizePipe,
    ParseCodeBlocksPipe,
    ParseCodeWordsPipe,
    ReplaceUnderscoresPipe,
    AIFabricService,
    AIFabricChatbotService,
    MonacoCommonsEditorService,
  ],
})
export class NgxCommonsModule {}
