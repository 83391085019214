import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Server } from '../step2.component';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'vlm-server-edit-dialog',
  templateUrl: './server-edit-dialog.component.html',
  styleUrls: ['./server-edit-dialog.component.scss'],
})
export class ServerEditDialogComponent implements OnInit {
  server: Server;
  options: string[] = [];
  dialogTitle: string = 'Edit';

  title: string;
  type: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ServerEditDialogComponent>,
    private titlePipe: TitleCasePipe,
  ) {}

  ngOnInit(): void {
    this.server = this.data.server;
    this.options = this.data.options;

    if (this.server) {
      this.title = this.server.title;
      this.type = [this.server.type];
    } else {
      this.type = [this.options[0]];
    }
  }

  saveAndClose(): void {
    if (!this.server) {
      this.server = { title: this.title, type: this.type[0] };
    } else {
      this.server.title = this.title;
      this.server.type = this.type[0];
    }

    if (!this.server.title) {
      this.server.title = this.titlePipe.transform(this.server.type);
    }
    this.dialogRef.close(this.server);
  }
}
