import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cybexerParseCodeWords' })
export class ParseCodeWordsPipe implements PipeTransform {
  transform(value: string): Array<{ type: string; content: string }> {
    const words = value.split(/(`[^`]+`)/g);
    return words
      .map((word) => ({
        type: word.startsWith('`') && word.endsWith('`') ? 'code' : 'text',
        content: word,
      }))
      .filter((word) => word.content.length > 0);
  }
}
