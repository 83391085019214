import { Component } from '@angular/core';

@Component({
  selector: 'cybexer-widget-filter',
  templateUrl: './widget-filter.component.html',
  styleUrls: ['./widget-filter.component.scss'],
})
// This might be deprecated for its initial intended use.
// Might be useful for usage for button/field rows inside widget body instead of header
export class WidgetFilterComponent {
  constructor() {}
}
