import { Component, Injector, Input, Renderer2 } from '@angular/core';
import { BaseEditorComponent } from '../base-editor.component';
import { editor } from 'monaco-editor';
import IStandaloneDiffEditor = editor.IStandaloneDiffEditor;
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;

@Component({
  selector: 'cybexer-monaco-diff-editor',
  templateUrl: './monaco-commons-diff-editor.component.html',
  styleUrls: ['./monaco-commons-diff-editor.component.scss'],
})
export class MonacoCommonsDiffEditorComponent extends BaseEditorComponent {
  @Input() originalModel: string;
  @Input() modifiedModel: string;

  protected override editor: IStandaloneDiffEditor;
  protected override options: IStandaloneEditorConstructionOptions = {
    lineNumbers: 'on',
    tabSize: 2,
    scrollBeyondLastLine: false,
    automaticLayout: true,
    showFoldingControls: 'always',
    readOnly: true,
  };

  constructor(
    protected override renderer: Renderer2,
    protected override injector: Injector
  ) {
    super(renderer, injector);
  }

  protected initMonaco(): void {
    const editorDiv: HTMLDivElement = this.editorContentRef.nativeElement;
    this.options.language = this.language;

    if (!this.editor) {
      let originalModel = this.getMonacoHandle().editor.createModel(
        this.originalModel,
        this.language
      );
      let modifiedModel = this.getMonacoHandle().editor.createModel(
        this.modifiedModel,
        this.language
      );

      this.editor = this.getMonacoHandle().editor.createDiffEditor(editorDiv, this.options);
      this.editor.setModel({ original: originalModel, modified: modifiedModel });
      this.setEditorHeight();
      this.editorInitialized.emit(this);
      this.editor.layout();
    }
  }
}
