import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams && params.key.startsWith('cybexer.')) {
      return params.interpolateParams['default'] || params.key;
    }
    return params.key;
  }
}
