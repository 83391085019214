import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'cybexer-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmActionComponent {
  @Input() tooltip: string;
  @Input() disabled = false;
  @ViewChild('tooltipElement') tooltipElement: MatTooltip;

  isInConfirmationState: boolean;

  private confirmationTimeout: number;
  private TIMEOUT_MS = 3000;

  constructor() {}

  startConfirmation(event: Event) {
    if (!this.disabled) {
      event.stopPropagation();
      this.isInConfirmationState = true;
      window.setTimeout(() => {
        if (this.tooltipElement) this.tooltipElement.show();
      }, 0);
    }
  }

  startConfirmationCancel() {
    this.confirmationTimeout = window.setTimeout(
      () => (this.isInConfirmationState = false),
      this.TIMEOUT_MS
    );
  }

  stopConfirmationCancel() {
    window.clearTimeout(this.confirmationTimeout);
    this.confirmationTimeout = null;
  }

  confirmAction() {
    this.isInConfirmationState = false;
    this.stopConfirmationCancel();
  }
}
