import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { from, of, Subject, switchMap } from 'rxjs';
import { catchError, first, take } from 'rxjs/operators';
import { MatDrawer } from '@angular/material/sidenav';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  AIFabricService,
  AvailableLLM,
  CHAT_ROLE_ASSISTANT,
  CHAT_ROLE_USER,
  ChatConversationTitle,
  ChatConversationToolType,
  ChatType,
} from '../../service/ai-fabric.service';
import { debounce } from '../../util/utils';
import { NotificationsService } from '../../service/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { AIFabricChatbotService } from '../../service/ai-fabric-chatbot.service';

export type ChatbotMessage = {
  role: string;
  content: string;
  errorMessage?: string;
  editing: boolean;
};

export type ChatbotResponse = {
  content: string;
  error?: string;
};

export type ChatbotTool = {
  type: ChatConversationToolType;
  icon: string;
  isEnabled: boolean;
  tooltip?: string;
  isUsable: () => boolean;
  buildTool: (promptVariables: Record<string, string>) => ChatbotToolBuildArgs;
  schema?: any;
};

export type ChatbotToolBuildArgs = {
  onCall: (...args: any[]) => string | Promise<string>;
  promptVariables: Record<string, string>;
};

export type ChatbotConfig = {
  selectedModel?: AvailableLLM;
  activeTools?: string[];
};

@Component({
  selector: 'cybexer-aifabric-chatbot-window',
  templateUrl: './aifabric-chatbot-window.component.html',
  styleUrls: ['./aifabric-chatbot-window.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AIFabricChatbotWindowComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  private static readonly TOKENS_RESERVED_FOR_CHATBOT_REPLY = 200;
  private static readonly COUNT_TOKENS_DEBOUNCE_DELAY_MS = 500;
  private static readonly MAX_CHAT_TITLE_LENGTH = 50;
  private static readonly STREAMING_UPDATE_DELAY_MS = 250;
  private static readonly STORAGE_NAMESPACE = 'cybexer_chatbot_config_';

  readonly CHAT_ROLE_USER = CHAT_ROLE_USER;
  modelMaxTokens?: number;
  isLlmOutOfMemory: boolean = false;
  llmOutOfMemoryMessage?: string;
  reservedTokens: number = AIFabricChatbotWindowComponent.TOKENS_RESERVED_FOR_CHATBOT_REPLY;
  contextTokens: number = 0;
  currentInputMessageTokens: number = 0;
  inputMessage: string = '';
  editMessage: string = '';
  messages: ChatbotMessage[] = [];
  isHistoriesMenuOpen: boolean = false;
  selectedChatHistoryId: string;
  availableChatHistories: ChatConversationTitle[] = [];
  availableModels: AvailableLLM[] = [];
  selectedModel: AvailableLLM = {
    modelId: '',
    displayName: 'Default',
    llmProviderId: '',
  };
  streamingMessage?: ChatbotMessage;
  hasConnection: boolean = false;

  protected chatbotToolGroups: ChatbotTool[][] = [];

  @Input() isChatHidden: boolean;
  @Input() baseChatType: ChatType = ChatType.SYSTEM_DEFINITION;
  @Input() chatbotTools: ChatbotTool[] = [];
  @Input() chatSpace: string = 'unspecified';
  @Input() combineToolsByIcon: boolean = true;

  @Output() toggleChat: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('messageInput') messageInput: ElementRef;
  @ViewChild('history') private historyContainer: ElementRef;
  @ViewChild('histories') private drawer: MatDrawer;

  private chatConversationId?: string;
  private observer: MutationObserver;
  private cancelSendMessageController: AbortController = new AbortController();
  private destroy$ = new Subject<void>();
  private lastTokenBufferFlushTime: number = 0;
  private tokenBuffer: string = '';
  private chatbotConfig: ChatbotConfig;

  constructor(
    private aiFabricService: AIFabricService,
    private notificationsService: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private aiFabricChatbotService: AIFabricChatbotService
  ) {
    aiFabricChatbotService.setAIFabricChatbotWindowComponent(this);
  }

  async ngOnInit() {
    this.loadStorage();

    this.aiFabricService
      .hasConnection()
      .pipe(
        switchMap((hasConnection) => {
          this.hasConnection = hasConnection;
          if (!hasConnection) return of(undefined);
          const threadId = this.aiFabricChatbotService.activeChatThreadIdSignal();
          if (threadId) {
            if (threadId) return this.openOldChat(threadId).then(() => threadId);
          }
          return this.newChat();
        }),
        catchError((e: unknown) => {
          console.warn('Failed to initialize chat thread', e);
          return of(undefined);
        }),
        switchMap((newChatId) => {
          return newChatId ? this.updateAvailableChatHistories() : of(null);
        }),
        switchMap((histories) => {
          return histories === null ? of([]) : this.aiFabricService.getAIFabricModels();
        })
      )
      .subscribe((models: AvailableLLM[]) => {
        this.availableModels = models;
        if (models.length > 0) {
          const desiredModel = this.chatbotConfig.selectedModel;
          this.selectedModel = desiredModel
            ? models.find(
                (it: AvailableLLM) =>
                  (it.modelConfigurationId &&
                    it.modelConfigurationId == desiredModel.modelConfigurationId) ||
                  (it.modelId &&
                    it.modelId == desiredModel.modelId &&
                    it.llmProviderId &&
                    it.llmProviderId == desiredModel.llmProviderId)
              )
            : models[0];
          this.changeModel(this.selectedModel);
        }
        this.initScrollToBottomObserver();
        this.setMessageInputHeight();
      });

    this.onToolsChanged();
  }

  onToolsChanged() {
    if (this.chatbotConfig.activeTools) {
      this.chatbotTools.forEach(
        (it) => (it.isEnabled = this.chatbotConfig.activeTools.includes(it.type))
      );
    }
    this.chatbotToolGroups = this.combineToolsByIcon
      ? this.chatbotTools.reduce((acc: ChatbotTool[][], it: ChatbotTool) => {
          const group = acc.find((group) => group[0].icon === it.icon) || [];
          if (!group.length) {
            acc.push(group);
          }
          group.push(it);
          return acc;
        }, [])
      : [this.chatbotTools];
  }

  private async getChatConversationId() {
    if (!this.chatConversationId) {
      this.chatConversationId = await this.aiFabricService.createChatConversation(
        this.chatSpace,
        this.baseChatType
      );
    }
    return this.chatConversationId;
  }

  private async updateAvailableChatHistories() {
    const titles = await this.aiFabricService.getChatConversationTitles();
    this.availableChatHistories = titles.map((it) => ({
      title:
        it.title.length > AIFabricChatbotWindowComponent.MAX_CHAT_TITLE_LENGTH
          ? it.title.slice(0, AIFabricChatbotWindowComponent.MAX_CHAT_TITLE_LENGTH) + '...'
          : it.title,
      conversationId: it.conversationId,
    }));
    return titles;
  }

  private handleChatToken = (token: string) => {
    if (this.streamingMessage) {
      this.tokenBuffer += token;
      if (
        Date.now() - this.lastTokenBufferFlushTime >
        AIFabricChatbotWindowComponent.STREAMING_UPDATE_DELAY_MS
      ) {
        this.lastTokenBufferFlushTime = Date.now();
        this.streamingMessage.content += this.tokenBuffer;
        this.tokenBuffer = '';
      }
    }
  };

  copyMessageToClipboard(message: ChatbotMessage) {
    navigator.clipboard.writeText(message.content).then(() =>
      this.notificationsService.info(
        this.translate.instant('cybexer.aiFabric.chatbot.messageCopiedToClipboard', {
          default: 'Message copied to clipboard',
        })
      )
    );
  }

  async openOldChat(chatConversationId: string) {
    const chatConversation = await this.aiFabricService.getChatConversation(chatConversationId);
    if (this.streamingMessage) {
      return;
    }
    this.messages = chatConversation.messages.map((message) => ({
      role: message.role,
      content: message.content,
      editing: false,
    }));
    this.chatConversationId = chatConversation.id;
    this.updateTokenUsage();
    setTimeout(() => this.scrollToBottom());
    this.aiFabricChatbotService.activeChatThreadIdSignal.set(chatConversationId);
  }

  async newChat() {
    this.messages = [];
    this.streamingMessage = undefined;
    this.selectedChatHistoryId = undefined;
    this.chatConversationId = undefined;
    const id = await this.getChatConversationId();
    if (this.selectedModel.llmProviderId) {
      await this.aiFabricService.setModel(id, this.selectedModel);
      this.modelMaxTokens = await this.aiFabricService.getModelTokenLimit(id);
      this.updateTokenUsage();
    }
    this.aiFabricChatbotService.activeChatThreadIdSignal.set(id);
    return id;
  }

  private async sendMessageInNewChat(
    message: string,
    chatType?: ChatType,
    hiddenSuffix: string = ''
  ) {
    await this.newChat();
    await this.sendMessage(message, hiddenSuffix, chatType);
  }

  async sendMessageInNewChatWithTools(
    message: string,
    tools: ChatConversationToolType[] = [],
    chatType?: ChatType,
    hiddenSuffix: string = ''
  ) {
    this.setTools(tools);
    await this.sendMessageInNewChat(message, chatType, hiddenSuffix);
  }

  async changeModel(model: AvailableLLM) {
    this.chatbotConfig.selectedModel = model;
    this.updateStorage();
    const id = await this.getChatConversationId();
    await this.aiFabricService.setModel(id, model);
    this.modelMaxTokens = await this.aiFabricService.getModelTokenLimit(id);
    this.updateTokenUsage();
  }

  ngAfterViewInit() {
    this.initScrollToBottomObserver();
  }

  private initScrollToBottomObserver() {
    if (!this.observer && this.historyContainer) {
      this.observer = new MutationObserver(() => {
        if (document.activeElement === this.messageInput.nativeElement) {
          this.scrollToBottom();
        }
      });
      this.observer.observe(this.historyContainer.nativeElement, { childList: true });
    }
  }

  ngAfterViewChecked() {
    this.setMessageInputHeight();
  }

  private setMessageInputHeight() {
    if (this.messageInput) {
      this.messageInput.nativeElement.style.height = 'auto';
      this.messageInput.nativeElement.style.height =
        this.messageInput.nativeElement.scrollHeight + 2 + 'px';
    }
  }

  private prepareTools(contextKeys: Record<string, string> = {}) {
    const toolOnCallMap: Record<string, (...args: any[]) => string | Promise<string>> = {};
    const tools = this.chatbotTools
      .filter((it) => it.isEnabled && it.isUsable())
      .map((it) => {
        const toolBuildArgs = it.buildTool({});
        contextKeys = { ...contextKeys, ...toolBuildArgs.promptVariables };
        toolOnCallMap[it.type] = toolBuildArgs.onCall;
        return it.type;
      });
    return { contextKeys, tools, toolOnCallMap };
  }

  private async sendMessage(
    content: string = this.inputMessage,
    hiddenSuffix: string = '',
    chatType: ChatType = this.baseChatType
  ) {
    if (this.streamingMessage) {
      return;
    }
    if (this.isLlmOutOfMemory) {
      this.notificationsService.error(
        this.translate.instant('cybexer.aiFabric.chatbot.cannotSendMessage', {
          message: this.llmOutOfMemoryMessage,
          default: 'Cannot send message - ' + this.llmOutOfMemoryMessage,
        })
      );
      return;
    }
    this.tokenBuffer = '';
    this.messages.push({ role: CHAT_ROLE_USER, content: content, editing: false });
    this.streamingMessage = { role: CHAT_ROLE_ASSISTANT, content: '', editing: false };
    this.messages.push(this.streamingMessage);
    this.scrollToBottom();
    this.inputMessage = '';
    this.changeDetectorRef.detectChanges();

    const { contextKeys, tools, toolOnCallMap } = this.prepareTools();
    const handleToolCall = (toolName: string, toolArgs: any[]) => {
      const onCall = toolOnCallMap[toolName];
      if (onCall) {
        return onCall(...toolArgs);
      }
      return this.translate.instant('cybexer.aiFabric.chatbot.toolNotFound', {
        toolName: toolName,
        default: `Tool ${toolName} not found`,
      });
    };

    this.aiFabricService
      .sendMessage(
        content,
        await this.getChatConversationId(),
        this.handleChatToken,
        handleToolCall,
        tools,
        contextKeys,
        hiddenSuffix,
        this.cancelSendMessageController.signal,
        chatType
      )
      .pipe(
        take(1),
        catchError((error: unknown) => {
          if (error !== 'AbortError') {
            console.error(error);
            throw error;
          }
          if (!this.streamingMessage.content.length) this.messages.pop();
          return of(null);
        })
      )
      .subscribe((response: ChatbotResponse) => {
        if (response != null) {
          if (response.error) {
            console.warn(
              this.translate.instant('cybexer.aiFabric.chatbot.gotMessageWithError', {
                default: 'Got chatbot message with error:',
              }),
              response.content,
              `[${response.error}]`
            );
          }
          if (response.content) {
            if (
              !this.streamingMessage.content.trim().length ||
              this.streamingMessage.content.includes(response.content) ||
              response.content.startsWith(this.streamingMessage.content)
            ) {
              this.streamingMessage.content = response.content;
              this.streamingMessage.errorMessage = response.error;
            } else {
              this.messages.push({
                role: CHAT_ROLE_ASSISTANT,
                content: response.content,
                editing: false,
                errorMessage: response.error,
              });
            }
          }
        }
        if (this.messages.length === 2) this.updateAvailableChatHistories();
        this.streamingMessage = undefined;
        this.tokenBuffer = '';
        this.scrollToBottom();
        this.updateTokenUsage();
        this.changeDetectorRef.detectChanges();
      });
  }

  async enterPressed(event: Event) {
    if (!(event as KeyboardEvent).shiftKey) {
      event.preventDefault();
      await this.sendMessage();
    }
  }

  private setTools(tools: ChatConversationToolType[]) {
    this.chatbotTools.forEach((it) => (it.isEnabled = tools.includes(it.type)));
    this.updateTokenUsage();
  }

  toggleToolGroup(toolGroup: ChatbotTool[]) {
    const isEnabled = !toolGroup[0].isEnabled;
    if (isEnabled && !this.chatbotConfig.activeTools) {
      this.chatbotConfig.activeTools = [];
    }
    toolGroup.forEach((it: ChatbotTool) => (it.isEnabled = isEnabled));
    this.chatbotConfig.activeTools = isEnabled
      ? this.chatbotConfig.activeTools.concat(toolGroup.map((it) => it.type))
      : this.chatbotConfig.activeTools.filter((it) => !toolGroup.some((tool) => tool.type == it));
    this.updateStorage();
    this.updateTokenUsage();
  }

  isToolGroupUsable(toolGroup: ChatbotTool[]) {
    return toolGroup.some((it) => it.isUsable());
  }

  scrollToBottom(): void {
    this.historyContainer.nativeElement.scrollTop =
      this.historyContainer.nativeElement.scrollHeight;
  }

  ngOnDestroy() {
    this.observer?.disconnect();
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateAvailability(customHeaders?: any) {
    this.aiFabricService.updateAvailability(customHeaders);
  }

  async regenerate(hiddenMessage: string = '') {
    if (this.messages[this.messages.length - 1].role !== CHAT_ROLE_USER) {
      return;
    }
    await this.aiFabricService.removeMessage(this.chatConversationId, -1);
    const userMessage = this.messages.pop().content;
    await this.sendMessage(userMessage, hiddenMessage);
  }

  async retryLastMessage() {
    if (
      this.messages.length < 2 ||
      !this.messages.at(-1).errorMessage ||
      this.messages.at(-1).role !== CHAT_ROLE_ASSISTANT ||
      this.messages.at(-2).role !== CHAT_ROLE_USER
    ) {
      return;
    }
    const failedMessage = this.messages.pop();
    await this.regenerate('\n' + this.getImprovementInstructions(failedMessage.errorMessage));
  }

  private getImprovementInstructions(errorMessage: string): string {
    return (
      this.translate.instant('cybexer.aiFabric.chatbot.previousResponseFailed', {
        default: 'Your previous response failed with',
      }) +
      ': ' +
      errorMessage.slice(0, 50) +
      (errorMessage.length > 50 ? '...' : '')
    );
  }

  async deleteMessage(index: number) {
    this.messages.splice(index, 1);
    const result = await this.aiFabricService.removeMessage(this.chatConversationId, index);
    this.updateTokenUsage();
    return result;
  }

  startEditMessage(index: number) {
    this.editMessage = this.messages[index].content;
    this.messages[index].editing = true;
  }

  editMessageEnterPressed(index: number, event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    if (!keyboardEvent.shiftKey && !keyboardEvent.ctrlKey) {
      event.preventDefault();
      this.finishEditMessage(index);
    }
  }

  cancelEditMessage(index: number) {
    this.messages[index].editing = false;
  }

  async finishEditMessage(index: number, removeNewer: boolean = false) {
    this.messages[index].content = this.editMessage;
    this.messages[index].editing = false;
    this.messages[index].errorMessage = undefined;
    const success = await this.aiFabricService.editMessage(
      this.chatConversationId,
      index,
      this.editMessage,
      removeNewer
    );
    if (success) this.updateTokenUsage();
    if (success && removeNewer) {
      this.messages.splice(index + 1, this.messages.length - index - 1);
      this.regenerate();
    }
  }

  deleteChat(id: string) {
    from(this.aiFabricService.removeChatConversation(id))
      .pipe(
        catchError(() => of(false)),
        first()
      )
      .subscribe((success) => {
        if (success) {
          this.notificationsService.success(
            this.translate.instant('cybexer.aiFabric.chatbot.chatDeleted', {
              default: 'Chat deleted',
            })
          );
          this.availableChatHistories = this.availableChatHistories.filter(
            (it) => it.conversationId !== id
          );
        } else {
          this.notificationsService.error(
            this.translate.instant('cybexer.aiFabric.chatbot.chatDeleteFailed', {
              default: 'Failed to delete chat',
            })
          );
        }
      });
  }

  menuOpened() {
    this.isHistoriesMenuOpen = true;
  }

  menuClosed() {
    this.isHistoriesMenuOpen = false;
  }

  cancelSendMessage() {
    this.cancelSendMessageController.abort(
      this.translate.instant('cybexer.aiFabric.chatbot.userCancelledMessage', {
        default: 'User cancelled message',
      })
    );
    this.cancelSendMessageController = new AbortController();
  }

  private getLLMOutOfMemoryMessage() {
    const solutions = [
      {
        isValid: this.modelMaxTokens < 128000,
        solution: this.translate.instant('cybexer.aiFabric.chatbot.selectModel', {
          default: 'select model with more context size',
        }),
      },
      {
        isValid: this.chatbotTools.filter((it) => it.isEnabled).length,
        solution: this.translate.instant('cybexer.aiFabric.chatbot.disableEditFile', {
          default: 'disable edit file functionality',
        }),
      },
      {
        isValid: this.currentInputMessageTokens > 2000,
        solution: this.translate.instant('cybexer.aiFabric.chatbot.writeShorterMessage', {
          default: 'write a shorter message',
        }),
      },
      {
        isValid: this.messages.length > 2,
        solution: this.translate.instant('cybexer.aiFabric.chatbot.deleteOlderMessages', {
          default: 'delete older messages',
        }),
      },
      {
        isValid: true,
        solution: this.translate.instant('cybexer.aiFabric.chatbot.startNewChatThread', {
          default: 'start new chat thread',
        }),
      },
    ]
      .filter((it) => it.isValid)
      .map((it) => it.solution);
    return (
      `${this.translate.instant('cybexer.aiFabric.chatbot.chatContextTooLarge', { default: 'Chat context is too large' })} - ` +
      solutions.join(
        ` ${this.translate.instant('cybexer.aiFabric.chatbot.or', { default: 'or' })} `
      )
    );
  }

  get storageKey() {
    return AIFabricChatbotWindowComponent.STORAGE_NAMESPACE + this.chatSpace;
  }

  loadStorage() {
    const storedConfig = sessionStorage.getItem(this.storageKey);
    this.chatbotConfig = storedConfig ? JSON.parse(storedConfig) : {};
  }

  updateStorage() {
    sessionStorage.setItem(this.storageKey, JSON.stringify(this.chatbotConfig));
  }

  updateTokenUsage = debounce(async () => {
    if (this.modelMaxTokens && this.chatConversationId) {
      const { contextKeys, tools } = this.prepareTools();

      const { tokensInContext, tokensInSystemPrompt, tokensInMessage } =
        await this.aiFabricService.countTokens(
          this.inputMessage,
          this.chatConversationId,
          tools,
          contextKeys
        );
      this.contextTokens = tokensInContext;
      this.reservedTokens =
        tokensInSystemPrompt + AIFabricChatbotWindowComponent.TOKENS_RESERVED_FOR_CHATBOT_REPLY;
      this.currentInputMessageTokens = tokensInMessage;
      this.isLlmOutOfMemory =
        this.modelMaxTokens <
        this.currentInputMessageTokens + this.reservedTokens + this.contextTokens;
      this.llmOutOfMemoryMessage = this.isLlmOutOfMemory ? this.getLLMOutOfMemoryMessage() : null;
    }
  }, AIFabricChatbotWindowComponent.COUNT_TOKENS_DEBOUNCE_DELAY_MS);
}
