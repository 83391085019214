import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'cybexer-widget-header',
  templateUrl: './widget-header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class WidgetHeaderComponent {
  @Input() title: string;
  @Input() inline: boolean = false;
  @Input() backEnabled: boolean = false;
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class') class = 'cybexer-widget-header';

  navigateBack() {
    this.back.emit();
  }
}
