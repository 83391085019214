<div class="cybexer-confirm-dialog">
  <mat-dialog-content>
    <p class="disable-uppercase" data-testid="message">{{ message }}</p>
    @if (data.additionalMessage) {
      <p class="disable-uppercase additional-message" data-testid="additionalMessage">
        {{ additionalMessage }}
      </p>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="true" mat-raised-button color="primary" data-testid="confirmBtn">
      {{ confirmBtnText }}
    </button>
    <button mat-button matDialogClose data-testid="cancelBtn" (click)="cancel()">
      {{ cancelBtnText }}
    </button>
  </mat-dialog-actions>
</div>
