import { Component, Input } from '@angular/core';
import { NavigationMenuLink } from '../navigation-menu.model';

@Component({
  selector: 'cybexer-menu-link',
  templateUrl: 'menu-link.component.html',
  styleUrls: ['menu-link.component.scss'],
})
export class MenuLinkComponent {
  @Input() link?: NavigationMenuLink;

  get linkName(): string {
    return this.link?.name;
  }
}
