@if (!isInConfirmationState) {
  <span (click.capture)="startConfirmation($event)" data-testid="start-confirm-wrapper">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </span>
} @else {
  <span
    (mouseleave)="startConfirmationCancel()"
    (mouseenter)="stopConfirmationCancel()"
    (click)="confirmAction()"
    class="cybexer-confirm-action"
    #tooltipElement="matTooltip"
    [matTooltip]="
      tooltip
        ? (tooltip | translate)
        : ('cybexer.pressAgain' | translate: { default: 'Press again to confirm' })
    "
    matTooltipClass="confirmation-tooltip"
    data-testid="confirm-wrapper"
  >
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </span>
}

<ng-template #contentTpl><ng-content></ng-content></ng-template>
