import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '../components/notification/notification.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private snackBar: MatSnackBar) {}

  success(title?: string, content?: string): void {
    this.show('success', title, content);
  }

  info(title?: string, content?: string): void {
    this.show('info', title, content);
  }

  warn(title?: string, content?: string): void {
    this.show('warning', title, content);
  }

  error(title?: string, content?: string): void {
    this.show('error', title, content);
  }

  show(type: string, title?: string, content?: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        type,
        title,
        content,
      },
      horizontalPosition: 'right',
      duration: 5000,
      panelClass: ['snackbar-notification', 'snackbar-notification--' + type],
    });
  }
}
