import { Injectable } from '@angular/core';
import Guacamole from 'guacamole-common-js';
import { Mimetype } from 'guacamole-common-js/lib/GuacCommon';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActiveConnection, ConsoleConnectionType } from '../guacamole.model';

@Injectable({
  providedIn: 'root',
})
export class GuacamoleConsoleService {
  activeGuacamoleConnection$: BehaviorSubject<ActiveConnection> =
    new BehaviorSubject<ActiveConnection>(null);

  client: Guacamole.Client;
  tunnel: Guacamole.Tunnel;

  clientReady = new Subject<void>();
  resize = new Subject<boolean>();
  toWideScreen = new Subject<boolean>();
  closeConsole = new Subject<void>();

  constructor() {}

  async connectToClient(params: URLSearchParams) {
    this.client = new Guacamole.Client(this.tunnel);
    this.client.connect(params);

    this.clientReady.next();
    return this.client;
  }

  isConnected(): boolean {
    return this.tunnel?.isConnected();
  }

  getDisplay(): Guacamole.Display | undefined {
    return this.client?.getDisplay();
  }

  getScreenshot(connectionType: ConsoleConnectionType): Promise<string> {
    return new Promise<string>((resolve) => {
      this.client.exportState((state) => {
        resolve(
          connectionType === ConsoleConnectionType.SSH ? state.layers[1].url : state.layers[0].url
        );
      });
    });
  }

  getCurrentState(): Promise<Guacamole.Client.State> {
    return new Promise((resolve) => {
      this.client.exportState((state) => {
        resolve(state.currentState);
      });
    });
  }

  sendMouseState(state: any): void {
    this.client?.sendMouseState(state);
  }

  sendKeyEvent(pressed: 0 | 1, keysym: number): void {
    this.client?.sendKeyEvent(pressed, keysym);
  }

  bindErrorHandlers(
    handleTunnelError: (status: Guacamole.Status) => void,
    handleClientError: (status: Guacamole.Status) => void
  ): void {
    this.client.onerror = handleClientError;
    this.tunnel.onerror = handleTunnelError;
  }

  bindStateHandlers(
    handleTunnelState: (tunnelState: Guacamole.Tunnel.State) => void,
    handleClientState: (clientState: Guacamole.Client.State) => void
  ): void {
    this.client.onstatechange = handleClientState;
    this.tunnel.onstatechange = handleTunnelState;
  }

  bindClipboardHandler(
    handleGuacamoleRemoteClipboard: (stream: Guacamole.InputStream, mimetype: Mimetype) => void
  ): void {
    this.client.onclipboard = handleGuacamoleRemoteClipboard;
  }

  unbindHandlers(): void {
    if (this.client) {
      this.client.onerror = this.client.onstatechange = undefined;
      this.client.onclipboard = undefined;
    }

    if (this.tunnel) {
      this.tunnel.onerror = this.tunnel.onstatechange = undefined;
    }
  }

  disconnect(): void {
    this.client?.disconnect();
    this.tunnel?.disconnect();
  }

  clear(): void {
    this.client = undefined;
    this.tunnel = undefined;
  }
}
