<div class="wrapper">
  @if (loading) {
    <div class="overlay" @loadingTrigger>
      <div class="spinner-wrapper">
        <cybexer-progress-spinner
          [spinnerText]="'cybexer.loading' | translate"
        ></cybexer-progress-spinner>
      </div>
    </div>
  }

  <ng-content></ng-content>
</div>
