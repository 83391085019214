import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cybexer-flex',
  templateUrl: './flex.component.html',
})
export class FlexComponent {
  @HostBinding('style.--gap')
  @Input()
  gap: number = 0;

  @Input() horizontalAlign: 'normal' | 'left' | 'center' | 'right' | 'justify' | 'spaced' =
    'normal';
  @Input() verticalAlign: 'normal' | 'top' | 'center' | 'bottom' | 'baseline' = 'normal';
  @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
  @Input() wrap: boolean = false;
  @Input() equal: boolean = false;

  @HostBinding('style.--horizontal-alignment')
  get horizontalAlignment(): string {
    if (!this.horizontalAlign) return null;
    switch (this.horizontalAlign) {
      case 'normal':
        return null;
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      case 'justify':
        return 'space-between';
      case 'spaced':
        return 'space-around';
      case 'center':
      default:
        return this.horizontalAlign;
    }
  }

  @HostBinding('style.--vertical-alignment')
  get verticalAlignment(): string {
    if (!this.verticalAlign) return null;

    switch (this.verticalAlign) {
      case 'normal':
        return null;
      case 'top':
        return 'flex-start';
      case 'bottom':
        return 'flex-end';
      case 'center':
      case 'baseline':
      default:
        return this.verticalAlign;
    }
  }

  @HostBinding('class')
  get hostClasses(): string {
    return `cybexer-flex cybexer-flex--direction-${this.direction} ${this.equal ? 'cybexer-flex--equal' : ''}`;
  }

  @HostBinding('style.--wrap')
  get wrapProp(): string {
    return this.wrap ? 'wrap' : null;
  }
}
